import Vue from 'vue'
import loadingComponent from './index'

const loadingConstructor = Vue.extend(loadingComponent);
const instance  = new loadingConstructor({
    el:document.createElement('div')
})

instance.show = false
export const loading = {
    show(){
        let that = this;
        instance.show = true;
        document.body.appendChild(instance.$el)
        setTimeout(function (){
            that.hide()
        },15000)
    },
    hide(){
        instance.show = false;
    }
}
export default {
    install(){
        if (!Vue.$loading){
            Vue.$loading = loading
        }
        Vue.mixin({
            created() {
                this.$loading = Vue.$loading
            }
        })
    }
}