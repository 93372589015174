import Cookies from 'js-cookie'

const TokenKey = 'YMS_BA_TOKEN'
const UserInfo = 'YMS_BA_USERINFO'
const MenuList = 'YMS_MENULIST'
const ButtonList = 'YMS_BUTTON_LIST'

export function getToken() {
  Cookies.get(TokenKey)
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
  Cookies.set(TokenKey, token)
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
  Cookies.remove(TokenKey)
  return Cookies.remove(TokenKey)
}

// 获取用户账户ID
export function getUserId() {
  const userInfo = JSON.parse(localStorage.getItem(UserInfo))
  if (userInfo) {
    return userInfo.userId
  }
}

// 获取用户ID
export function fetUserID() {
  const userInfo = JSON.parse(localStorage.getItem(UserInfo))
  if (userInfo) {
    return userInfo.userId
  }
}

// 获取用户名称
export function getUserName() {
  const userInfo = JSON.parse(localStorage.getItem(UserInfo))
  if (userInfo) {
    return userInfo.name
  }
}

// 获取用户密码
export function getUserPassword() {
  const userInfo = JSON.parse(localStorage.getItem(UserInfo))
  if (userInfo) {
    return userInfo.password
  }
}

// 设置用户密码
export function setUserPassword() {
  const userInfo = JSON.parse(localStorage.getItem(UserInfo))
  if (userInfo) {
    return userInfo.password
  }
}

// 获取用户最后一次登录时间
export function getUserLastLoginTIme() {
  const userInfo = JSON.parse(localStorage.getItem(UserInfo))
  if (userInfo) {
    return userInfo.lastLoginTime
  }
}

export function getUserInfo() {
  const userInfo = JSON.parse(localStorage.getItem(UserInfo))
  return userInfo
}

export function setUserInfo(userInfo) {
  return localStorage.setItem(UserInfo, userInfo)
}

export function removeUserInfo() {
  return localStorage.removeItem(UserInfo)
}

// 获取菜单
export function getMenuList() {
  const menuList = JSON.parse(localStorage.getItem(MenuList))
  return menuList
}

// 存放菜单
export function setMenuList(menuList) {
  return localStorage.setItem(MenuList, menuList)
}

// 获取按钮
export function getButtonList() {
  const buttonList = JSON.parse(localStorage.getItem(ButtonList))
  return buttonList
}

// 存放按钮
export function setButtonList(buttonList) {
  return localStorage.setItem(ButtonList, buttonList)
}

//
export function getButtonAllowed(code) {
  const buttonList = getButtonList()
  if (buttonList.length !== 0) {
    return buttonList.some(val => val === code)
  }
  return false
}

// 获取MenuCode
export function getMenuCode(self) {
  const menuList = getMenuList()
  const title = self.$route.meta.title
  const allMenuList = menuList.map((item) => {
    return item.childMenu
  })
  const allMenuListFlat = allMenuList.flat()
  const item = allMenuListFlat.find((item) => {
    return item.name.indexOf(title) > -1
  })
  return item.menuCode
}
