/**
  * @FilePath: src\router\customerRouter.js
  * @Description: 客户路由
  * @Author: lyh
*/

import relatedCustomer from '@/views/customer/relatedCustomer'
import fillInFollowUp from '@/views/customer/fillInFollowUp'

const customerRouter = [
  {
    path: '/related_customer',
    name: 'RelatedCustomer',
    component: relatedCustomer,
    meta: {
      keepAlive: false,
      title: "关联客户",
    }
  },
  {
    path: '/fill_in_follow_up',
    name: 'FillInFollowUp',
    component: fillInFollowUp,
    meta: {
      keepAlive: false,
      title: "填写跟进",
    }
  }
]

export default customerRouter
