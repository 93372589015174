<template>
  <div>
    <Search :value="searchValue" :placeholderSearch="'搜索关键词'" @searchCallBack="searchCallBack" />
    <Content @multiple="multipleClick" @filterShowCallBack="filterShowCallBack" :total="total"  :multiple="false">
      <div class="infoWrap" ref="list" @scroll="alive">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadCallBack"
        >
          <div class="">
            <van-checkbox-group v-model="checkedList" ref="checkboxGroup">
              <div  v-for="(item,index) in list" :key="index">
                <ContactItem  @followCallBack="followCallBack"  @detailsItemCallBack="detailsItemCallBack" :item="item" :isMultiple="isMultiple"></ContactItem>
              </div>
            </van-checkbox-group>
            <div style="height: 40px"></div>
          </div>
        </van-list>

      </div>
      <ContactFilterPop :searchValue="searchValue"  @sureCallBack="sureCallBack"  :totalAll="total"  :filterShow="filterShow" @close="onCancel" :identityType="identityType">
      </ContactFilterPop>
    </Content>
  </div>
</template>

<script>
import ContactFilterPop from "@/views/contact/item/contactFilterPop";
import Content from '@/views/components/content.vue'
import Search from '@/views/components/search';
import ContactItem from "@/views/contact/item/contactItem";
import {contact_list} from "@/api/contact";
import {returnExpireData} from "@/utils/check/changeData";
export default {
  components:{
    Content,
    ContactItem,
    Search,
    ContactFilterPop,
  },
  props:{
    identityType:{
      type:Number,
      default:null,
    },
    placeholderSearch:{
      type:String,
      default:"",
    }
  },
  data() {
    return {
      scrollPages: 0, // 缓存当前滚动条的位置
      recycleItem:null,
      recycleShow:false,
      connectItem:{},
      searchValue:'',
      total:0,
      isShowContacts:false,
      filterShow:false,
      loading: false,
      finished: false,
      list:[],
      checkedList:[],//选中数组
      isMultiple:false,
      checked:false,
      form:{
        pageNum:1,
        pageSize:20
      },
      goToDetails: false // 是否进入详情
    };
  },
  created() {
    console.log('我们进入================created')
  },
  activated() {
    if(!this.goToDetails) { // 进入详情页 返回不重新加载
      this.loading = false
      this.finished = false
      this.form.pageNum = 1
      this.list = []
      this.init()
    }
    this.goToDetails = false
    // 缓存组件激活时调用
    this.$refs.list.scrollTop = this.scrollPages; // 获取 dom 的scrollTop = 缓存的滚动条位置
  },
  methods:{
    alive(e) {
      // 获取页面滚动条的位置
      this.scrollPages = e.target.scrollTop;
    },
    closeCallBack(){
      this.recycleShow = false
    },
    recycleCallBack(item){
      this.recycleShow = true
      console.log('recycleShow',this.recycleShow)
      this.recycleItem = item
    },
    sureCallBack(item){
      this.form = item;
      this.list = []
      this.init();
    },
    async init(){
      this.loading = true
      this.total = 0
      this.form.name = this.searchValue;
      try {
        const res = await contact_list(this.form)
        this.initThenCallBack(res);
      }catch (e) {
        this.loading = false
        this.finished = true
      }
    },
    initThenCallBack(res){
      res.records = res.records||[]
      this.list.push(...res.records)

      this.form.pageNum++
      this.loading = false
      this.total = Number(res.totalNum)
      console.log('alina-------------------',this.total)
      if (res.records.length!=0 && this.list.length <= this.total) {
        this.finished = false
      }else {
        this.loading = false
        this.finished = true
      }
      console.log(res)
    },
    detailsItemCallBack(item){
      console.log(item)
      localStorage.setItem('active','2')
      let queryData ='?id=' + item.id//品牌身份多传个品牌id
      this.$router.push({
        path: '/contact_details'+ queryData
      })
      this.goToDetails = false
    },
    followCallBack(item){
      localStorage.setItem('active','2')
      let shopId = ''
      if (item.identity === 'USER_LABEL_IDENT_RETAILER'){
        shopId = item.id
      }
      this.$router.push({
        path: '/fill_in_follow_up?identity='+item.identity+'&userId='+item.userId+'&shopId='+shopId
      })
    },
    connectCallBack(item){
      let data = {
        id:item.id,
        userType:item.userType,
        userId:item.userId,
        identityType: this.identityType
      }
      this.connectItem = data
      console.log('connectCallBack=',item)
      this.isShowContacts  = true
    },
    filterShowCallBack(){
      this.filterShow = true
    },
    multipleClick(){
      this.isMultiple = !this.isMultiple
      this.$emit('isMultipleCallback',this.isMultiple)
    },
    onConfirm(item){
      console.log(item)
      this.showDistribution = false
    },
    onCancel(){
      this.isShowContacts = false;
      this.showDistribution = false;
      this.filterShow = false;
    },
    isMultipleCallback(isMultiple){
      console.log(isMultiple)
      this.isMultiple = isMultiple
    },
    checkAll(item){
      if(this.checked){
        this.$refs.checkboxGroup.toggleAll(true);
      }else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    onLoadCallBack() {
      console.log('onLoadCallBack')
      this.init()
    },
    searchCallBack(val){
      let that = this;
      this.searchValue = val||'';
      this.list = [];
      this.form.pageNum = 1;
      that.init()
    },
  }
}
</script>

<style lang="scss" scoped>
.infoWrap{
  margin: 20px 30px 0 0;
  /* padding-bottom: 120px; */
  box-sizing: border-box;
  height: calc(100% - 180px) ;
  overflow: auto;
  .swipe{
    margin-bottom: 20px;
  }
  .item{
    box-sizing: border-box;
    margin-left: 30px;
    height: 194px;
    border-radius: 10px;
    background: #FFFFFF;
    position: relative;
    z-index: 1;
    color:#333333;
    padding-left: 30px;
    &.isMultiple{
      padding-left: 100px;
    }
    .checked{
      position: absolute;
      left: 30px;
      top: 60px;
    }
    .name{
      font-size: 36px;
      margin-bottom: 8px;
    }
    .style{
      font-size: 24px;
    }
  }
  .operate{
    display: flex;
    /* margin-left: -18px; */
    div{
      text-align: center;
      height: 152px;
      line-height: 152px;
      font-size: 28px;
      color: #FFFFFF;
    }
    .draw{
      width: 134px;
      background: #EB5D00;
      &.active{
        margin-left: -18px;
      }
    }
    .distribution{
      width: 116px;
      background: #4484FF;
      border-radius: 0px 20px 20px 0px;
    }
  }
}

.isMultipleOp{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  height: 120px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 30px;
  font-size: 28px;
  .num{
    display: inline-block;
    line-height: 1;
    margin-left: 20px;
    color:#999999
  }
  .draw,.distribution{
    font-size: 24px;
    width: 152px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    position:absolute;
    border-radius: 25px;
  }
  .draw{
    right: 190px;
    color:#4484FF;
    border: 1px solid #4484FF;
  }
  .distribution{
    background: #4484FF;
    right: 30px;
    color: #FFFFFF;
  }
}
</style>
