<!--
 * @FilePath: /web-yms-customer-h5/src/views/customer/fillInFollowUp.vue
    @Description: 填写跟进
    @Author: lyh
-->
<template>
  <div class="container">
    <div class="select-input" @click="isShowOption = true">
      <input readonly class="form-input normal" type="text" v-model="formData.visitModeName" placeholder="选择跟进方式">
      <img class="arrow-icon" :src="arrowIcon" alt="">
    </div>
    <div  v-if="isBrand" class="select-input" @click="isShowScaleOption = true">
      <input readonly class="form-input normal" type="text" v-model="formData.enterpriseReceivableScaleName" placeholder="企业年营收规模">
      <img class="arrow-icon" :src="arrowIcon" alt="">
    </div>

    <div class="calendar-input" @click="isShowDatetime = true">
      <input readonly class="form-input normal" type="text" v-model="formData.followUpTimeLabel" placeholder="走访时间">
      <img class="calendar-icon" :src="calendarIcon" alt="">
    </div>

    <div class="textarea-input">
      <!-- <textarea
          rows="4"
          maxlength="1000"
          class="form-input text-area"
          v-model="formData.content"
          placeholder="输入跟进内容"></textarea> -->
          <div id="editEditor" />
      <!-- <span class="text-length">{{ textLength }}/1000</span> -->
    </div>

    <div>
      <div class="form-title">添加图片<span style="color: #999999">(最多6张)</span></div>
      <ImageUploadCrop
          :initImageList="defaultImg"
          folder="customer"
          @handleGetImgList="handleUpload"
      />
    </div>

    <div class="submit-btn" @click="submitInfo">提交</div>

    <!--  日期选择器弹窗  -->
    <datetimePickerPop
        :is-new="!id"
        :is-show="isShowDatetime"
        :value="formData.followUpTime"
        @clickClose="clickPickerPopClose"
        @clickConfirm="clickPickerPopConfirm"/>

    <!--  跟进方式选择弹窗  -->
    <modeOptionPop
        :is-new="!id"
        :value="formData.visitMode"
        :is-show="isShowOption"
        :identity="identity"
        @clickClose="clickOptionClose"
        @clickConfirm="clickOptionConfirm"/>

         <!--  营收规模 -->
    <revenueScalePop
        :value="formData.enterpriseReceivableScale"
        :is-show="isShowScaleOption"
        @clickClose="clickScaleOptionClose"
        @clickConfirm="clickScaleOptionConfirm"/>

  </div>
</template>

<script>
import Editor from 'wangeditor'
import getOssSignature from '@/mixins/getOssSignature'
import arrowIcon from '@/assets/image/arrow-icon.png'
import calendarIcon from '@/assets/image/calendar-icon.png'

import datetimePickerPop from "@/views/customer/components/datetimePickerPop";
import modeOptionPop from "@/views/customer/components/modeOptionPop";
import ImageUploadCrop from '@/components/ImageUploadCrop'
import revenueScalePop from "@/views/customer/components/revenueScalePop";


import {saveVisitRecordInfo, getVisitRecordDetail} from "@/api/customerSea";

let editEditor = null
export default {
  name: "fillInFollowUp",
  components: {
    datetimePickerPop,
    modeOptionPop,
    ImageUploadCrop,
    revenueScalePop
  },
  mixins: [getOssSignature],
  data() {
    return {
      formData: {
        visitMode: '',
        visitModeName: '',
        followUpTime: '',
        followUpTimeLabel: '',
        content: '',
        enterpriseReceivableScale:'',
        enterpriseReceivableScaleName:'',
        imgUrl: []
      },
      arrowIcon, // 箭头图标
      calendarIcon, // 日历图标
      isShowDatetime: false, // 时间弹窗是否展示
      isShowOption: false, // 下拉选择是否选中
      isShowScaleOption: false, // 企业规模
      defaultImg: [], // 默认图片
      identity: "", // 类型：String  必有字段  备注：会员身份
      userId: "", // 类型：String  必有字段  备注：用户ID
      userType: "", //
      id: '',
      shopId:''
    }
  },
  created() {
    const {identity = '', userId = '', id = '',shopId='',userType="" } = this.$route.query // 进入页面必穿参数 identity, userId
    this.identity = identity
    this.userId = userId
    this.userType = userType
    this.id = id
    this.shopId = shopId
    if (id) {
      this.getVisitRecordDetail({
        id
      })
    }else {
      this.$nextTick(()=>{
        this.editEditor()
      })
    }
  },
  computed: {
    textLength() {
      return this.formData.content.length
    },

    isBrand(){
      return this.identity === 'USER_LABEL_IDENT_BRAND'
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener("popstate", this.onCloseModal, false)
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.onCloseModal, false)
  },
  methods: {
    editEditor() {
      editEditor = new Editor('#editEditor')
      editEditor.config.placeholder =  this.isBrand ? '不要流水账直接重点！客户需求、意见和建议、自己基于客户的判断写标签、市场的前沿信息' : '请输入'
      editEditor.config.zIndex = 1
      editEditor.config.menus = [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'table',
        'code',
        'splitLine',
        'undo',
        'redo'
      ]
      editEditor.config.customUploadImg = (resultFiles, insertImgFn) => {
      // resultFiles 是 input 中选中的文件列表
      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
      // 上传图片，返回结果，将图片插入到编辑器中
      console.log(resultFiles,'resultFiles')
        resultFiles.forEach((file) => {
        /**
         * 传参
         * file 上传的文件
         * insertImgFn 是获取图片 url 后，插入到编辑器的方法
         * folder 上传的文件夹
         * */
          this.getSignature(file, insertImgFn, 'ks_info')
        })
      }
      editEditor.create()
      this.formData.content && editEditor.txt.html(this.formData.content)
    },
    /**
     * 关闭时间选择器弹窗
     */
    clickPickerPopClose() {
      this.isShowDatetime = false
    },
    /**
     * 关闭下拉选择
     */
    clickOptionClose() {
      this.isShowOption = false
    },
    /**
     * 点击下拉选择确定
     */
    clickOptionConfirm(value) {
      this.formData.visitModeName = value.label
      this.formData.visitMode = value.value
      this.isShowOption = false
    },

    /**
     * 关闭企业规模下拉选择
     */
    clickScaleOptionClose() {
      this.isShowScaleOption = false
    },
    /**
     * 点击企业下拉选择确定
     */
    clickScaleOptionConfirm(value) {
      this.formData.enterpriseReceivableScaleName = value.label
      this.formData.enterpriseReceivableScale = value.value
      this.isShowScaleOption = false
    },



    /**
     * 图片上传成功
     */
    handleUpload(value) {
      this.formData.imgUrl = value
    },
    /**
     * 点击选择时间确定
     * @param value
     */
    clickPickerPopConfirm(value) {
      this.formData.followUpTime = value.value
      this.formData.followUpTimeLabel = value.label
      this.isShowDatetime = false
    },
    /**
     * 提交跟进记录
     * @returns {Promise<void>}
     */
    async submitInfo() {
      let dataSources = this.identity === 'USER_LABEL_IDENT_RETAILER' && this.userType == '3'?1:0
      if( this.isBrand && (!this.formData.enterpriseReceivableScale || this.formData.enterpriseReceivableScale === '' )){
        this.$toast('请选择企业年营收规模')
        return
      }
      console.log('-----------------------',dataSources)
      try {
        this.$loading.show()
        const {content = '', visitMode = '', followUpTime = '', imgUrl = '', enterpriseReceivableScale = ''} = this.formData
        const param = {                //类型：Object  必有字段  备注：无
          identity: this.identity,
          userId: this.userId,
          dataSources: dataSources,
          strategyId: this.$route.query.strategyId,
          content,
          visitMode,
          followUpTime,
          imgUrl,
          enterpriseReceivableScale
        }
        param.content = editEditor.txt.html()
        if (this.id) {
          param.id = this.id
        }
        if (this.identity === 'USER_LABEL_IDENT_RETAILER'&&this.shopId){
          param.dataSources = 1
          param.shopId=this.shopId
        }
        await saveVisitRecordInfo(param)
        localStorage.setItem('jumpData','1')
        this.$toast('操作成功')
        this.formData = {
          visitMode: '',
          visitModeName: '',
          followUpTime: '',
          followUpTimeLabel: '',
          content: '',
          enterpriseReceivableScale:'',
          enterpriseReceivableScaleName: '',
          imgUrl: []
        }
        this.$EventBus.$emit('ADD_FOLLOW')
        setTimeout(() => {
          this.onCloseModal()
        }, 1000)
      } catch (e) {
        this.$loading.hide()
        this.$toast(e)
      } finally {
        // this.$loading.hide()
      }
    },
    /**
     * 浏览器触发回退触发
     */
    onCloseModal() {
      let lock = false
      for (const key in this.formData) {
        if (key === 'imgUrl' && this.formData[key].length) {
          lock = true
          break
        }
        if (key !== 'imgUrl' && this.formData[key]) {
          lock = true
          break
        }
      }
      if (lock) {
        this.$dialog.confirm({
          title: '提示',
          message: '当前内容未保存确认返回上一页？',
          closeOnPopstate: false
        }).then(() => {
          this.$router.go(-1)
        }).catch(() => {
          history.pushState(null, null, document.URL)
        })
        this.$loading.hide()
      } else {
        this.$loading.hide()
        this.$router.go(-1)
      }
    },
    /**
     * 获取跟进记录详情
     */
    async getVisitRecordDetail(param) {
      const res = await getVisitRecordDetail(param)
      if (res) {
        res.imgUrl = res.imgUrl ? JSON.parse(res.imgUrl) : []
        this.defaultImg = res.imgUrl
        this.formData = {
          visitMode: res.visitMode,
          visitModeName: res.visitModeName || '',
          followUpTime: res.followUpTime,
          followUpTimeLabel: '',
          content: res.content,
          imgUrl: res.imgUrl,
          enterpriseReceivableScale: res.enterpriseReceivableScale,
          enterpriseReceivableScaleName: res.enterpriseReceivableScaleName || '',
          id: res.id
        },
        this.$nextTick(()=>{
          this.editEditor()
        })
      }
    }
  }
}
</script>


<style scoped lang="scss">
::v-deep .van-overlay{
  z-index: 3 !important;
}
::v-deep #editEditor{
  ol{
    list-style:auto
  }
  ul{
    list-style:disc
  }
}
#app {
  height: 100%;
}

.container {
  padding: 30px;
  box-sizing: border-box;
  height: 100%;
  background-color: #F1F4F5;
  font-size: 24px;
}

.select-input {
  position: relative;

  .arrow-icon {
    position: absolute;
    width: 24px;
    height: 14px;
    right: 30px;
    top: 36px;
  }
}

.calendar-input {
  position: relative;

  .calendar-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 30px;
    top: calc(50% - 26px);
  }
}

.form-input {
  display: inline-block;
  width: 100%;
  line-height: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  padding: 0 30px;
  margin-bottom: 20px;
  color: #333333 !important;
}


.textarea-input {
  position: relative;

  .text-length {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: #999999;
    background-color: #fff;
  }
}

.text-area {
  display: inline-block;
  line-height: 36px;
  height: 350px;
  padding: 24px 30px 34px;
}

.form-title {
  padding-bottom: 20px;
}

.submit-btn {
  width: calc(100vw - 60px);
  color: #FFFFFF;
  line-height: 80px;
  text-align: center;
  position: fixed;
  bottom: 58px;
  left: 30px;
  font-size: 28px;
  background: #4484FF;
  border-radius: 20px;
}
</style>
