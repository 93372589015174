import ClipboardJS from 'clipboard'
import Vue from 'vue'
import { Toast } from 'vant';
import store from '@/store'
import { dicType } from "@/api/common";

export const debounce = (func, wait = 50) => {
  let timer = 0
  return function(...args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}

export const throttle = (func, wait = 50) => {
  let lastTime = 0
  return function(...args) {
    const now = +new Date()
    if (now - lastTime > wait) {
      lastTime = now
      func.apply(this, args)
    }
  }
}

export const copyText = (text = 'text', func) => {
  const copyDom = document.getElementById('app')
  const clipboard = new ClipboardJS(copyDom, {
    text: () => text
  })
  const timer = setInterval(() => {
    copyDom.click()
  }, 500)
  clipboard.on('success', e => {
    func ? func() : Toast.success('复制成功');
    clearInterval(timer)
    // 释放内存
    clipboard.destroy()
  })
  clipboard.on('error', e => {
    // 不支持复制
    Vue.prototype.$message({
      type: 'error',
      message: '该浏览器不支持自动复制'
    })
    clearInterval(timer)
    // 释放内存
    clipboard.destroy()
  })
}

export const forClearCache = (route) => {
  // 清除缓存页面的缓存记录
  const name = route.name + '-query' // 查询条件缓存
  const name2 = route.name + '-form' // 查询条件缓存2
  const name3 = route.name + '-pagination' // 分页数据缓存
  const name4 = route.name + '_tree_node_id' // 左侧树形控件缓存
  const name5 = route.name + '-activeName' // 列表页tabs
  sessionStorage.removeItem(name)
  sessionStorage.removeItem(name2)
  sessionStorage.removeItem(name3)
  sessionStorage.removeItem(name4)
  sessionStorage.removeItem(name5)
}

// 时间戳转 yyyy-MM-dd HH:mm:ss
export function timetampFormat(timestamp) {
  const date = new Date(timestamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

let nodes = []

// 根据id获取节点路径
export function getParentNodes(id, tree, key = 'id') {
  _getParentNodes([], id, tree, key)
  const p = nodes.map(v => v[key])
  return p
}

function _getParentNodes(his, targetId, tree, key) {
  tree.some((item) => {
    const children = item.children || []
    if (item[key] === targetId) {
      nodes = his
      return true
    } else if (children.length > 0) {
      const history = [...his]
      history.push(item)
      return _getParentNodes(history, targetId, children, key)
    }
  })
}

/**
 * @param obj object类型
 * @param path object结构路径 eg:main.options[0].children[0].options
 * @return object 对应 path 的 value 备注：如果需要返回的value与原对象保持一致的引用地址，则最终的返回值应控制在一个复杂类型的值
 */
export function getByPath(obj, path) {
  return path.split('.').reduce((subObj, key) => {
    let selfObj = subObj[key]
    if (!selfObj) {
      const startIndex = key.indexOf('[')
      const endIndex = key.indexOf(']')
      if (startIndex > -1 && endIndex > -1) {
        const index = key.substring(startIndex + 1, endIndex)
        const selfKey = key.substring(0, startIndex)
        selfObj = subObj[selfKey][index]
      }
    }
    if (!selfObj) {
      console.warn('getByPath wran', 'path参数解析 与 obj参数对象结构不对应！！！')
      return null
    }
    return selfObj
  }, obj)
}

export class StorageManager {
  constructor (key, defaultValue) {
    this.key = key
    this.defaultValue = JSON.stringify(defaultValue)
  }
  get item () {
    return JSON.parse(localStorage.getItem(this.key)  ?? this.defaultValue)
  }
  set item (val) {
    localStorage.setItem(this.key, JSON.stringify(val))
  }
  remove () {
    localStorage.removeItem(this.key)
    return JSON.parse(this.defaultValue)
  }
}

// 获取字典的detailName
export async function getDictName (dictCode, key) {
  const res = await dicType([{code: dictCode}])
  const detailName = res[dictCode].find(item => item.detailCode == key)?.detailName
  return detailName
}

export function buttonAllowed (code) {
  return store.getters.getAuthButton.some(val => val === code)
}
export function returnUserType(item) {
  if (item){
        let userType = item.userType
        if (!userType){
          if (item.dataType==1){
            userType = 4
          }else {
            if (item.grade == 2){
              userType = 2
            }else if (item.grade == 3){
              userType = 3
            }
          }
        }
        let name = '';
        this.userType = userType
        if (userType == 2){
          if (item.certificationType==1){
            name = '认证用户-企业'
          }else {
            name = '认证用户-个人'
          }
        }else if (userType==4){
          name = '手工录入'
        }else if (userType==3){
          name = '已过期白金会员'
        }else if(userType==1) {
          name = '专业观众'
        }else {
          name = '未认证'
        }
        return name
  }else {
    return '--'
  }
}

