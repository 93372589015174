<template>
  <div class="yf-uploader">
    <div class="yf-uploader__list">
      <div v-for="(item, index) in videoCoverList" :key="item" class="yf-uploader__item">
        <img :src="item" alt="" class="yf-uploader__img" @click="videoPreview(index)">
<!--        <video :src="item" class="video" @click="videoPreview(item,index)"-->
<!--               preload="auto" webkit-playsinline="true" playsinline="true" x-webkit-airplay="allow"-->
<!--               x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint">-->
<!--          您的浏览器不支持 video 标签。-->
<!--        </video>-->
        <div class="yf-uploader__delete" @click="videoDel(index)"><van-icon name="cross" size="14" /></div>
      </div>
      <div v-if="videoList.length < limit" class="yf-uploader__input-wrapper">
        <img class="yf-uploader__file" src="@/assets/image/video-upload.png">
        <div v-if="isApp" class="yf-uploader__input" @click="uploadVideoOss"></div>
        <input
          v-else
          id="videoId"
          ref="fileInput"
          accept="video/*"
          class="yf-uploader__input"
          type="file"
          @change="change($event)"
        />
      </div>
    </div>
    <van-popup v-model="videoShow" :style="{ width: '80%', height: '60%' }">
      <video id="video1" class="popup-video" :src="videoSrc" controls="controls">
        您的浏览器不支持 video 标签。
      </video>
    </van-popup>

  </div>
</template>
<script>
import moment from "moment";
import navigator from "@/commonjs/navigator";
import {cloneDeep} from "lodash";
import AppBridge from "@/commonjs/appBridge";
import Signature from "@/utils/signature";
import VueCookies from "vue-cookies";
export default {
  props: {
    // 默认视频
    initVideoList: {
      type: [String,Array,Object,Number],
      default: () => []
    },
    limit: {
      type: Number,
      default: 1
    },
    // 文件夹
    folder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      videoSrc: '',
      videoShow: false,
      isApp: false,
      filename: '',
      videoCoverList: [],
      videoList: [],
      videoListSuffix: [],
      dialogImageUrl: '',
      dialogVisible: false,
      form: {},
      pathDir: null,
      uploadImgUrl: null,
      showBtnImg: true,
      noneBtnImg: false,
      isFirst: false
    }
  },
  watch: {
    initVideoList: {
      handler(value) {
        if (value){
          if(!this.isFirst) { // 初始化的时候执行 只执行一次
            this.isFirst = true
            this.videoListSuffix = []
            this.videoCoverList = []
            this.videoList = []
            /**
             * 跟服务端定义好
             * 单张传字符串
             * 多张传数组
             * */
            if(this.limit === 1) {
              value = [value]
            }
            value.forEach((item) => {
              this.renderVideo(item)
            })
            this.videoListSuffix = cloneDeep(value)
            // this.handleCallbackImgList()
          }
        }

      },
      immediate: true
    }
  },
  mounted() {
    const isApp = navigator.openInWebview();
    const isEquipment = navigator.isEquipment();
    const isIos = isEquipment.isIOS;
    const isAndroid = isEquipment.isAndroid;
    let userAgent = window.navigator.userAgent;
    if(userAgent.indexOf('_yifeiApp_') > -1|| userAgent.search('_yifeiApp_')> -1||userAgent.indexOf('yifei') > -1|| userAgent.search('yifei')> -1){
      this.isApp =true
      // console.log('当前状态不在微信 是当前APP打开的页面')
    }
  },
  methods: {
    // 删除
    videoDel(index) {
      this.videoCoverList.splice(index, 1)
      this.videoList.splice(index, 1)
      this.videoListSuffix.splice(index, 1)
      this.handleCallbackImgList()
    },
    // 图片预览
    videoPreview(index) {
      this.videoSrc = this.videoList[index]
      this.videoShow = true
    },
    async uploadVideoOss() {
      try {
        const res = await AppBridge.upload({
          dirName: this.folder,
          mediaType: AppBridge.UPLOAD_TYPE.video,
          isCrop: false
        })
        await this.renderVideo(res.objectKey)
        this.$emit('handleGetVideoList', this.videoListSuffix)
      } catch (error) {
        console.log(error)
      }
    },
    change() {
      const file = this.$refs.fileInput.files[0] // 获取文件流
      const isVideoType = file.type.includes('video')
      const isLt5M = file.size / 1024 / 1024 < 300
      this.filename = file.name
      if (!isVideoType) {
        this.$toast('只能上传视频类型')
        return false
      }
      if (!isLt5M) {
        this.$toast('上传视频大小不能超过 300MB!')
        return false
      }
      this.$loading.show()
      this.getSignature(file)
    },
    async getSignature(file) {
      const signature = Signature.getInstance()
      const client = await signature.get()
      const year = moment().format('YYYY')
      const month = moment().format('MM')
      const day = moment().format('DD')
      const timestamp = moment().valueOf()
      const userId = VueCookies.get('userId') || 'userId'
      const path = `${this.folder}/${year}/${month}/${day}/${userId}_${timestamp}_${this.filename}`
      try {
        const result = await client.put(path, file);
        const url = client.signatureUrl(path);
        const imgUrl =  client.signatureUrl(path, {expires: 300000, process: 'video/snapshot,t_0,f_jpg,w_400,h_400,m_fast,ar_auto'});
        this.videoCoverList.push(imgUrl)
        this.videoList.push(url)
        this.videoListSuffix.push(path)
        this.handleCallbackImgList()
        this.$loading.hide()
      } catch (e) {
        this.$loading.hide()
        console.log(e);
      }
    },
    handleCallbackImgList() {
      this.videoListSuffix = this.videoListSuffix || []
      /**
       * 回调
       * 单张传字符串
       * 多张传数组
       * */
      const videoList = this.limit === 1 ? this.videoListSuffix[0] : this.videoListSuffix
      this.$emit('handleGetVideoList', videoList)
    },
    async renderVideo(path) {
      const signature = Signature.getInstance()
      const client = await signature.get()
      const url =  client.signatureUrl(path);
      const imgUrl =  client.signatureUrl(path, {expires: 300000, process: 'video/snapshot,t_0,f_jpg,w_400,h_400,m_fast,ar_auto'});
      this.videoCoverList.push(imgUrl)
      this.videoList.push(url)
      this.videoListSuffix.push(path)
    },
  }
}
</script>
<style lang="scss" scoped>
.yf-uploader {
  &__list {
    display: flex;
  }
  &__item {
    position: relative;
    margin-right: 10px;
  }
  &__file {
    width: 157px;
    height: 157px;
  }
  &__img {
    width: 157px;
    height: 157px;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid #e5e5e5;
  }
  &__delete {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    opacity: 0.5;
    border-radius: 4px 10px 4px 4px;
    color: #fff;
  }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    z-index: 99;
  }
  &__input-wrapper {
    position: relative;
  }
  .video {
    width: 159px;
    height: 159px;
    border-radius: 10px;
  }
  .popup-video {
    width: 100%;
    height: 100%;
  }
}
</style>
