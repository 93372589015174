import request from '@/utils/request'
import {NET_WORK_ENUM} from '@/api/index'

/**
 * BD成功 最新 统一接口
 * @param data
 * @returns {*}
 */
let MY_CUSTOMERS = '/crm/v1/customer/my_customers';
/**
 * 五大身份 领取/分配BD/移交
 * @param data
 * @returns {*}
 */
let ALL_RECEIVE = '/international/waters/v1/common/all_receive';
/**
 * 五大身份-退回公海
 * @param data
 * @returns {*}
 */
let RELEASE_SEA = '/crm/v1/customer/no_cooperation/all_release';

export function release_sea(data = {}) {
    return request({
        url: RELEASE_SEA,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
export function all_receive(data = {}) {
    return request({
        url: ALL_RECEIVE,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function my_customers(data = {}) {
    return request({
        url: MY_CUSTOMERS,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
