<template>
  <van-popup
      v-model="filterShow"
      position="bottom"

      round
      @close="close"
  >
    <div class="title" style="position: fixed;background: #FFFFFF;">
      筛选
      <span style="position: absolute;right: 14px;min-width: 100px;text-align: right" @click="close">
            <van-icon name="cross" size="12" color="#000000" style="margin-top: 3px" />
      </span>
    </div>
    <div class="optionWrap-all">
      <div class="optionWrap">
        <div>
          <CheckBoxAndSelectShow :key="'isRegister'"  :type="'select'" @dataCallBack="dataCallBack" :title="'是否注册'" :name="'isRegister'" :options="isRegisterArr" />
          <CheckBoxAndSelectShow  :key="'isMarker'"  :type="'select'" @dataCallBack="dataCallBack" :title="'关键决策人'" :name="'isMarker'" :options="isMarkerArr" />
        </div>
        <div style="height: 100px"></div>
      </div>
    </div>

    <div class="bottom">
      <div class="resetting" @click="resetClick">重置</div>
      <div class="sure" @click="sureClick">确定({{ totals||0 }}个客户)</div>
    </div>
  </van-popup>
</template>

<script>
import CheckBoxAndSelectShow from '@/components/checkBoxAndSelectShow/index';
import {contact_list} from "@/api/contact";
export default {
  props:{
    searchValue:{
      type:String,
      default:"",
    },
    filterShow:{
      type:Boolean,
      default:false,
    },
  },
  watch:{
    totalAll:{
      handler(val) {
        this.totals = val
      },
      immediate: true
    }
  },
  components:{
    CheckBoxAndSelectShow
  },
  data() {
    return {
      totals:0,
      dataShow:false,
      name:'',
      title:'',
      isRegisterArr:[{
        detailName:'是',
        detailCode:1
      },{
        detailName:'否',
        detailCode:0
      }],
      isMarkerArr:[{
        detailName:'是',
        detailCode:1
      },{
        detailName:'否',
        detailCode:0
      }],
      form:{
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  mounted() {
    this.initMounted()
  },
  methods:{
    initMounted(){
      this.isRegisterArr=[{
        detailName:'是',
        detailCode:1
      },{
        detailName:'否',
        detailCode:0
      }],
       this.isMarkerArr=[{
        detailName:'是',
        detailCode:1
      },{
        detailName:'否',
        detailCode:0
      }]
    },
    dataCallBack(data){
      console.log('测试',data)
      this.$set(this.form,data.name,data.value)
      console.log(this.form)
      this.initFilter()
    },
    initFilter(){
      let that = this;
      this.form.pageNum = 1;
      this.form.pageSize = 20;
      that.initFilterList()
    },
    initFilterList(){
      console.log(this.form)
      this.delDataCallBack()
    },
    delDataCallBack(){
      let that = this;
      this.form.name = this.searchValue
      contact_list(this.form).then(res=>{
        console.log('查询联系人的数据=',res)
        that.totalNumCallBack(res)
      })
    },
    totalNumCallBack(res){
      console.log('totalNumCallBack',res)
      this.totals = res?res.totalNum:0
    },
    onCancel(){
      this.dataShow = false
    },
    close(){
      this.$emit('close')
    },
    sureClick(){
      this.form.pageNum = 1;
      this.form.pageSize = 20;
      this.$emit('sureCallBack',this.form)
      this.close()
    },
    resetClick(){
      this.initMounted()
      this.form = {
        pageNum:1,
        pageSize:20,
        isRegister:'',
        isMarker:''
      }
      this.initFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/filter';
</style>
