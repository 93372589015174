<template>
<div>
  <span class="user-type-span">
                <img class="addr-img" v-if="isImg" style="margin-bottom: -1px" :src="userType==2?certification:(userType==4?manual:no)"/>
               {{ returnUserType(item) }}
              </span>
</div>
</template>

<script>
import certification from '../../assets/image/not/certification.png';
import manual from '../../assets/image/not/manual.png'
import no from '../../assets/image/not/no.png'
export default {
  name: "userType",
  props:{
    isImg:{
      type:Boolean,
      default:true
    },
    item:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      userType:'',
      certification:certification,
      manual:manual,
      no:no
    }
  },
  methods:{
    returnUserType(item){
      if (item){
        let userType = item.userType
        if (!userType){
          if (item.dataType==1){
            userType = 4
          }else {
            if (item.grade == 2){
              userType = 2
            }else if (item.grade == 3){
              userType = 3
            }
          }
        }
        let name = '';
        this.userType = userType
        if (userType == 2){
          if (item.certificationType==1){
            name = '认证用户-企业'
          }else {
            name = '认证用户-个人'
          }
        }else if (userType==4){
          name = '手工录入'
        }else if (userType==3){
          name = '已过期白金会员'
        }else if(userType==1) {
          name = '专业观众'
        }else {
          name = '未认证'
        }
        return name
      }else {
        return '--'
      }

    },
  }
}
</script>

<style scoped lang="css">
.addr-img{
  width: 24px;
  height: 24px;
}
.user-type-span{
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
}
</style>
