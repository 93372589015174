/***
 * 详情
 */
const shopDetails = () => import('@/views/details/shopDetails')
const favouriteDetails = () => import('@/views/details/favouriteDetails')
const mcnDetails = () => import('@/views/details/mcnDetails')
const serverDetails = () => import('@/views/details/serverDetails')
const factoryDetails = () => import('@/views/details/factoryDetails')
const brandDetails = () => import('@/views/details/brandDetails')
const manualDetails = () => import('@/views/details/manualDetails')
const followUpRecord = () => import('@/views/details/followUpRecordList') // 跟进记录
const ContactDetail = () => import('@/views/details/contactDetail')// 联系人详情
const makeLabel = () => import('@/views/details/makeLabel') // 打标签
const bindContact = () => import('@/views/details/bindContact') // 绑定联系人

const detailsRouter = [
	{
		path: '/contact_details',
		name: 'ContactDetail',
		component: ContactDetail,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/manual_details',
		name: 'ManualDetails',
		component: manualDetails,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/shop_details',
		name: 'ShopDetails',
		component: shopDetails,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/favourite_details',
		name: 'FavouriteDetails',
		component: favouriteDetails,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/mcn_details',
		name: 'McnDetails',
		component: mcnDetails,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/server_details',
		name: 'serverDetails',
		component: serverDetails,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/factory_details',
		name: 'factoryDetails',
		component: factoryDetails,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/brand_details',
		name: 'brandDetails',
		component: brandDetails,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	},
	{
		path: '/follow_up_record',
		name: 'FollowUpRecord',
		component: followUpRecord,
		meta: {
			keepAlive: true,
			title: "跟进记录",
		}
	},
	{
		path: '/make_label',
		name: 'MakeLabel',
		component: makeLabel,
		meta: {
			keepAlive: false,
			title: "打标签",
		}
	},
	{
		path: '/bind_contact_for_detail',
		name: 'BindContactForDetail',
		component: bindContact,
		meta: {
			keepAlive: false,
			title: "绑定联系人",
		}
	},
]
export default detailsRouter
