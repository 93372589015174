/**
  * @Description: 控制按钮显示隐藏的指令
*/
import { buttonAllowed } from '@/utils/util'

export default {
  install(Vue) {
    Vue.directive('auth', {
      bind(el, binding) {
        const isAllow = buttonAllowed(binding.value)
        const element = el
        if (!isAllow && binding.value) {
          element.style.display = 'none'
          if (binding.arg && typeof binding.arg === 'function') {
            binding.arg({ tips: '没权限' })
          }
        }
      }
    })
  }
}
