<template>
  <div class="loading" id="contain" v-if="show">
    <div class="loading-mask"></div>
    <div class="loading-block">
      <div class="loading-center">
        <div class="loading-wrap">
          <div class="loading-part"></div>
        </div>
        <div class="loading-wrap loading-wrap-right">
          <div class="loading-part loading-part-right"></div>
        </div>
      </div>
      <div class="loading-text">loading…</div>
    </div>
  </div>
</template>

<script>
export default {
name: "index",
  props: {
    show: Boolean,
  },
}
</script>

<style scoped>
.loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100000;
}
.loading-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  z-index: 100;
  margin: auto;
  animation: fadeIn 0.5s 1;
  animation-fill-mode: forwards;
}
.loading-center {
  position: relative;
  display: flex;
  align-items: center;
  width: 200px;
  height: 104px;
}
.loading-wrap {
  animation: rotate 500ms infinite ease-in-out alternate, zindex 1000ms infinite ease-in-out;
  position: absolute;
  left: 40px;
  z-index: 0;
}
.loading-part {
  width: 40px;
  height: 40px;
  background-color: #EA3C3D;
  border-radius: 50%;
  animation: scale 500ms infinite ease-in-out alternate;
  animation-delay: -250ms;
  transform: scale(0.5);
}
.loading-wrap-right {
  animation-delay: -500ms;
}
.loading-part-right {
  background-color: #256FF7;
  animation-delay: -750ms;
}
.loading-text {
  font-size: 28px;
  color: #333;
  opacity: 0.9;
}

@keyframes rotate {
  100% {
    transform: translateX(68px);
  }
}
@keyframes scale {
  100% {
    transform: scale(0.7);
  }
}
@keyframes zindex {
  25% {
    z-index: 1;
  }

  75% {
    z-index: -1;
  }
}
@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
</style>