<template>
  <van-popup
      v-model="filterShow"
      position="bottom"
      :style="{ height: '90%' }"
      round
      @close="close"
  >
    <div class="title" style="position: fixed;background: #FFFFFF;z-index: 1000">
      筛选
      <span style="position: absolute;right: 14px;min-width: 100px;text-align: right" @click="close">
            <van-icon name="cross" size="12" color="#000000" style="margin-top: 3px" />
      </span>
    </div>
    <div class="optionWrap-all">
      <div class="optionWrap">
        <CheckBoxAndSelectShow :key="'identityList'+tabState" :selectList="form.identityList" :isArray="true" :type="'cb'" @dataCallBack="dataCallBack" :title="'用户身份'" :name="'identityList'"  :options="identityArr" />
        <CheckBoxAndSelectShow :key="'userTypeList'+tabState" :selectList="form.userTypeList" :isArray="true" :type="'cb'" @dataCallBack="dataCallBack" :title="'用户类型'" :name="'userTypeList'" :options="userTypeArr" />
        <CheckBoxAndSelectShow :key="'provinceList'+tabState" :selectList="form.provinceList" :type="'cb'" :isArray="true"  @dataCallBack="dataCallBack" :title="'地域'" :name="'provinceList'" :options="provinceArr" />
        <div>
          <div class="name">粉丝数</div>
          <div class="options">
            <van-field
                v-model="form.followersMin" @input="initFilter"
                class="num-field" input-align="center" placeholder="最小值" maxlength="9" type="number" label="" />
            <div style="color: #999999;line-height: 20px">——</div>
            <van-field
                v-model="form.followersMax" @input="initFilter"
                class="num-field" input-align="center" placeholder="最大值" maxlength="9" type="number" label="" />
          </div>
        </div>
        <div>
          <div class="name">签约红人数</div>
          <div class="options" >
            <van-field
                v-model="form.netRedMinNum" @input="initFilter"
                class="num-field" input-align="center" placeholder="最小值" maxlength="9" type="number" label="" />
            <div style="color: #999999;line-height: 20px">——</div>
            <van-field
                v-model="form.netRedMaxNum" @input="initFilter"
                class="num-field" input-align="center" placeholder="最大值" maxlength="9" type="number" label="" />
          </div>
        </div>
        <CheckBoxAndSelectShow :key="'serviceTypeList'+tabState"  :type="'cb'" @dataCallBack="dataCallBack" :title="'服务商类型'" :name="'serviceTypeList'" :options="providerObjectTypeArr" />

        <CheckBoxAndSelectShow :key="'factoryTypeList'+tabState"  :type="'cb'" @dataCallBack="dataCallBack" :title="'工厂类型'" :name="'factoryTypeList'" :options="factoryTypeArr" />
        <div>
          <div class="name">跟进天数</div>
          <div class="options">
            <van-field
                v-model="form.days" @input="initFilter"
                class="num-field" input-align="center" placeholder="跟进天数" maxlength="9" type="number" label="" />
<!--            <div style="color: #999999;line-height: 20px">——</div>-->
<!--            <van-field-->
<!--                v-model="form.daysMax" @input="initFilter"-->
<!--                class="num-field" input-align="center" placeholder="最大值" maxlength="9" type="number" label="" />-->
          </div>
        </div>
        <CheckBoxAndSelectShow :key="'visitCount'+tabState" :ref="'visitCount'" :type="'select'" @dataCallBack="dataCallBack"
                               :title="'跟进次数'" :name="'visitCount'" :options="visitCountArr" />
        <CheckBoxAndSelectShow :key="'followUpPersonIdList'+tabState" :ref="'followUpPersonIdList'"  @dataCallBack="dataCallBack"
                               :title="'BD人员'" :isArray="true" :type="'cb'" :name="'followUpPersonIdList'" :options="followUpPersonArr" />
        <CheckBoxAndSelectShow :key="'suspectedWhiteGold'" :ref="'suspectedWhiteGold'"    :type="'select'"
                               @dataCallBack="dataCallBack" :is-show-popover="true"
                               :title="'疑似白金'" :name="'suspectedWhiteGold'" :options="suspectedWhiteGoldArr" />
        <div v-if="tabState==0">
<!--          <CheckBoxAndSelectShow :key="'expiry'+tabState" :ref="'expiry'" :type="'select'" @dataCallBack="dataCallBack"-->
<!--                                 :title="'到期时间'" :name="'expiry'" :options="expiryList" />-->
          <div class="name">到期时间</div>
          <div class="options" >
            <div style="background: #FFFFFF;line-height: 26px">自定义</div>
            <span style="color: #999999" :class="typeof(form.expiryStartTime) == 'undefined'?'color-size':''"  @click="expiryStartClick">{{ form.expiryStartTime?form.expiryStartTime.substring(0,10):'开始时间'}}</span>
            <div style="line-height: 26px;color: #999999">——</div>
            <span style="color: #999999" :class="typeof(form.expiryEndTime) == 'undefined'?'color-size':''"   @click="expiryEndClick">{{ form.expiryEndTime?form.expiryEndTime.substring(0,10):'结束时间'}}</span>
          </div>
        </div>
        <div v-if="tabState==1">
          <div class="name">领取时间</div>
          <div class="options" >
            <div style="background: #FFFFFF;line-height: 26px">自定义</div>
            <span style="color: #999999" :class="typeof(form.assignStartTime) == 'undefined'?'color-size':''"  @click="assignStartClick">{{ form.assignStartTime?form.assignStartTime.substring(0,10):'开始时间'}}</span>
            <div style="line-height: 26px;color: #999999">——</div>
            <span style="color: #999999" :class="typeof(form.assignEndTime) == 'undefined'?'color-size':''"   @click="assignEndClick">{{ form.assignEndTime?form.assignEndTime.substring(0,10):'结束时间'}}</span>
          </div>
        </div>
        <div style="height: 100px"></div>
      </div>
    </div>
    <DateTimeItem :key="name+tabState" v-if="dataShow"   :dataShow="dataShow" :name="name" :title="title" @onCancel="onCancel" @onConfirm="onDataConfirm"/>

    <div class="bottom">
      <div class="resetting" @click="resetClick">重置</div>
      <div class="sure" @click="sureClick">确定({{ totals||0 }}个客户)</div>
    </div>
  </van-popup>
</template>

<script>
import DateTimeItem from '../../../components/DateTimeItem';
import CheckBoxAndSelectShow from '@/components/checkBoxAndSelectShow';
import {dicType, category, province_list, select_all_category, get_depart_user_list} from '@/api/common';
import {returnExpireData} from '@/utils/check/changeData'
import {my_customers} from "@/api/cooperating/bd";
export default {
  props:{
    searchValue:{
      type:String,
      default:"",
    },
    tabState:{
      type:Number,
      default:null,
    },
    filterShow:{
      type:Boolean,
      default:false,
    },
    formItem:{
      type:Object,
      default:()=> {},
    },
    totalAll:{
      type:Number,
      default:null,
    }
  },
  watch:{
    filterShow:{
      handler(val) {
        if (val){
          this.form = this.formItem
          this.totals = this.totalAll
        }
      },
      immediate: true
    },
  },
  components:{
    DateTimeItem,
    CheckBoxAndSelectShow
  },
  data() {
    return {
      totals:0,
      dataShow:false,
      name:'',
      title:'',
      expiryList:[],
      visitCountArr:[],
      suspectedWhiteGoldArr:[

      ],
      categoryArr:[],
      sourcePlatformCodeArr:[],
      factoryTypeArr:[],
      providerObjectTypeArr:[],
      brandTypeArr:[],
      tradeTypeArr:[],
      provinceArr:[],
      identityArr:[],
      userTypeArr:[],
      followUpPersonArr:[],
      form:{
        expiryStartTime:'',
        expiryEndTime:'',
        assignStartTime:'',
        assignEndTime:'',
        followersMax:'',
        suspectedWhiteGold:'',
        followersMin:'',
        netRedMinNum:'',
        netRedMaxNum:'',
        days:'',
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  mounted() {
    this.initMounted();
  },
  methods:{
    initMounted(){
      let data = [
        {code:'USER_TYPE',dicStatus: 1},
        {code:'BUY_EQUITY_IDENTITY',dicStatus:1},
        {code:'CRM_VISIT_COUNT',dicStatus:1},
        {code:'PLATFORM_CODE',dicStatus:1},

      ]
      this.initDictCode(data);
      this.allCategory();
      this.province();
      this.initCategory(2);
      this.initCategory(3);
      this.getUserByName()
      this.expiryList=[
        {detailName:'近1个月到期',detailCode:'1'},
        {detailName:'近2个月到期',detailCode:'2'},
        {detailName:'近3个月到期',detailCode:'3'},
      ];
    },
    getUserByName() {
      let data = {};
      data={
        allDepartName: [
          "仪美尚,会员部,拓展组"
        ]
      }
      get_depart_user_list(data).then(res => {
        res = res[0].childs || []
        if (res.length !== 0) {
          res.forEach(item => {
            item.detailCode = item.userNo;
            item.detailName = item.userName;
          })
          this.followUpPersonArr = res
        }
      })
    },
    allCategory(){
      let data = {
        level:1,
        status:2,
        pid:'',
        isShow:1
      }
      select_all_category(data).then(res=>{
        res= res||[]
        if (res.length!==0){
          let arr = res
          arr.forEach(item=>{
            item.detailName = item.name
            item.detailCode = item.id
          })
          this.categoryArr = arr || []
        }
      })
    },
    // TODO 获取 全部省份
    province(){
      province_list().then(res=>{
        res = res || [];
        let arr = [];
        if (res.length!==0){
          res.forEach(item=>{
            arr.push({detailCode:item.provinceId,detailName:item.name})
          })
          this.provinceArr = arr;
        }
      })
    },
    initCategory(pid){
      category({type:pid}).then(res=>{
        res = res||[]
        let arr = [];
        if (res.length!==0){
          res.forEach(item=>{
            arr.push({detailCode:item.id,detailName:item.name})
          })
          if (pid===3){
            this.factoryArr = res
            this.factoryTypeArr = arr;
          }else {
            this.providerObjectTypeArr = arr;
            this.serviceArr = res
          }
        }
      })
    },
    // TODO 数据字典
    initDictCode(data){
      dicType({jsonData: data}).then(res=>{
        // TODO 跟进次数
        let visitCountArr = res.CRM_VISIT_COUNT||[];
        this.visitCountArr = visitCountArr;
        // TODO 用户身份
        let identityArr = res.BUY_EQUITY_IDENTITY||[];
        this.identityArr = identityArr
        // TODO 用户类型
        let userTypeArr = res.USER_TYPE||[];
        this.userTypeArr = userTypeArr
      })
      this.suspectedWhiteGoldArr = [{
          "detailCode": 1,
          "detailName": "是"
        },
        {
          "detailCode": 0,
          "detailName": "否"
        }]
    },
    dataCallBack(data){
      if(['serviceTypeList','factoryTypeList'].indexOf(data.name)> -1) {
        const map = {
          'serviceTypeList':this.serviceArr,
          'factoryTypeList':this.factoryArr
        }
        this.$set(this.form,data.name,this.getAllId(data.list,map[data.name]))
      }else {
        this.$set(this.form,data.name,data.value) //主营类目改用， 拼接了
      }
      this.initFilter()
    },
    getAllId(list,typeArr){
      let selected = []
      function findChild(val){
        if(Array.isArray(val)) {
          val.map(item => {
            findChild(item)
          })
        }else {
          selected.push(val.id);
          findChild(val.childList)
        }
      }
      list.map(item =>{
        findChild(typeArr.find(j =>{ return item.indexOf(j.id) > -1}))
      })
      return selected
    },
    initFilter(){
      let that = this;
      this.form.pageNum = 1;
      this.form.pageSize = 20;
      if (this.form.followersMin){
        this.form.followersMin = Number(this.form.followersMin)
      }
      if (this.form.followersMax){
        this.form.followersMax = Number(this.form.followersMax)
      }
      if (this.form.netRedMaxNum){
        this.form.netRedMaxNum = Number(this.form.netRedMaxNum)
      }
      if (this.form.netRedMinNum){
        this.form.netRedMinNum = Number(this.form.netRedMinNum)
      }
      if (this.form.days){
        this.form.days = Number(this.form.days)
      }
      //this.form.expiryEndTime = returnExpireData(this.form.expiry,1)
      that.initFilterList()
    },
    initFilterList(){
      console.log('initFilterList')
      console.log(JSON.parse(JSON.stringify(this.form)))
      for (let i in this.form){
        if (!this.form[i]){
          delete this.form[i]
        }
      }
      console.log('最终要请求查询的数据传参=',this.form)
      this.delDataCallBack()
    },
    delDataCallBack(){
      let that = this;
      this.form.crmSearch = this.searchValue
      that.form.tabState = this.tabState
      my_customers(this.form).then(res=>{
        console.log('查询未合作的数据=',res)
        that.totalNumCallBack(res)
      })
    },
    totalNumCallBack(res){
      console.log('totalNumCallBack',res)
      this.totals = res?res.totalNum:0
    },
    onDataConfirm(values){
      console.log('onDataConfirm',values)
      if (values){
        let data = values
        data = this.name.indexOf("Start") != -1?'00:00:00':'23:59:59'
        this.$set(this.form,this.name,values+' '+data)
        this.initFilter()
        this.onCancel()
      }
    },
    onCancel(){
      this.dataShow = false
    },
    expiryStartClick(){
      this.name = 'expiryStartTime'
      this.title = '开始时间'
      this.dataShow = true
    },
    assignStartClick(){
      this.name = 'assignStartTime'
      this.title = '开始时间'
      this.dataShow = true
    },
    expiryEndClick(){
      if (!this.form.expiryStartTime){
        this.$toast('请先选择开始时间！')
      }else {
        this.name = 'expiryEndTime'
        this.title = '结束时间'
        this.dataShow = true
      }
    },
    assignEndClick(){
      if (!this.form.assignStartTime){
        this.$toast('请先选择开始时间！')
      }else {
        this.name = 'assignEndTime'
        this.title = '结束时间'
        this.dataShow = true
      }
    },
    close(){
      this.$emit('close')
    },
    sureClick(){
      this.form.pageNum = 1;
      this.form.pageSize = 20;

      this.$emit('sureCallBack',this.form)
      this.close()
    },
    resetClick(){
      console.log('resetClick===',this.tabState)
      this.initMounted();
      this.form = {
        pageNum:1,
        pageSize:20,
        tabState:this.tabState
      }
      this.initFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/css/filter';
</style>
