/**
 * BD人员专用
 */
import BdPerson from '@/views/bdPerson/index'
const bdPersonRouter = [
    {
        path: '/bd_person',
        name: 'BdPerson',
        component: BdPerson,
        meta: {
            keepAlive: true,
            title: "我的客户",
        }
    }
]

export default bdPersonRouter
