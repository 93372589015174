// 判断是否在webview里打开   仅限真机，模拟不算
export function openInWebview() {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) === 'micromessenger') {
    // 微信浏览器判断
    return false
  } else if (ua.match(/QQ/i) === 'qq') {
    // QQ浏览器判断
    return false
  } else if (ua.match(/WeiBo/i) === 'weibo') {
    return false
  } else {
    if (ua.match(/Android/i) != null && ua.indexOf('_yifeiapp_') >= 0) {
      // return ua.match(/browser/i) == null
      return true
    } else if (ua.match(/iPhone/i) != null && ua.indexOf('yifei') >= 0) {
      // return ua.match(/safari/i) == null
      return true
    } else {
      return false
      // return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null;
    }
  }
}
var varMiniProgram = null
export function judgeEv() {
  return new Promise(resolve => {
    var ua = window.navigator.userAgent.toLowerCase()
    varMiniProgram = false
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      // ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
      // wx.miniProgram.getEnv(info => {
      //   varMiniProgram = info.miniprogram
      //   resolve(varMiniProgram)
      // })
    } else {
      resolve(varMiniProgram)
    }
  })
}
judgeEv()

export function isEquipment() {
  const UA = window.navigator.userAgent
  const isAndroid = /android|adr|linux/gi.test(UA)
  const isIOS = /iphone|ipod|ipad/gi.test(UA) && !isAndroid
  const isBlackBerry = /BlackBerry/i.test(UA)
  const isWindowPhone = /IEMobile/i.test(UA)
  const isMobile = isAndroid || isIOS || isBlackBerry || isWindowPhone
  const isWeixin = /MicroMessenger/gi.test(UA)
  const isMiniProgram = varMiniProgram
  const isApp = openInWebview()
  return {
    isAndroid,
    isIOS,
    isMobile,
    isWeixin,
    isMiniProgram,
    isWeixinH5: isWeixin && !isMiniProgram,
    isApp,
    isQQ: /QQ/gi.test(UA),
    isPC: !isMobile,
    isWeibo: /WeiBo/gi.test(UA),
    isNeedLogin: !isApp && !isMiniProgram // 非app和小程序的环境下需要手动登录
  }
}
export const userAgent = window.navigator.userAgent

export default {
  openInWebview,
  judgeEv,
  isEquipment,
  userAgent
}
