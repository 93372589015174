import CryptoJS from 'crypto-js'

const SECRET_KEY = CryptoJS.enc.Utf8.parse('96465acad5c271f2')
const SECRET_IV = CryptoJS.enc.Utf8.parse('ca40ddb3c08b6c2b')

/**
 * 加密方法
 * @param data
 * @returns {string}
 */
export function encrypt(data) {
  const str = CryptoJS.enc.Utf8.parse(data)
  const encrypted = CryptoJS.AES.encrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

/**
 * 解密方法
 * @param data
 * @returns {string}
 */
export function decrypt(data) {
  const encryptedHexStr = CryptoJS.enc.Base64.parse(data)
  const str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  })
  return CryptoJS.enc.Utf8.stringify(decrypt)
}
