<template>
  <!--  单选框 -->
  <van-popup v-model="selectShow"  round position="bottom" @click-overlay="onCancel">
    <van-picker
        :title="title"
        show-toolbar
        :value-key="valueKey"
        :columns="arr"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        confirm-button-text="完成"
        cancel-button-text="取消"
    />
  </van-popup>
</template>

<script>
export default {
name: "index",
  props:{
    arr:{
      type:Array,
      default:() =>[]
    },
    name:{
      type:String,
      default: ""
    },
    title:{
      type:String,
      default: ""
    },
    valueKey: { // 选项文字对应的键名
      type: String,
      default: "detailName"
    }
  },
  data() {
    return {
      selectShow:true
    }
  },
  methods:{
    onConfirm(value){
      this.$emit("onConfirm", value)
    },
    onChange(){

    },
    onCancel(){
      this.$emit('onCancel',false)
    },
  }
}
</script>

<style lang="scss" scoped >

</style>
