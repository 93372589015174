import request from '@/utils/request'
import { NET_WORK_ENUM } from '@/api/index'

//客保：拓展组客户总数折线图统计接口
export function chartList(data = {}) {
    return request({
        url: '/crm/v1/customer/no_cooperation_num/expand_dept/stats',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
//客保：历史记录（最近1年）汇总接口
export function tableList(data = {}) {
    return request({
        url: '/crm/customer/bd/task/v1/analysis/history/group',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

//客保：拓展组客户总数分页接口
export function get_expand_dept_page(data = {}) {
    return request({
        url: '/crm/v1/customer/no_cooperation/expand_dept/page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

//客保：获取用户整个菜单树接口
export function menu(data = {}) {
    return request({
        url: '/crm/work/table/menu',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

