export default [
  {
    path: '/query_factory',
    name: 'QueryFactory',
    component: () => import('@/views/gadgets/queryFactory'),
    meta: {
      keepAlive: true,
      title: "查询工厂",
    }
  },
  {
    path: '/query_brand',
    name: 'QueryBrand',
    component: () => import('@/views/gadgets/queryBrand'),
    meta: {
      keepAlive: true,
      title: "查询品牌",
    }
  },
  {
    path: '/query_shop',
    name: 'QueryShop',
    component: () => import('@/views/gadgets/queryShop'),
    meta: {
      keepAlive: true,
      title: "查询零售商店铺",
    }
  },
  {
    path: '/enter_customer',
    name: 'EnterCustomer',
    component: () => import('@/views/gadgets/enterCustomer'),
    meta: {
      keepAlive: false,
      title: "录入客户",
    }
  },
  {
    path: '/trend_chart',
    name: 'TrendChart',
    component: () => import('@/views/gadgets/trendChart'),
    meta: {
      keepAlive: false,
      title: "趋势图",
    }
  },
]
