import { getWechatConfigWx } from '@/api/common'
import wx from "weixin-js-sdk";
export function wxConfig(configInit) {
	if (configInit) {
		getWechatConfigWx().then((data) => {
      // console.log(res,'ressssssssss')
			console.log('data', data)
			if (data) {
				const d = data
				wx.config({
					debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
					// 开启调试模式,调用的所有api的返回值会在客户端alert出来
					appId: d.appId, // 必填，公众号的唯一标识
					timestamp: d.timestamp, // 必填，生成签名的时间戳
					nonceStr: d.nonceStr, // 必填，生成签名的随机串
					signature: d.signature,
					jsApiList: [
						"checkJsApi",
						"onMenuShareTimeline",
						"onMenuShareAppMessage",
						"updateAppMessageShareData",
						"updateTimelineShareData",
            "scanQRCode"
					], // 必填，需要使用的JS接口列表
				});
			}
		})
	}
}

export default wxConfig
