import { getSignature } from '@/api/file'
import { decrypt } from '@/utils/secret'
import moment from 'moment'
import OSS from 'ali-oss'
import { getUserId } from '@/utils/auth'

export default {
  methods: {
    getSignature(file, insertImgFn, folder = 'goods') {
      const filename = file.name
      getSignature({ timestamp: moment().valueOf() + Math.random() }).then(async(res) => {
        const client = new OSS({
          region: 'oss-cn-shanghai',
          accessKeyId: decrypt(res.AccessKeyId),
          accessKeySecret: decrypt(res.AccessKeySecret),
          stsToken: decrypt(res.SecurityToken),
          bucket: res.publicReadBucketName
        })
        const year = moment().format('YYYY')
        const month = moment().format('MM')
        const day = moment().format('DD')
        const timestamp = moment().valueOf()
        const userId = getUserId()
        const path = `${folder}/${year}/${month}/${day}/${userId}_${timestamp}_${filename}`
        try {
          const res = await client.put(path, file)
          insertImgFn(res.url)
        } catch (e) {
          console.log(e)
        }
      })
    }
  }
}
