<template>
  <div class="coo-body" id="vanTabs">
    <van-tabs v-model="active"  @change="onClickTab" color="#4484FF" title-active-color="#4484FF" title-inactive-color="#999999"	>
      <van-tab title="BD成功">
        <BdCustomer v-if="active === 0" :searchType="searchType" :placeholderSearch="placeholderSearch" :active="active"></BdCustomer>
      </van-tab>
      <van-tab title="已领取">
        <Received v-if="active === 1" :searchType="searchType" :placeholderSearch="placeholderSearch" :active="active"></Received>
      </van-tab>
      <van-tab title="联系人">
        <Contact v-if="active === 2" :searchType="searchType" :placeholderSearch="placeholderSearch" :active="active"/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Received from '@/views/bdPerson/received/receivedList'
import BdCustomer from "@/views/cooperating/components/BdCustomer";
import Contact from "@/views/contact/contactList"
export default {
  name: "index",
  components: {
    Contact,
    BdCustomer,
    Received
  },
  data() {
    return {
      placeholderSearch: '搜索名称',
      identity: '',
      searchType:'',
      active: Number(localStorage.getItem('active'))?Number(localStorage.getItem('active')):0,
    }
  },
  mounted() {
    this.identity = this.$route.query.identity||''
    this.onClickTab(this.$route.query.active)
    if (localStorage.getItem('active')){
      let active = localStorage.getItem('active')||0
      this.active = Number(active)
    }else {
      this.active = Number(this.$route.query.active || 0)
    }
  },
  methods:{
    onClickTab(val){
      console.log(val)
      this.active = val;
      localStorage.setItem('active',val)
    },
  },
}
</script>

<style lang="scss" scoped>
.van-tabs__wrap {
  touch-action: manipulation;
}

</style>
