<template>
  <div>
    <Search :value="searchValue" :placeholderSearch="placeholderSearch" @searchCallBack="searchCallBack" />
    <Content @multiple="multipleClick" @filterShowCallBack="filterShowCallBack" :total="total"  :multiple="false">
     <div class="infoWrap" ref="list" @scroll="alive">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoadCallBack"
      >
        <div class="">
          <van-checkbox-group v-model="checkedList" ref="checkboxGroup">
            <div  v-for="(item,index) in list" :key="index">
              <bdItem @recycleCallBack="recycleCallBack"
                      :is-show-user-type-name="false"
                            :identityType="identityType" @detailsItemCallBack="detailsItemCallBack"
                            @followCallBack="followCallBack" @connectCallBack="connectCallBack"
                            :item="item" :isMultiple="isMultiple"></bdItem>
            </div>
          </van-checkbox-group>
          <div style="height: 40px"></div>
        </div>
      </van-list>

     </div>
      <div class="isMultipleOp" v-if="isMultiple">
        <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
        <span class="num">已选({{checkedList.length}})</span>
        <div class="draw" >退回公海</div>
        <div class="distribution" >移交</div>
      </div>
      <filterPop :searchValue="searchValue" :tabState="0" @sureCallBack="sureCallBack"  :totalAll="total"  :filterShow="filterShow"
                 @close="onCancel" :formItem="bdForm">
      </filterPop>
    </Content>
    <ContactsDialog :connectItem="connectItem"  :is-show="isShowContacts" @contactsConfirm="onCancel" />
    <RecycleDialog :is-show="recycleShow"  :recycleItem="recycleItem" @closeCallBack="closeCallBack"/>

  </div>
</template>

<script>
import RecycleDialog from "@/views/components/recycleDialog";
import ContactsDialog from '@/components/ContactsDialog'
import filterPop from "@/views/bdPerson/components/filterPop";
import Content from '@/views/components/content.vue'
import bdItem from "./item/bdItem";
import Search from '@/views/components/search';
import {my_customers} from "@/api/cooperating/bd";
import {returnExpireData} from "@/utils/check/changeData";
export default {
  components:{
    Content,
    Search,
    bdItem,
    filterPop,
    ContactsDialog,
    RecycleDialog
  },
  props:{
    identityType:{
      type:String,
      default:"",
    },
    placeholderSearch:{
      type:String,
      default:"",
    },
    searchType:{
      type:String,
      default:"",
    }
  },
  watch:{
    active:{
      handler(val){
        console.log('点击tab===========',val)
        this.init()
      }
    }
  },
  data() {
    return {
      scrollPages: 0, // 缓存当前滚动条的位置
      recycleItem:null,
      recycleShow:false,
      connectItem:{},
      searchValue:'',
      total:0,
      isShowContacts:false,
      filterShow:false,
      loading: false,
      finished: false,
      list:[],
      checkedList:[],//选中数组
      isMultiple:false,
      checked:false,
      tabState:0,
      bdForm:{
        identityList:[],
        tabState:0,// 0 BD成功 1已领取
        pageNum:1,
        pageSize:20
      }
    };
  },
  created() {

  },
  activated() {
    let expire= this.$route.query.expire
    if (expire){
      this.bdForm.expireEndTime = returnExpireData(expire,1)
      this.bdForm.expireStartTime = returnExpireData(expire,2)
    }
    let {identity='',expandDeptAuthority='',assignStartTime='',assignEndTime=''} = this.$route.query
    console.log('我们进入================created',identity)
    this.bdForm.identityList = this.bdForm.identityList.length!==0?this.bdForm.identityList:(identity?identity.split(','):[])
    this.bdForm.expandDeptAuthority = Boolean(expandDeptAuthority) || null
    this.bdForm.assignStartTime = assignStartTime
    this.bdForm.assignEndTime = assignEndTime
    // 缓存组件激活时调用
    this.$refs.list.scrollTop = this.scrollPages; // 获取 dom 的scrollTop = 缓存的滚动条位置
  },
  methods:{
    alive(e) {
      // 获取页面滚动条的位置
      this.scrollPages = e.target.scrollTop;
    },
    recycleCallBack(item){
      this.recycleShow = true
      console.log('recycleShow',this.recycleShow)
      this.recycleItem = item
    },
    closeCallBack(){
      this.recycleShow = false
    },
    sureCallBack(item){
      this.bdForm = item;
      this.list = []
      this.init();
    },
    init(){
      let that = this;
      that.initUserTypeList();
    },
    async initUserTypeList(){
      this.bdForm.crmSearch = this.searchValue;
      for (let i in this.bdForm){
        if (!this.bdForm[i]){
          delete this.bdForm[i]
        }
      }
      try {
        this.loading = true
        this.total = 0
        this.bdForm.tabState = this.tabState
        const res = await my_customers(this.bdForm)
        this.initThenCallBack(res);
      }catch (e) {
        this.loading = false
        this.finished = true
      }
    },
    initThenCallBack(res){
      res.records = res.records||[]
      this.list.push(...res.records)
      this.bdForm.pageNum++
      this.loading = false
      this.total = Number(res.totalNum)
      if (res.records.length!=0 && this.list.length <= this.total) {
        this.finished = false
      }else {
        this.loading = false
        this.finished = true
      }
      console.log(res)
    },
    detailsItemCallBack(item){
      this.localStorageCallBack('1')
      let queryData ='?id=' + item.userId+'&identity='+item.identity + (item.identity == 'USER_LABEL_IDENT_BRAND' ? ('&brandId=' + item.strategyId) :'')//品牌身份多传个品牌id
      if (item.userType == 4) {
        this.$router.push({
          path: '/manual_details'+queryData
        })
      } else {
        switch (item.identity) {
          case 'USER_LABEL_IDENT_RETAILER':
            this.$router.push({
              path: '/shop_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_BRAND':

            this.$router.push({
              path: '/brand_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_RED':
            this.$router.push({
              path: '/favourite_details' +queryData
            })
            break;
          case 'USER_LABEL_IDENT_RED_ORG':
            this.$router.push({
              path: '/mcn_details'+queryData
            })
            break;
          case 'USER_LABEL_IDENT_FACTORY':
            this.$router.push({
              path: '/factory_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_SERVICE_PROVIDER':
            this.$router.push({
              path: '/server_details' + queryData
            })
            break;
        }
      }
    },
    followCallBack(item){
      this.localStorageCallBack('1')
      this.$router.push({
        path: '/fill_in_follow_up?identity='+item.identity+'&userId='+item.userId
      })
    },
    localStorageCallBack(index){
      localStorage.setItem('active',index)
    },
    connectCallBack(item){
      let data = {
        id:item.id,
        userType:item.userType,
        userId:item.userId,
        identityType: this.identityType
      }
      this.connectItem = data
      console.log('connectCallBack=',item)
      this.isShowContacts  = true
    },
    filterShowCallBack(){
      this.filterShow = true
    },
    multipleClick(){
      this.isMultiple = !this.isMultiple
      this.$emit('isMultipleCallback',this.isMultiple)
    },
    onConfirm(item){
      console.log(item)
      this.showDistribution = false
    },
    onCancel(){
      this.isShowContacts = false;
      this.showDistribution = false;
      this.filterShow = false;
    },
    isMultipleCallback(isMultiple){
      console.log(isMultiple)
      this.isMultiple = isMultiple
    },
    checkAll(item){
      if(this.checked){
        this.$refs.checkboxGroup.toggleAll(true);
      }else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    onLoadCallBack() {
      this.init()
    },
    searchCallBack(val){
      this.searchValue = val||'';
      this.list = [];
      this.bdForm.pageNum = 1;
      this.init()
    },
  }
}
</script>

<style lang="scss" scoped>
.infoWrap{
  margin: 20px 30px 0 0;
  /* padding-bottom: 120px; */
  box-sizing: border-box;
  height: calc(100% - 180px) ;
  overflow: auto;
  .swipe{
    margin-bottom: 20px;
  }
  .item{
    box-sizing: border-box;
    margin-left: 30px;
    height: 194px;
    border-radius: 10px;
    background: #FFFFFF;
    position: relative;
    z-index: 1;
    color:#333333;
    padding-left: 30px;
    &.isMultiple{
      padding-left: 100px;
    }
    .checked{
      position: absolute;
      left: 30px;
      top: 60px;
    }
    .name{
      font-size: 36px;
      margin-bottom: 8px;
    }
    .style{
      font-size: 24px;
    }
  }
  .operate{
    display: flex;
    /* margin-left: -18px; */
    div{
      text-align: center;
      height: 152px;
      line-height: 152px;
      font-size: 28px;
      color: #FFFFFF;
    }
    .draw{
      width: 134px;
      background: #EB5D00;
      &.active{
        margin-left: -18px;
      }
    }
    .distribution{
      width: 116px;
      background: #4484FF;
      border-radius: 0px 20px 20px 0px;
    }
  }
}

.isMultipleOp{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  height: 120px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 30px;
  font-size: 28px;
  .num{
    display: inline-block;
    line-height: 1;
    margin-left: 20px;
    color:#999999
  }
  .draw,.distribution{
    font-size: 24px;
    width: 152px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    position:absolute;
    border-radius: 25px;
  }
  .draw{
    right: 190px;
    color:#4484FF;
    border: 1px solid #4484FF;
  }
  .distribution{
    background: #4484FF;
    right: 30px;
    color: #FFFFFF;
  }
}
</style>
