
export function returnExpireData(expire,index){
    let newData = getCurrentTime()
    if (index===2){
        return newData
    }else{
        return getBeforeTime(Number(expire))
    }
}
function getBeforeTime(num){
    var dt = new Date();
    dt.setMonth( dt.getMonth()+num );
    console.log( dt.toLocaleString('chinese',{hour12:false}) );
    let newData = dt.toLocaleString('chinese',{hour12:false})
    let date = new Date(newData);//当前时间
    let month = zeroFill(date.getMonth() + 1);//月
    let day = zeroFill(date.getDate());//日
    let curTime = '';
    curTime = date.getFullYear() + "-" + month + "-" + day;
    return curTime
}
// 获取当前时间
function getCurrentTime(type='') {
    let date = new Date();//当前时间
    let month = zeroFill(date.getMonth() + 1);//月
    let day = zeroFill(date.getDate());//日
    let hour = zeroFill(date.getHours());//时
    let minute = zeroFill(date.getMinutes());//分
    let second = zeroFill(date.getSeconds());//秒
    let curTime = '';
    if (type==='time'){
        curTime = date.getFullYear() + "-" + month + "-" + day
            + " " + hour + ":" + minute + ":" + second;
    }else {
        curTime = date.getFullYear() + "-" + month + "-" + day;
    }
    return curTime;
}

/**
 * 补零
 */
function zeroFill(i){
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
}
export function changeDataForm(arr,key) {
   let outPut = [];
   for (let obj of arr){
       if (obj[key]){
           outPut.push(obj[key])
       }
   }
    return outPut
}
// 将数组加一个flag为false然后返回
export function returnDictCodeArr(arr){
    if (arr.length!==0){
        arr.forEach(item=>{
        item.flag = false;
      });
      return arr
    }else {
        return arr
    }
}
// 传入单选数组和code 然后返回Name
export function returnDictName(arr,code){
    let name = ''
    if (arr.length!==0){
        arr.forEach(item=>{
            if (item.detailCode === code){
                name = item.detailName
            }
        });
        return name
    }else {
        return name
    }
}

// 将数组某个指定设置为true其他全部设置为false然后返回
export function returnDictCodeSelectArr(arr,x){
    let obj = {}
    if (arr.length!==0) {
        arr.forEach((item, index) => {
            if (x === index) {
                item.flag = true
                obj = item
            } else {
                item.flag = false
            }
        })
        return {arr:arr,obj:obj}
    }
}
// 塞进到数组的时候 将详情有的参数 和 全部数据数组判断 如果有选中就将它设置为true 最后返回的是最新的全部数组
export function changeDataCheckbox(allArr,arrString='') {
    if (arrString){
        arrString = arrString||[]
        arrString = arrString.length!==0?arrString.split(','):[]
        for (let i = 0;i<allArr.length;i++){
            for (let j = 0;j<arrString.length;j++){
               if (allArr[i].detailName === arrString[j]){
                   allArr[i].flag = true
               }
            }
        }
    }
    return allArr
}
// 获取到详情的时候 单选将默认参数 展示在表单展示show
export function changeDataSelectShow(arr=[],code){
    console.log(arr)
    console.log(code)
    if (arr.length!==0){
        arr.forEach(item=>{
            if (item.detailCode === code){
                item.flag = true
            }
        })
        return arr
    }else {
        return arr
    }
}
export function changeDataCheckboxSet(arr1=[],arr2=[],name1='') {
    if (arr1.length!==0){
        for (let obj of arr1){
            if (obj[name1]){
                obj.detailName = obj[name1]
            }
        }
        for (let i = 0;i<arr2.length;i++){
            for (let j = 0;j<arr1.length;j++){
                if (arr2[i].detailName === arr1[j].detailName){
                    arr2[i] = arr1[j]
                }
            }
        }
    }
    return arr2
}
// 将数组 和 全部数据比对 如果有就返回出来。
export function changeDataCheckboxInit(arr1=[],arr2=[],name1='') {
    let arr=[];
    if (arr1.length!==0){
        for (let obj of arr1){
            if (obj[name1]){
                obj.detailCode = obj[name1]
            }
        }
        console.log('arr1=',arr1)
        for (let i = 0;i<arr2.length;i++){
            for (let j = 0;j<arr1.length;j++){
                if (arr2[i].detailCode === arr1[j].detailCode){
                    arr.push(arr2[i])
                }
            }
        }
    }
    return arr
}
// 解决弹出键遮住输入框
export function inputScrollToCallBack(){
    console.log('inputScrollToCallBack1')
    let ua = navigator.userAgent;
    let isAndroid = /android/i.test(ua); //android终端
    if(isAndroid) {
        window.onresize = function () {
            undefined
            if (document.activeElement.tagName == "INPUT" || document.activeElement.tagName == "TEXTAREA") {undefined
                setTimeout(function () {
                    undefined
                    let top = document.activeElement.getBoundingClientRect().top;
                    window.scrollTo(0,top);
                }, 0);
            }
        }
    }
}
// 获取时间时和分
export function changeTimeSet() {
    let now = new Date();        var year = now.getFullYear();       //年
    let month = now.getMonth() + 1;     //月
    let day = now.getDate();            //日
    let hh = now.getHours();            //时
    let mm = now.getMinutes();          //分
    let ss = now.getSeconds();          //分
    let clock = year + "-";        if(month < 10)
        clock += "0";
    clock += month + "-";        if(day < 10)
        clock += "0";
    clock += day + " ";        if(hh < 10)
        clock += "0";
    clock += hh + ":";        if (mm < 10)
        clock += '0';
    clock += mm + ":";        if (ss < 10)
        clock += '0';
    clock += ss;
    clock = clock?clock.slice(11,16):''
    return clock
}
