/**
 * 联系人专用
 */
import BindContact from '@/views/contact/contactBind'
const contactRouter = [
    {
        path: '/bind_contact',
        name: 'BindContact',
        component: BindContact,
        meta: {
            keepAlive: false,
            title: "绑定联系人",
        }
    }
]

export default contactRouter
