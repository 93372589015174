<template>
  <div>
<!--单选下拉样式 format select-->
    <van-field
        v-if="format  === 'select'||format  === 'checkbox'||format  === 'area'||format === 'data'"
        readonly
        is-link
        :name="name"
        :label-width="labelWidth"
        v-model="val"
        v-bind="field"
        :ref="name"
        @click="selectClick(field)"
    >
      <template #label >
        <span class="form-item__subtitle">{{ field.label }}</span>
      </template>
    </van-field>
    <!--    单选框-->
    <van-field
               v-if="format  === 'radio'"
               :name="name"
               :label-width="labelWidth"
               v-bind="field"
               v-model="val"
               :ref="name"
    >
      <template #label>
        <div style="margin-bottom:0px">{{field.label}}<div class="input-hint" style="font-size: 12px">{{hint}}</div></div>
      </template>
      <template #input>

        <van-radio-group v-model="val"  @change="radioClick(field,val)" direction="horizontal" class="radio-all" >
           <van-radio v-for="item in field.dicType" :key="item.dictCode" :name="item.dictCode" checked-color="#1989fa" style="margin-right: 8px" >{{item.dictName||item.detailName}}</van-radio>
        </van-radio-group>

      </template>
    </van-field>
    <!--    多行输入框-->
    <van-field
        v-if="format  === 'textarea'"
        class="textarea-field"
        v-model="val"
        :label-width="labelWidth"
        :name="name"
        :label="field.label"
        :type="type"
        :maxlength="field.maxLength"
        rows="5"
        show-word-limit
        v-bind="field"
        :ref="name"
    >
    </van-field>
<!-- 输入样式 format input-->
    <van-field
        v-if="format  === 'input'"
        :label-width="labelWidth"
        v-model="val"
        :name="name"
        :type="type"
        :label="field.label"
        v-bind="field"
        :class="className"
        style="padding-right: 20px"
        :ref="name"
    >
      <template #button v-if="field.rightText||field.hint">
        <div @click="rightClick(field,val)"  :style="{color: field.rightTextColor}">{{ field.rightText }}</div>
        <div class="input-hint" style="font-size: 12px">{{hint}}</div>
      </template>
      <template #label>
        <div style="margin-bottom:0px">{{field.label}}<span class="is-required-span" v-if="!field.required">（非必填）</span><div class="input-hint" style="font-size: 12px" v-if="hint"><van-icon name="info-o" />{{hint}}</div></div>
      </template>
    </van-field>
    <!-- 自定义上传-->
    <van-field
        v-if="format  === 'upload'||format === 'video'"
        :label-width="labelWidth"
        :name="name"
               class="upload-field"
               :ref="name"
               v-bind="field"
        :value="value"
               :rules="[{ required: field.required, message: '请上传'+field.label,trigger:'onChange'  }]"
               :label="field.label">
      <template #input>
        <div class="look-example" v-show="example"  @click="showExampleClick(example)">查看示例</div>
        <div class="upload-field__body"  style="text-align:left">
          <slot v-if="format  === 'upload'">
            <ImageUploadCrop  :init-image-list="value" style="margin-bottom: 20px" :key="JSON.stringify(value)"
                              :folder="uploadPathName" :limit="field.limit" :aspectRatio="field.aspectRatio"
                             @handleGetImgList="(value)=>handleGetImgList(field,value)" />
          </slot>
          <slot v-if="format  === 'video'">
            <VideoUpload :folder="uploadPathName" :init-video-list="val" style="margin-bottom: 20px"
                             @handleGetVideoList="(val)=>handleGetVideoList(field,val)" />
          </slot>

          <div class="upload-hint" v-show="hint">
            {{hint}}
          </div>
        </div>
      </template>
    </van-field>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
import VideoUpload from '@/components/VideoUpload'

import ImageUploadCrop from '@/components/ImageUploadCrop'
export default {
  name: "index",
  components: {
    ImageUploadCrop,
    VideoUpload
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    uploadUrlKey: {
      type: String,
      default: () => "url",
    },
    isBorder: {
      type: Boolean,
      default: () => true,
    },
    format:{
      type:[String, Number, Object, Array],
      default: () => "input"
    },
    example: {type:[String, Number, Object, Array],
      default: () => ""},

    hint: {type:[String, Number, Object, Array],
      default: () => ""},
    value: {
      type: [String, Number, Object, Array],
      default: () => "",
    },
    labelWidth:{
      type: [String, Number, Object, Array],
      default: () => "px",
    },
    type: {
      type: [String, Number, Object, Array],
      default: () => "",
    },
    name: {
      type: [String, Number, Object, Array],
      default: () => "",
    },
    columns: {
      type: [String, Number, Object, Array],
      default: () => [],
    },
    dicType: {
      type: [String, Number, Object, Array],
      default: () => "",
    },
    field: {
      type: [String, Number, Object, Array],
      default: () => ({}),
    },
    /**
     * 上传目录
     */
    uploadPathName: {
      type: [String, Number, Object, Array],
      default: () => "formItem",
    },
  },
  data() {
    return {
      fileList:[],
      columnList: [],
      loading: false,
      showPicker: false,
      val: this.value,
      checkProvince: "",
      videoBase64: {},
    };
  },
  computed: {

    isTextarea() {
      return this.type === "textarea";
    },
    isSelect() {
      return this.type === "select";
    },
    isUpload() {
      return this.type === "upload";
    },
    className() {
      if (this.isTextarea) {
        return "textarea-field textarea-class";
      }
      return "";
    },
  },
  watch: {
    val(value) {
      this.$emit("change", value);
    },
    async value(value) {
      this.val = value;
    },
  },
  methods: {
    handleGetVideoList(item,val){
      let data = {
        name:item.name,
        val:val
      }
      console.log('videoCallBack=',data)
      this.$emit("videoCallBack",data);
    },
    handleGetImgList(item,val) {
      console.log('handleGetImgList=============1', item);
      console.log('handleGetImgList=============2', val);
      let data = {
        name:item.name,
        val:val
      }
      this.$emit("imgCallBack",data);
    },
    radioClick(item,val){
      let clickSelect = item.clickSelect ?item.clickSelect: false
      if (clickSelect){
        this.$emit("radioChange",val);
      }
    },
    rightClick(item,val){
      this.$emit("rightClick",val);
    },
    showExampleClick(img){
      ImagePreview([
        img
      ]);
    },
    selectClick(item){
      if (item.format === 'checkbox'){
        this.$emit("checkboxCallback", item);
      }else if(item.format === 'area') {
        this.$emit("areaCallback", item);
      }else if (item.format === 'data') {
        this.$emit("dataCallback", item);
      }else{
        this.$emit("selectCallback", item);
      }
    },
    /**
     * 通过this.$refs调用此方法，会自动滚动到该节点位置，并获取焦点。
     */
    focus() {
      //滚动到指定位置
      this.$refs[this.name].$el.scrollIntoView();
      this.$refs[this.name].focus();
    },
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/form.scss';
.radio-all{
  position: absolute;right: 10px;margin-top: 22px;color: #888888;
}


</style>
