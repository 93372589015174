import request from '@/utils/request'
import { NET_WORK_ENUM } from '@/api/index'

/**
 * 登录
 * @param data
 */
export function req_login(data = {}) {
  return request({
    url: '/login/v1',
    method: 'post',
    data,
    GATE: NET_WORK_ENUM.GATE,
  })
}

/**
 * 登出
 * @param data
 */
export function req_logout(data = {}) {
  return request({
    url: '/login/v1/login_out',
    method: 'post',
    data,
    GATE: NET_WORK_ENUM.GATE,
    mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 获取菜单
 * @param data
 */
export function req_get_menu(data = {}) {
  return request({
    url: '/sys/menu/v1/query_user_menu',
    method: 'post',
    data,
    GATE: NET_WORK_ENUM.GATE,
    mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 获取权限
 * @param data
 */
export function req_data_code(data = {}) {
  return request({
    url: '/crm/work/table/data/code',
    method: 'post',
    data,
    GATE: NET_WORK_ENUM.GATE,
    mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 邀请链接
 * @param data
 */
export function getInviteLink(data = {}) {
  return request({
    url: '/user/share/v1/link',
    method: 'post',
    data,
    GATE: NET_WORK_ENUM.GATE,
  })
}

