const shop = () => import('@/views/cooperating/shop/index') // 零售商
const brand = () => import('@/views/cooperating/brand/index') // 品牌
const redMan = () => import('@/views/cooperating/redMan/index') // 红人
const org = () => import('@/views/cooperating/org/index') // 机构
const factory = () => import('@/views/cooperating/factory/index') // 工厂
const serviceProvider = () => import('@/views/cooperating/serviceProvider/index') // 合作服务商

/**
 * 已合作和未合作
 * @type {[{path: string, component: (function(): Promise<*>), meta: {keepAlive: boolean, title: string}, name: string}]}
 */
const haveAndNotCooperatingRouter = [
    {
        path: '/cooperating_shop',
        name: 'cooperating_shop',
        component: shop,
        meta: {
            keepAlive: true,
            title: "零售商客户",
        },
    },
    {
        path: '/cooperating_brand',
        name: 'cooperating_brand',
        component: brand,
        meta: {
            keepAlive: true,
            title: "品牌客户",
        },
    },
    {
        path: '/cooperating_red_man',
        name: 'cooperating_red_man',
        component: redMan,
        meta: {
            keepAlive: true,
            title: "红人客户",
        },
    },
    {
        path: '/cooperating_org',
        name: 'cooperating_org',
        component: org,
        meta: {
            keepAlive: true,
            title: "机构客户",
        },
    },{
        path: '/cooperating_factory',
        name: 'cooperating_factory',
        component: factory,
        meta: {
            keepAlive: true,
            title: "工厂客户",
        },
    },{
        path: '/cooperating_service_provider',
        name: 'cooperating_service_provider',
        component: serviceProvider,
        meta: {
            keepAlive: true,
            title: "合作服务商客户",
        },
    }
]
export default haveAndNotCooperatingRouter
