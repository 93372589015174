/**
 * 分享
 */
const card = () => import('@/views/share/card')

const shareRouter = [
	{
		path: '/share_card',
		name: 'ShareCard',
		component: card,
		meta: {
			keepAlive: true,
			title: "客户详情",
		}
	}
]
export default shareRouter
