<template>
  <div>
    <van-swipe-cell
        class="swipe"
        @open="openClick(item)"
        @close="closeClick"
    >
      <div class="item" :class="{'isMultiple':isMultiple}" @click="detailClick(item)">
        <div class="contact-all" style="display: flex;flex-direction: row">
          <div class="contact-img" >
            <div >{{item.name?item.name.substring(0,1):''}}</div>
            <div style="line-height: 0px" v-if="item.isMarker">
              <span class="contact-main-person">决策人</span>
            </div>
          </div>
          <div>
            <div class="contact-name" >{{ item.name }} <span class="contact-job">{{ item.position }}</span></div>
            <div class="contact-item">
              <img class="contact-img-phone" src="../../../assets/image/contact/phone.png" />
              <span class="contact-job">手机号：{{ item.phone||'--' }}</span>
              <span class="contact-login" v-if="item.isRegister">已注册</span>
            </div>
            <div class="contact-item" v-if="item.shopName">
              <img class="contact-img-shop" src="../../../assets/image/contact/shop.png" />
              <span class="contact-job">店铺名称：{{ item.shopName||'--' }}</span>
            </div>
            <div class="contact-item" v-if="item.brandName">
              <img class="contact-img-brand" src="../../../assets/image/contact/brand.png" />
              <span class="contact-job">品牌名称：{{ item.brandName||'--' }}</span>
            </div>
            <div class="contact-item" v-if="item.redName">
              <img class="contact-img-person" src="../../../assets/image/contact/person.png" />
              <span class="contact-job">红人名称：{{ item.redName||'--' }}</span>
            </div>
            <div class="contact-item" v-if="item.companyName">
              <img class="contact-img-company" src="../../../assets/image/contact/company.png" />
              <span class="contact-job">公司名称：{{item.companyName}}</span>
            </div>
          </div>
        </div>
        <div>

        </div>

      </div>
      <template #right>
        <div class="operate"  v-if="item.userId">
          <div class="draw operate-w"  @click="followClick(item)" style="border-radius:0px 10px 10px 0px">
            <div class="operate-item" >
                        <span >
                        填写<br/>跟进
                        </span>
            </div>
          </div>
        </div>
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
export default {
  name: "ContactItem",
  props: {
    item: {},
    isMultiple: {},
    identityType:{}
  },
  data(){
    return{
      openList:[]
    }
  },
  methods:{
    returnName(item){
      let name = '--'
      if (item.name&&item.brandNameEn){
        if (item.name==item.brandNameEn){
          name = item.name
        }else {
          name = item.name +'/'+item.brandNameEn
        }
      }else {
        name = item.name||item.brandNameEn
      }
      name = name||'--'
      return name
    },
    recycleClick(item){
      console.log(item)
      this.$emit('recycleCallBack',item)
    },
    detailClick(item){
      this.$emit('detailsItemCallBack',item)
    },
    followClick(item){
      console.log(item)
      this.$emit('followCallBack',item)
    },
    connectClick(item){
      console.log(item)
      this.$emit('connectCallBack',item)
    },
    openClick(item){
      this.openList.push(item)
    },
    closeClick(item){
      let index =  this.openList.indexOf(item)
      this.openList.splice(index,1)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/haveItem';
.contact-all{
  background: #FFFFFF;
  width: 630px;
  background: #FFFFFF;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
.contact-img{
  width: 100px;
  height: 100px;
  line-height: 100px;
  color: #FFFFFF;
  border-radius: 50%;
  margin-right: 20px;
  font-size: 52px;
  text-align: center;
  background: #C8E2FF;
}
  .contact-name{
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    //display: flex;
    //align-items: center;
    max-width: 540px;
  }
  .contact-job{
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    margin-left: 12px;
  }
  .contact-img-phone{
    width: 22px;
    height: 28px;
    margin-top: 4px;
  }
  .contact-img-shop{
    width: 26px;
    height: 23px;
    margin-top: 4px;
  }
  .contact-img-brand{
    width: 24px;
    height: 24px;
    margin-top: 4px;
  }
  .contact-img-person{
    width: 28px;
    height: 30px;
    margin-top: 4px;
  }
  .contact-img-company{
    width: 26px;
    height: 26px;
    margin-top: 4px;
  }
  .contact-item{
    margin-top: 14px;
    display: flex;
    //align-items: center;
  }
  .contact-login{
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #127EFE;
    background: rgba(0,126,255,0.09);
    margin-left: 4px;
    padding: 4px;
  }
  .contact-main-person{
    background: #FA6400;
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 4px;
    padding-right: 0px;
    //position: absolute;
    bottom: -10px;

  }
}
</style>
