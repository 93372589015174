//公海
const brand = () => import('@/views/customerSea/brand')//品牌
const favourite = () => import('@/views/customerSea/favourite')//红人
const service = () => import('@/views/customerSea/service')//合作服务商
const factory = () => import('@/views/customerSea/factory')//工厂
const mcn = () => import('@/views/customerSea/mcn')//MCN
const shop = () => import('@/views/customerSea/shop')//零售商会员
const all = () => import('@/views/customerSea/all')//all-大公海

const customerSeaRouter = [
    {
        path: '/customerSea_brand',
        name: 'CustomerSeaBrand',
        component: brand,
        meta: {
            keepAlive: true,
            title: "品牌公海",
        },
    },
    {
        path: '/customerSea_favourite',
        name: 'CustomerSeaFavourite',
        component: favourite,
        meta: {
            keepAlive: true,
            title: "红人公海",
        },
    },
    {
        path: '/customerSea_service',
        name: 'CustomerSeaService',
        component: service,
        meta: {
            keepAlive: true,
            title: "合作服务商公海",
        },
    },
    {
        path: '/customerSea_factory',
        name: 'CustomerSeaFactory',
        component: factory,
        meta: {
            keepAlive: true,
            title: "工厂公海",
        },
    },
    {
        path: '/customerSea_mcn',
        name: 'CustomerSeaMcn',
        component: mcn,
        meta: {
            keepAlive: true,
            title: "MCN机构公海",
        },
    },
    {
        path: '/customerSea_shop',
        name: 'CustomerSeaShop',
        component: shop,
        meta: {
            keepAlive: true,
            title: "零售商公海",
        },
    },
    {
        path: '/customerSea_all',
        name: 'CustomerSeaAll',
        component: all,
        meta: {
            keepAlive: true,
            title: "公海",
        },
    },
]
export default customerSeaRouter