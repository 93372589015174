<template>
  <!--  省市区 -->
  <van-popup  v-model="areaShow"  position="bottom" @click-overlay="onCancel">
    <van-picker
        ref="areas"
        :title="title"
        show-toolbar
        value-key="name"
        :columns="areaList"
        @cancel="onCancel"
        @confirm="onConfirm"
        @change="onChange"
        confirm-button-text	="完成"
        cancel-button-text	="取消"
    />
  </van-popup>
</template>

<script>
import {queryAddress} from '@/api/common'
export default {
name: "index",
  props:{
    title:{
      type:String,
      default:() =>""
    },
    areaShow: {
      type: Boolean,
      default: false,
    },
    province:{
      type:String,
      default:() =>""
    },
    city:{
      type:String,
      default:() =>""
    },
    district:{
      type:String,
      default:() =>""
    }
  },
  data() {
    return { areaList:[{                       //一级   省
        flex: 1,
        values: ["请选择"],
        textAlign: 'center',
        defaultIndex: 0,
      }, {                    //二级   市
        flex: 1,
        values: ["请选择"],
        textAlign: 'center',
        defaultIndex: 0,
      },{                     //三级   区
        flex: 1,
        values: ["请选择"],
        textAlign: 'center',
        defaultIndex: 0,
      }] };
  },
  mounted() {
    queryAddress({jsonData: {provinceId:''}}).then(res=>{
      this.areaList[0].values=res||[];
      if (res.length!==0){
        res.forEach((item,index)=>{
          if (item.provinceId === this.province){
            this.areaList[0].defaultIndex = index
          }
        })
      }
      queryAddress({jsonData: {provinceId:this.province||res[0].provinceId}}).then(res=>{
        this.areaList[1].values=res||[];
        if (res.length!==0){
          res.forEach((item,index)=>{
            if (item.provinceId === this.city){
              this.areaList[1].defaultIndex = index
            }
          })
        }
        queryAddress({jsonData: {provinceId:this.city||res[0].provinceId}}).then(res=>{
          this.areaList[2].values=res||[];
          if (res.length!==0){
            res.forEach((item,index)=>{
              if (item.provinceId === this.district){
                this.areaList[2].defaultIndex = index
              }
            })
          }
        })
      })
    })
  },
  methods:{
    onConfirm(arr){
      let mergerName = arr[1].mergerName;
      let name = arr[0].name;
      if (mergerName.indexOf(name) > -1|| mergerName.search(name)> -1){
        this.$emit("areaConfirm",arr)
      }

    },
    onChange(picker,values,index){
      let that = this;
      if (index===0){
        that.area(values[0]["provinceId"]).then(function(data){ //通过一级 省的id 获取市的列表
          that.areaList[1].values = data;
          that.area(data[0]["provinceId"]).then(function(data){//通过 二级 市的id  获取区域的列表
            that.areaList[2].values = data;
          });
        });
      }else if (index===1){
        that.area(values[1]["provinceId"]).then(function(data){//通过 二级 市的id  获取区域的列表
          that.areaList[2].values = data;
        });
      }


    },
    //调取地区列表的函数
    area(id){
      let promise= new Promise(function(resolve){
        queryAddress({jsonData: {provinceId:id}}).then(res=>{
          resolve(res);
        })
      })
      return promise;
    },
    onCancel(){
      this.$emit('onCancel',false)
    },
  }
}
</script>

<style lang="scss" scoped >

</style>
