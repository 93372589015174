<template>
  <div class="container-scroll">
    <van-form style="margin-top: 10px" class="all-form-item" @submit="onSubmit" @failed="handleFailed"
              :show-error-message="false">
      <FormItem v-for="item in formList0"
                :key="item.key"
                :name="item.key"
                v-model="form[item.key]"
                v-bind="item"
                :ref="item.key"
                @areaCallback="areaCallback"
                @selectCallback="selectCallback"
                @radioChange="radioCallBack"
                :class="item.class"/>
      <div style="position: relative;width: 100%;background: #FFFFFF;height: 40px" v-if="form.identityName">
        <div style="display: flex;flex-direction: row">
          <van-field v-model="search" label="名称"  @input="onSearch" placeholder="请输入"
                     maxlength="20" inputAlign="right"
                     style="position: absolute;" type="text"  class="input-lsy"/>
          <span @click="rightCallBack" class="right-all">查重</span>
        </div>

        <ul class="select-lsy-all" v-if="selectInputShow">
          <!-- 注意！注意！注意！这里循环遍历的是items，不再是data里的list数组 -->
          <li v-for="(item) in list" :key="item" @click="selectInputClick(item)">
            <span class="select-lsy" >{{item.name}}</span>
          </li>
          <span v-if="list.length==0" class="select-lsy" style="text-align: center">无匹配数据</span>
        </ul>
      </div>
      <div class="info-all">
        <div v-if="form.identity=='USER_LABEL_IDENT_RETAILER'">地域：{{info.provinceName?info.provinceName+info.cityName+info.districtName:'--'}}</div>
        <div v-if="form.identity=='USER_LABEL_IDENT_RED'">粉丝数：{{info.followers||0}}</div>
        <div v-if="form.identity=='USER_LABEL_IDENT_RED_ORG'">签约红人数：  {{info.netRedNum||'0'}} </div>
        <div v-if="form.identity=='USER_LABEL_IDENT_SERVICE_PROVIDER'">服务商类型：{{info.serviceTypeName||'--'}}</div>
        <div v-if="form.identity=='USER_LABEL_IDENT_FACTORY'">工厂类型：{{info.factoryTypeName||'--'}}</div>
      </div>
      <FormItem v-for="item in formList1"
                :key="item.key"
                :name="item.key"
                v-model="form[item.key]"
                v-bind="item"
                :ref="item.key"
                @areaCallback="areaCallback"
                @selectCallback="selectCallback"
                @radioChange="radioCallBack"
                :class="item.class"/>
      <div style="height: 80px;background:transparent;width:100%"></div>

      <div class="fix_bottom-all">
        <button class="fix_bottom" native-type="submit">提交</button>
      </div>
    </van-form>
    <SelectItem v-if="show" :name="name" :title="title" :arr="selectArr" @onCancel="onCancel" @onConfirm="onConfirm"/>
    <AreaItem v-if="areaShow" :name="name" :areaShow="areaShow" :title="title" :province="form.province" :city="form.city" :district="form.district"  @areaConfirm="areaConfirm"
              @onCancel="onCancel"/>
  </div>
</template>

<script>
import {PATTERN} from "@/utils/check/const";
import {dicType} from '@/api/common';
import {isEquipment, judgeEv} from "@/commonjs/navigator"
import {contact_bind,contact_check,contact_name} from '@/api/contact'
import {people_base_info} from "@/api/details";

export default {
  name: "bind",
  components: {
  },
  computed: {
  },
  data() {
    return {
      selectInputShow:false,
      search:'',
      list:[],
      source: 'h5',
      areaShow: false,
      show: false,
      title: '',
      name: '',
      selectArr: [],
      flag:true,
      formList0: [
        {
          key: "identityName",
          format: "select",
          type: "select",
          field: {
            name: "identityName",
            format: "select",
            label: "选择身份",
            placeholder: "请选择身份",
            inputAlign: "right",
            required: true,
            rules: [{required: true, message: "请选择身份", trigger: 'onChange'}],
          }
        }
      ],
      formList1: [
        {
          key: "area",
          format: "area",
          type: "area",
          field: {
            name: "area",
            format: "area",
            label: "所在区域",
            placeholder: "请选择所在区域",
            required: false,
            inputAlign: "right",
            rules: [{required: true, message: "请选择所在区域", trigger: 'onChange'}],
          },
        },
        {
          key: "name",
          format: "input",
          field: {
            label: "姓名",
            placeholder: "请输入姓名",
            required: true,
            inputAlign: "right",
            maxLength: 20,
            rules: [{required: true, message: "请输入姓名", trigger: 'onChange'}],
          },
        },
        {
          key: "phone",
          format: "input",
          type: "tel",
          field: {
            label: "手机号",
            placeholder: "常用手机号",
            required: true,
            inputAlign: "right",
            maxLength: 11,
            // rightText:'查重',
            rightTextColor:'#3D7FFF',
            rules: [
              {required: true, message: "请输入手机号", trigger: 'onChange'},
              {
                pattern: PATTERN.phone,
                message: "手机号格式不正确"
                , trigger: 'onChange'
              },
            ],
          },
        },
        {
          key: "wechat",
          format: "input",
          field: {
            label: "微信号",
            placeholder: "请输入微信",
            required: true,
            inputAlign: "right",
            rules: [{required: true, message: "请输入微信", trigger: 'onChange'}],
          },
          class: 'height-bottom-20'
        },
        {
          key: "position",
          format: "input",
          field: {
            label: "职位",
            placeholder: "请输入职位",
            required: false,
            inputAlign: "right",
            rules: [{required: false, message: "请输入职位", trigger: 'onChange'}],
          },
        },
        {
          key: "email",
          format: "input",
          field: {
            label: "邮箱",
            placeholder: "请输入邮箱地址",
            inputAlign: "right",
          },

        },
        {
          key: "isMarker",
          format: "radio",
          field: {
            dicType: [
              {dictCode: '1', dictName: '是', text: '是'},
              {dictCode: '0', dictName: '否', text: '否'},
            ],
            label: "关键决策人",
            placeholder: "",
            inputAlign: "right",
          },
          class: 'height-bottom-20'
        },
        {
          key: "remark",
          format: "textarea",
          type: "textarea",
          labelWidth: '400px',
          field: {
            label: "备注",
            placeholder: "请填写备注",
            inputAlign: "left",
            maxLength: 200,
          }
        }
      ],
      info:{
        serviceTypeName:'',
        factoryTypeName:'',
        netRedNum:0,
        followers:0,
        cityName:'',
        districtName:''
      },
      form: {
        email: '', // 邮箱
        id: '', // 主键ID
        phone: '',// 手机号
        position: '',// 职位
        area: '',//省市区组合地址
        name: '', // 用户名称
        wechat: '', // 微信
        isMarker:0,
      },
      positionArr: [],
      authUrl: '',
      isApp: isEquipment().isApp
    }
  },
  mounted() {
    this.form.id = this.$route.query.id;
    this.form.sourceId = this.$route.query.sourceId;
    let data = [
      {code: 'BUY_EQUITY_IDENTITY', dicStatus: 1},
      // TODO 用户身份
    ]
    this.initDictCode(data)//职位
    this.init()
  },
  methods: {
    init(){
      people_base_info({id:this.form.id}).then(res=>{
        console.log('详情=',res)
        this.form = {
          email:res.email,
          id:res.id,
          isMarker:res.isMarker,
          name:res.name,
          phone:res.phone,
          position:res.position,
          wechat:res.wechat
        }
      })
    },
    selectInputClick(item){
      this.info = item
      this.form.sourceId = item.strategyId
      this.search = item.name
      this.selectInputShow = false
    },
    onSearch(val){
      if (!this.form.identity){
        this.search = ''
        this.$toast('请先选择身份！')
        return true;
      }
      this.selectInputShow = true
      if (val){
        this.list = []
        let data = {
          identity:this.form.identity,
          name:this.search,
          isAssign:2,
          pageNum:1,
          pageSize:20
        }
        contact_name(data).then(res=>{
           console.log(res)
          this.list = res.records ||[]
        })
      }else {
        this.info = {}
      }
    },
    onBlurClick(val){
      this.selectInputShow = false
      console.log('onBlurClick=========',val)
    },
    areaConfirm(item) {
      this.form.area = item[0].name + item[1].name + item[2].name
      this.form.netRedAddress = {
        province: item[0].provinceId,
        provinceName: item[0].name,
        city: item[1].provinceId,
        cityName: item[1].name,
        country: item[2].provinceId,
        countryName: item[2].name
      }
      this.getAreaCallBack();
      this.onCancel()
    },
    getAreaCallBack(){
      if (this.form.area){
        this.form.province = this.form.netRedAddress.province;
        this.form.city = this.form.netRedAddress.city;
        this.form.district = this.form.netRedAddress.country;
      }else {
        this.form.province = '';
        this.form.city = '';
        this.form.district = '';
      }
    },
    backChange() {
      this.$router.go(-1)
    },
    // 初始化数据字典
    initDictCode(data) {
      dicType({jsonData: data}).then(res => {
        let identityArr = res.BUY_EQUITY_IDENTITY||[];
        if (identityArr.length !== 0) {
          this.identityArr = identityArr;
        }
      })
    },
    onSubmit(values) {
      console.log('submit', values);
      console.log(this.form)
      this.saveCallBack();
    },
    // TODO 提交方法
    saveCallBack() {
      console.log('提交方法=', this.form)
      if (!this.form.sourceId){
        this.$toast('请输入名称！')
        return true;
      }
      if (this.flag){
        this.$toast('请点击查重！')
        return true;
      }
      if (this.form.email) {
        this.form.email = this.form.email ? this.form.email.trim() : ''
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!re.test(this.form.email)) {
          this.$toast('请输入正确的邮箱格式！')
          return true;
        }
      }
      contact_bind(this.form).then(res=>{
        this.$toast('操作成功！')
        this.backChange()
      })

    },
    handleFailed(item) {
      console.log('handleFailed', item);
      console.log(this.form)
      this.$toast(item.errors[0].message)
      this.$refs[item.errors[0].name][0].focus();
    },
    areaCallback(item) {
      this.areaShow = true;
      this.selectInputShow = false
    },
    radioCallBack(item){
      console.log('点击单选',item)
      this.selectInputShow = false
    },
    rightCallBack(){
      console.log('点击查重',this.form)
      this.selectInputShow = false
      let data = {
        id:this.form.id,
        sourceId:this.form.sourceId,
        identity:this.form.identity
      }
      contact_check(data).then(res=>{
        console.log(res)
        if (!res){
          this.flag = false
          this.$toast('查重成功！')
        }else {
          this.flag = true
          this.search = ''
          this.form.sourceId = ''
          this.info = {}
          this.$toast('该名称重复！')
        }
      })
    },
    selectCallback(item) {
      console.log(item)
      this.title = item.label;
      this.name = item.name;
      this.selectArr = [];
      this.show = true;
      console.log('selectCallback=', this.identityArr)
      this.selectArr = this.identityArr
      this.selectInputShow = false
    },
    onCancel() {
      this.show = false;
      this.areaShow = false;
    },
    onConfirm(values) {
      console.log(values);
      this.form.identity = values.detailCode;
      this.form.identityName = values.detailName;
      this.onCancel()
    },
    headClick() {
      const {head} = this.$refs
      if (head.isApp) {
        head.uploadImageOss()
      } else {
        head.$refs.fileInput.click()
      }
    },
    handleGetImgList(arr) {
      this.form.headImage = arr[1]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/form.scss';

.companyName-all {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #FFFFFF;
  padding-left: 40px;
  padding-right: 30px;
  line-height: 98px;
  font-size: 28px;
  color: #333333;

  &_right * {
    vertical-align: middle;
    display: inline-block;
  }

  .companyName-certified {
    font-size: 24px;
    line-height: normal;
    padding: 4px 6px;
    background-color: #05bd5c;
    border-radius: 2PX;
    color: white;
    margin-right: 10px;
  }

  .companyName-text {
    margin-right: 10px;
  }

  .companyName-auth {
    color: #888888;
  }

  .companyName-auth-no {
    color: #EF5C5E;;
  }
}

::v-deep .head-img {
  .yf-uploader__delete, .yf-uploader__input-wrapper {
    display: none;
  }

  img {
    width: 70px;
    height: 70px;
    margin: 10px 20px;
    border-radius: 100%;
    pointer-events: none;
  }
}
.input-lsy ::v-deep{
  color: #333333 !important;
  border-radius: 0.05333rem;
  font-size: 0.37333rem;
  border: 0px !important;
  line-height: 70px;
  text-align: right;
  .van-field__label{
    padding-left: 34px !important;
  }
}
.select-lsy-all{
  position: absolute;
  top: 70px;
  right: 30px;
  background: #FFFFFF;
  z-index: 9999;
  min-width: 600px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: 5px 0;
  padding: 10px 0px 10px 0px;
}
.select-lsy{
  font-size: 28px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 36px;
  box-sizing: border-box;
  cursor: pointer;
}
.info-all{
  line-height: 96px;
  background: #FFFFFF;
  margin-left: 36px;
  font-size: 28px;
  color: #333333;
}
.right-all{
  font-size: 14px;color: #4484FF;padding-left: 20px;position: absolute;right: 40px;line-height: 96px;z-index: 2000;height: 96px;
}
</style>
