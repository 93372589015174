import router from '@/router'
import { req_login, req_data_code } from '@/api/user'
import { StorageManager } from '@/utils/util'
import { loading } from '@/components/LoadingBlock/loading'

const storageToken = new StorageManager('TOKEN', '')
const storageUserInfo = new StorageManager('USERINFO', {})
const storageButtonCode = new StorageManager('BUTTONCODE', [])
const storageMenu= new StorageManager('MENU', [])
export default {
  state: {
    token: storageToken.item,
    userInfo: storageUserInfo.item,
    buttonCode: storageButtonCode.item,
    menu: storageMenu.item,
  },
  getters: {
    getUserInfo (state) {
      return state.userInfo
    },
    getToken (state) {
      return state.token
    },
    getAuthButton (state) {
      return state.buttonCode
    },
    getAuthMenu (state) {
      return state.menu
    },
  },
  mutations: {
    setUserInfo (state, userInfo) {
      storageToken.item = state.token = userInfo.token
      delete userInfo.token
      storageUserInfo.item = state.userInfo = userInfo
    },
    setAuthData (state, authData) {
      storageButtonCode.item = state.buttonCode = authData
      storageMenu.item = state.menu = []
    },
    removeUser (state) {
      state.token = storageToken.remove()
      state.userInfo = storageUserInfo.remove()
      state.buttonCode = storageButtonCode.remove()
      state.menu = storageMenu.remove()
    },
  },
  actions: {
    async login({ state, commit, getters }, reqParams) {
      loading.show()
      const res = await req_login(reqParams).catch(e => {})
      // console.log('res', res)
      if (res) {
        commit('setUserInfo', res)
        const menu = await req_data_code()
        if (menu) commit('setAuthData', menu)
        if(sessionStorage.getItem('backRouter')) {
          const backRouter = sessionStorage.getItem('backRouter')
          sessionStorage.removeItem('backRouter')
          router.replace(backRouter)
        } else {
          router.replace('/')
        }
        loading.hide()
        return Promise.resolve(res)
      } else {
        return Promise.reject()
      }
    },
    async getCode({ commit }) {
      const menu = await req_data_code()
      if (menu) commit('setAuthData', menu)
    },
    logout({ commit }) {
      commit('removeUser')
      // sessionStorage.removeItem('backRouter')
      return router.replace({
        path: '/login',
        // query: { redirect: router.currentRoute.fullPath }
      })
    },
  }
}
