//嘉宾
const list = () => import('@/views/guest/list') //嘉宾管理
const signUp = () => import('@/views/guest/signUp') //嘉宾报名
const confirm = () => import('@/views/guest/confirm') //嘉宾报名
const protocol = () => import('@/views/guest/protocol') //协议
const success = () => import('@/views/guest/success') //成功
const timeout = () => import('@/views/guest/timeout') //过期

const guestRouter = [
  {
    path: '/guest_list',
    name: 'GuestList',
    component: list,
    meta: {
      keepAlive: false,
      title: '嘉宾管理'
    }
  },
  {
    path: '/guest_sign_up',
    name: 'GuestsSignUp',
    component: signUp,
    meta: {
      keepAlive: false,
      title: '嘉宾报名'
    }
  },
  {
    path: '/guest_confirm',
    name: 'GuestsConfirm',
    component: confirm,
    meta: {
      keepAlive: true,
      title: '确认邀请函'
    }
  },
  {
    path: '/guest_protocol',
    name: 'GuestsProtocol',
    component: protocol,
    meta: {
      keepAlive: false,
      title: '协议'
    }
  },
  {
    path: '/guest_success',
    name: 'GuestsSuccess',
    component: success,
    meta: {
      keepAlive: false,
      title: '确认邀请函'
    }
  },
  {
    path: '/guest_timeout',
    name: 'GuestsTimeout',
    component: timeout,
    meta: {
      keepAlive: false,
      title: '嘉宾邀请函'
    }
  }
]
export default guestRouter
