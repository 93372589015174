<template>
<div class="title-all">
  <div class="title-left"></div><div class="title-center">{{title}}</div><div class="title-right"></div>
</div>
</template>

<script>
export default {
  name: "index",
  props: {
    title: {
      type: String,
      default: () => "",
    },
  },
}
</script>


<style lang="scss" scoped>
.title-all{
  display: flex;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 28px;
  .title-left{
    margin-left: 32px;
    width: 6px;
    height: 28px;
    background: #EF5D5E;
    opacity: 1;
    border-radius: 6px;
  }
  .title-center{

    margin-left: 12px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    opacity: 1;
    margin-right: 6px;
  }
  .title-right{
    width: 548px;
    height: 1px;
    background: #E5E5E5;
    margin: auto 0;
  }
}

</style>
