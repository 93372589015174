/*
* 详情接口
* */
import request from '@/utils/request'
import { NET_WORK_ENUM } from '@/api/index'

/**
 * 获取用户信息
 * @param data
 */
export function getUserInfo(data = {}) {
    return request({
        url: '/crm/user/v1/info',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 获取工厂详情
 * @param data
 */
export function getFactoryDetail(data = {}) {
    return request({
        url: '/fs/factory/v1/detail',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 获取工厂类型子项
 * @param data
 */
export function getFactoryCategoryList(data = {}) {
    return request({
        url: '/sys/category/v1/get_list',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
// 获取红人详情
export function getFavouriteDetail(data = {}) {
    return request({
        url: '/red/v1/detail',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 获取MCN机构详情
export function getMCNDetail(data = {}) {
    return request({
        url: '/red/v1/org/detail',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}


/**
 * 获取店铺详情
 * @param data
 */
export function getShopDetail(data = {}) {
    return request({
        url: '/crm/retailers/v1/detail',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 联系人
 * @param data
 */
export function getContactList(data = {}) {
    return request({
        url: '/crm/v1/contact',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 品牌详情
 * @param data
 */
 export function getBrandDetail(data = {}) {
  return request({
      url: '/crm/brand/v1/detail',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}
/**
 * 服务商详情
 * @param data
 */
 export function getServerDetail(data = {}) {
  return request({
      url: '/ps_service_provider/v1/get_detail_by_userid',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 公海详情
 * @param data
 */
 export function getSeaDetail(data = {}) {
  return request({
      url: '/crm/high/seas/v1/detail',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 获取公司详情
 * @param data
 */
 export function getCompanyDetail(data = {}) {
  return request({
      url: '/company/v1/detail',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 跟进记录列表
 * @param data
 */
 export function getVisitList(data = {}) {
  return request({
      url: '/crm/v1/sea/visit/record/query_page_list',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 跟进记录删除
 * @param data
 */
 export function delete_records(data = {}) {
  return request({
      url: '/crm/v1/sea/visit/record/delete_records',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 所属人列表
 * @param data
 */
 export function getBelongList(data = {}) {
  return request({
      url: '/crm/user/v1/belong',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 零售商退回公海
 * @param data
 */
 export function releaseSea(url, data) {
  return request({
      url: url,
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 退回公海统一接口
 * @param data
 */
 export function releaseSeaNew(data = {}) {
  return request({
      url: '/crm/v1/customer/no_cooperation/release',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 名片联系人
 * @param data
 */
 export function getContactsInfo(data = {}) {
  return request({
      url: '/user/card/v1/get',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 【客保/BA】 获取联系人
 * @param data
 */
 export function get_by_identity(data = {}) {
  return request({
      url: '/user/card/v1/get_by_identity',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 录入客户删除
 * @param data
 */
 export function manualDataDel(data = {}) {
  return request({
      url: '/crm/v1/tools/customer/date/enter/del',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}
/**
 * 录入客户删除
 * @param data
 */
 export function manualDataDelNew(data = {}) {
  return request({
      url: '/crm/customer/v1/delete',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 【客保/BA】详情
 * @param data
 */
 export function detail_by_identity(data = {}) {
  return request({
      url: '/user/details/v1/detail_by_identity',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 【客保/BA】专业观众活动
 * @param data
 */
 export function percentage(data = {}) {
  return request({
      url: '/professional/v1/sign/percentage',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 获取所有标签
 * @param data
 */
 export function getLabelList(data = {}) {
  return request({
      url: '/sys/label/v1/get_list',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 获取用户标签
 * @param data
 */
 export function user_label_list(data = {}) {
  return request({
      url: '/user/v1/label/user_label_list',
      method: 'post',
      data,
      GATE: NET_WORK_ENUM.GATE,
      mock: NET_WORK_ENUM.MOCK
  })
}

/**
 * 用户编辑标签
 * @param data
 * @returns {*}
 */
export function modify_tag_user(data = {}) {
  return request({
    url: '/user/v1/label/modify_tag_user',
    method: 'post',
    data,
    GATE: '',
    mock: process.env.VUE_APP_BASE_API
  })
}

/**
 * 获取头部用户信息
 * @param data
 */
export function identity_details(data = {}) {
    return request({
        url: '/crm/v1/identity_details',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// /**
//  * 获取用户联系人
//  * @param data
//  */
// export function getContactListNew(data = {}) {
//     return request({
//         url: '/crm/open/sea/bind_main/v1/list',
//         method: 'post',
//         data,
//         GATE: NET_WORK_ENUM.GATE,
//         mock: NET_WORK_ENUM.MOCK
//     })
// }
/**
 * 获取用户联系人
 * @param data
 */
export function getContactListNew(data = {}) {
    return request({
        url: '/user/docking/people/v1/list',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 客户动态
 * @param data
 */
export function log_page(data = {}) {
    return request({
        url: '/operation/log/v1/select_page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 绑定联系人
 * @param data
 */
export function bind_contact_user(data = {}) {
    return request({
        url: '/crm/docking/people/v1/bind_contact_user',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 联系人手机号查重
 * @param data
 */
export function cnki_phone(data = {}) {
    return request({
        url: '/crm/docking/people/v1/cnki_phone',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 联系人详情
 * @param data
 */
export function people_base_info(data = {}) {
    return request({
        url: '/crm/docking/people/v1/base_info',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 编辑联系人详情
 * @param data
 */
export function people_edit(data = {}) {
    return request({
        url: '/crm/docking/people/v1/edit_info',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 用户权益订单支付明细记录
 * @param data
 */
export function identity_page(data = {}) {
    return request({
        url: '/oc/v1/order/pay/item/identity_page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}