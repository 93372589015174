import { getSignature } from '@/api/file'
import moment from 'moment'
import OSS from 'ali-oss'
import store from '@/store'
import {decrypt} from "@/utils/secret";

export default class Signature {
  constructor() {
    console.log('constructor,实例化触发构造函数')
    const client = this.init()
    this.client = client
  }
  static getInstance() { // 单例
    if (!Signature.instance) {
      Signature.instance = new Signature()
    }
    return Signature.instance
  }
  async init() {
    const res = await getSignature({ timestamp: moment().valueOf() + Math.random() })
    const client = new OSS({
      region: 'oss-cn-shanghai',
      accessKeyId: decrypt(res.AccessKeyId),
      accessKeySecret: decrypt(res.AccessKeySecret),
      stsToken: decrypt(res.SecurityToken),
      bucket: res.bucketName,
      refreshSTSToken: async() => {
        const info = await getSignature({ timestamp: moment().valueOf() + Math.random() })
        // client.policy = info
        return {
          accessKeyId: decrypt(info.AccessKeyId),
          accessKeySecret: decrypt(info.AccessKeySecret),
          stsToken: decrypt(info.SecurityToken)
        }
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 300000
    })
    // client.policy = res
    return client
  }
  get() {
    return this.client
  }
}

export async function ossClient () {
  // client = await signature.get()
  const signature = Signature.getInstance()
  const client = await signature.get()
  return client
}

export async function ossPut(file, folder) {
  // const client = await ossClient()
  const signature = Signature.getInstance()
  const client = await signature.get()
  const year = moment().format('YYYY')
  const month = moment().format('MM')
  const day = moment().format('DD')
  const timestamp = moment().valueOf()
  const { userId } = store.getters.getUserInfo
  const path = `${folder}/${year}/${month}/${day}/${userId}_${timestamp}_${file.name}`
  try {
    const res = await client.put(path, file)
    res.path = path
    res.oriUrl = res.url
    res.url = client.signatureUrl(path);
    // res.policy = client.policy
    return res
  } catch (e) {
    console.log(e)
  }
}

export async function ossGet (path, options) {
  try {
    // const client = await ossClient()
    const signature = Signature.getInstance()
    const client = await signature.get()
    // 填写Object完整路径，例如exampledir/exampleobject.jpg。Object完整路径中不能包含Bucket名称。
    const url = await client.signatureUrl(path, options)
    return url
  } catch (e) {
    console.log(e)
  }
}

export async function ossDel (path) {
  // const client = await ossClient()
  const signature = Signature.getInstance()
  const client = await signature.get()
  return client.delete(path)
}
