/**
 * 传入时间与模板, 返回对应模板的时间字符串 'yyyy-MM-dd hh:mm:ss'
 * @param time
 * @param fmt
 * @returns {*}
 */
function timeFormat(time, fmt) {
  const date = new Date(time)
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds() // 秒
  }

  // RegExp.$1: 与正则匹配的第一项 子匹配(以括号为标志)字符串
  if (/(y+)/.test(fmt)) { // 年份
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }

  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }

  return fmt
}

export default timeFormat
