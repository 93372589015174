<template>
    <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '90%' }"
      round
    >
      <div class="title">查询条件
         <span style="position: absolute;right: 14px;min-width: 100px;text-align: right" @click="show = false">
          <van-icon name="cross" size="12" color="#000000" style="margin-top: 3px" />
        </span>
      </div>
     <div class="optionWrap" >
        <div  v-for="item in queryItems" :key="item.prop" :class="{'close':item.close}">
         <div class="name">{{item.label}}
           <span @click="empty(item.prop)">清空</span>
         </div>
         <div class="option">
          <span
              :class="{'active':checkedObj[item.prop].indexOf(item1.detailCode) > -1}"
              v-for="item1 in item.options" :key="item1.detailCode"
              @click="choose(item,item1)"
          >
          {{checkedObj[item.prop+'-'+item1.detailCode] ? checkedObj[item.prop+'-'+item1.detailCode]: item1.detailName}}
          </span>
         </div>
       </div>
     </div>
      <div class="bottom">
        <div class="resetting" @click="resetting">重置</div>
        <div class="sure" @click="sure">确定</div>
      </div>

      <DateTimeItem v-if="dataShow"  :dataShow="dataShow" :name="name" :title="title" @onCancel="onCancel" @onConfirm="onDataConfirm"/>

      <!-- 年份弹窗 -->
    <van-popup   
      v-model="timePicker"  
      round   
      get-container="body"   
      position="bottom" > 
      <van-picker     
        show-toolbar     
        title="选择年份"     
        :default-index="defaultIndex"     
        :columns="columns"     
        @cancel="timePicker = false"     
        @confirm="(val)=>{confirmDate(val,'time-7')} "  />
    </van-popup> 

    <!-- 选择月份 -->
      <van-popup   
        v-model="monthPicker"  
        round   
        get-container="body"   
        position="bottom" >
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @cancel="monthPicker = false"    
          @confirm="(val)=>{confirmDate(val,'time-8')} "
        />
      </van-popup>

    <Distribution
      :departmentName="'仪美尚,会员部,拓展组'"
      :special="true"
      :showDistribution="showDistribution"
      @onConfirm="(val)=>{confirmDate(val,'architecture-6')}"
      @onCancel="showDistribution = false"
      />
    </van-popup>
</template>

<script>
import DateTimeItem from '../../../components/DateTimeItem';
import CheckBoxAndSelectShow from '@/components/checkBoxAndSelectShow/index'
import {dicType,} from '@/api/common'
import { debounce } from "@/utils/util";
import Distribution from './distribution.vue'
import moment from 'moment';
const lastYear = moment(new Date()).year(); 
const yearsList = [...new Array(15).keys()].map((k) => { return `${lastYear - k}`;}).reverse();
export default {
  components:{
    CheckBoxAndSelectShow,
    DateTimeItem,
    Distribution
  },
  props:{
    isAll:{
      type:Boolean,
      default:false
    },
    identity:{
      type:String,
      default:'',
    },
    filterShow:{
      type:Boolean,
      default:false,
    },
    optionTitle:{
      type:String,
      default:'',
    },
    queryItems:{
      type:Array,
      default:()=>([])
    },
    totalNum:{
      types:String | Number,
      default:'',
    }
  },
  computed:{
     show: {
      get() {
        return this.filterShow;
      },
      set(val) {
          this.$emit("update:filterShow", val);
      }
    },
  },
  watch:{
    checkedList(val){
      this.handlerFunc(val)
      // this.$emit('filterSure',val)
    },
    form:{
      handler(val) {
        // this.handlerFunc(val)
      },
      deep:true
    }
  },
  data() {
    return {
      // 选择年
      timePicker: false,           
      columns: yearsList,            
      defaultIndex: 0,
      //选择月
      monthPicker:false,
      minDate: new Date(2005, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      // 
      showDistribution:false,
      // 
      name:'',
      title:'',
      dataShow:false,
      categoryArr:[],
      form:{
        startTime:'',
        endTime:''
      },
      certificationTypeList:[],
      timeList:[
        {detailName:'今日',detailCode:'1'},
        {detailName:'本周',detailCode:'2'},
        {detailName:'本月',detailCode:'3'},
        {detailName:'昨日',detailCode:'4'},
        {detailName:'上周',detailCode:'5'},
        {detailName:'上月',detailCode:'6'},
        {detailName:'选择年份',detailCode:'7'},
        {detailName:'选择月份',detailCode:'8'}
      ],
      userTypeList:[
        // {detailName:'专业观众',detailCode:'1'},
        // {detailName:'认证会员',detailCode:'2'},
        // {detailName:'已过期白金会员',detailCode:'3'},
        // {detailName:'手工录入',detailCode:'4'}
      ],
      checkedList:[],
      checkedObj:{

      },
      option:[],
      SYS_USER_LABEL_IDENT:[],//身份
    };
  },
  created(){
    this.init()
    this.handlerFunc = debounce(this.handlerFunc,500)
  },
  methods:{
    // 清空
      empty(prop){
        this.checkedObj[prop] = []
      },
     formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    confirmDate(value,type) {  
      console.log(value,'value')
      if(type === 'time-8') {
        value = moment(value).format('yyyy-MM')
      }
      if(type === 'architecture-6') {
        this.checkedObj['userId'] = value.userNo
        value = value.userName
      }
      this.checkedObj[type] = value
      console.log(this.checkedObj,'this.checkedObj')
      this.timePicker = false;
      this.monthPicker = false;
      this.showDistribution = false;
    },
    onDataConfirm(values){
      if (this.name === 'startTime'){
        this.form.startTime = values;
      }else if (this.name === 'endTime'){
        this.form.endTime = values
      }
      if (this.form.startTime&&this.form.endTime){
        console.log('代码测试=',values)
        console.log(values)
      }
      this.onCancel()
    },
    onCancel(){
      this.dataShow = false
    },
     timeStartClick(){
      this.form.expiry = '';
      this.name = 'startTime'
      this.title = '开始时间'
      this.dataShow = true
    },
    timeStopClick(){
      this.form.expiry = '';
      if (!this.form.startTime){
        this.$toast('请先选择开始时间！')
      }else {
        this.name = 'endTime'
        this.title = '结束时间'
        this.dataShow = true
      }
    },
    handlerFunc(val){
      console.log(val,'valaaaaaa')
      const clone = {...val}
      this.queryItems.map(item=>{
        if( clone[item.prop]){
          clone[item.prop] = clone[item.prop].join(',')
        }
      })
      this.$emit("filterSure",clone);
    },
    showMore(item){
      console.log('showMore=',item)
      item.close = !item.close
    },
    choose(pItem,item){
      if(pItem.multiple) {
        let index = this.checkedObj[pItem.prop].indexOf(item.detailCode)
        if(index > -1) {
          this.checkedObj[pItem.prop].splice(index,1)
        }else {
          this.checkedObj[pItem.prop].push(item.detailCode)
        }
      }else {
        // 单选
        this.checkedObj[pItem.prop].splice(0,1,item.detailCode)
      }
      if(item.detailCode === '7') {
        console.log('选择年份')
        this.timePicker = true
      }
      if(item.detailCode === '8') {
        console.log('选择年份')
        this.monthPicker = true
      }
      if(pItem.prop === 'architecture' && item.detailCode === '6') {
        console.log('选择年份')
        this.showDistribution = true
      }
      console.log(this.checkedObj[pItem.prop])
    },
    sure(){
      this.handlerFunc(this.checkedObj)
      // this.$emit('filterClose')
    },
    dataCallBack(data){
      console.log(data)
      console.log('测试',this.form)
      // if(data.name == 'categoryList') {
      //   this.$set(this.form,data.name,data.list)
      // }else {
      this.$set(this.form,data.name,data.value) //主营类目改用， 拼接了
      // }
      this.handlerFunc(this.form)
      // this.$emit('filterSure',this.form)
    },
    resetting(){
      console.log(this.queryItems)
      this.form = {
        certificationType:'',
        userType:'',
        categoryList:[],
        followersMin:'',
        followersMax:'',
        netRedMinNum:'',
        startTime:'',
        endTime:'',
      }
      this.queryItems.map((item)=>{
        this.form[item.prop] = item.value
      })
      this.handlerFunc(this.form)
      // this.$emit('filterSure',this.form)
      this.init()
    },
    initMounted() {
      let data = [
        {code: 'CERTIFICATION_TYPE', dicStatus: 1},
        // {code:'MAIN_BUSINESS_CATEGORY_TYPE',dicStatus:1},
        {code:'USER_TYPE',dicStatus:1},
        {code:'SYS_USER_LABEL_IDENT',dicStatus:1},
      ]
      this.initDictCode(data);
    },
    initDictCode(data){
      dicType({jsonData: data}).then(res=>{
        this.certificationTypeList = res.CERTIFICATION_TYPE || []
        // this.categoryArr = res.MAIN_BUSINESS_CATEGORY_TYPE || []
        this.userTypeList = res.USER_TYPE || []
        this.SYS_USER_LABEL_IDENT = res.SYS_USER_LABEL_IDENT || []
      })
    },
    init(){
      this.initMounted()
      const res = {}
      this.queryItems.map(item => {
        res[item.prop] = []
      })
      console.log('checkedObj=',res)
      this.checkedObj = res
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/css/filter';
.title{
  font-size: 30px;
  color: #333333;
  text-align: center;
  line-height: 42px;
  position: fixed;
  background: #FFFFFF;
}
.bottom{
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: flex;
  font-size: 24px;
  div{
    text-align: center;
    height: 70px;
    line-height: 70px;
    width: 346px;
  }
  .resetting{
    color: #4484FF;
    background: #F0F6FF;
    border-radius: 35px 0px 0px 35px;
  }
  .sure{
    color: #FFFFFF;
    background: #4484FF;
    border-radius: 0px 35px 35px 0px;
  }
}
.optionWrap{
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  .close{
    height: 160px;
    overflow: hidden;
  }
  .name{
    font-size: 28px;
    color: #333333;
    margin-bottom: 20px;
    margin-top: 26px;
    span{
      position: absolute;
      right: 48px;
      font-size: 24px;
      color: #888888;
    }
  }
  .option{
    font-size: 24px;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    span{
      display: inline-block;
      box-sizing: border-box;
      width: 220px;
      height: 60px;
      border-radius: 30px;
      color: #333333;
      background: #F6F6F6;
      line-height: 60px;
      text-align: center;
      margin-bottom: 26px;
      &:not(:nth-child(3n)) {
          margin-right: 15px;
      }
      &.active{
        background: rgba(68, 132, 255, 0.08);
        color: #4484FF;
        border: 1px solid #4484FF
      }
    }
  }
}
/* @import '../../../assets/css/filter'; */

</style>
