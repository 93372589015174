import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import router from './router'
import store from '@/store'
import loading from "./components/LoadingBlock/loading";
import ImageItem from './components/ImageItem';
import VideoItem from './components/VideoItem';
import TitleItem from './components/TitleItem';
import AreaItem from './components/AreaItem/index'
import SelectItem from './components/SelectItem/index'
import FormItem from './components/FormItem/index'
import '@/directives'
import { Button, Cell, CellGroup, Icon, Popup, Field, Col, RadioGroup, Radio, Row,
   Form, NavBar, Checkbox, CheckboxGroup, DatetimePicker, List, Picker,Tab, Tabs,Overlay,
  ImagePreview, Uploader, Area, Dialog, Notify, Toast, Swipe, SwipeItem, NoticeBar, ActionSheet, Cascader, Popover, Search , SwipeCell, Step, Steps, Loading,CountDown,Progress,PullRefresh,Divider    } from 'vant';
import 'amfe-flexible'
import '@/assets/css/index.scss'
Vue.config.productionTip = false
Vue.component('ImageItem',ImageItem)
Vue.component('VideoItem',VideoItem)
Vue.component('TitleItem',TitleItem)
Vue.component('AreaItem',AreaItem)
Vue.component('SelectItem',SelectItem)
Vue.component('FormItem',FormItem)
// import VConsole from 'vconsole'
// new VConsole();
Vue.use(loading)
Vue.use(VueCookies)
Vue.prototype.$EventBus = new Vue()
import wxConfig from '@/commonjs/wxConfig'
wxConfig(true, true)
/**
 * 按需引入vant组件库.......
 */
const useVantComponent = [ Button, Cell, CellGroup, Icon, RadioGroup, Radio, Popup, Field,
  Col, Row, Form, NavBar, Checkbox, CheckboxGroup, DatetimePicker, List,Overlay,
  Picker, Tab, Tabs, ImagePreview, Uploader, Area, Dialog, Notify, Toast, Swipe, SwipeItem, NoticeBar, ActionSheet, Cascader, Popover, Search, SwipeCell, Step, Steps, Loading,CountDown,Progress,PullRefresh,Divider  ];

useVantComponent.forEach(vc => {
  Vue.use(vc);
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
