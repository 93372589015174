<template>
  <van-popup v-model="show" round position="bottom">
    <van-cascader
        v-model="fieldValue"
        title="选择人员"
        :options="options"
        :field-names="fieldNames"
        active-color="#4484FF"
        @close="show = false"
        @finish="onFinish"
    />
  </van-popup>
</template>

<script>
import {all_receive} from '@/api/common'
export default {
  name: "index",
  props:{
    options:{
      type:Array,
      default:()=>[]
    },
    itemArr:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return{
      show:true,
      fieldValue:'',
      fieldNames:{ text: 'userName', value: 'userNo', children: 'childs' }
    }
  },
  methods:{
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      let data = {
        receiveList:this.itemArr,
        ownerUserId:selectedOptions[1].userNo
      }
      all_receive(data).then(res=>{
        this.show = false;
        this.$emit('finishCallBack',false)
      })
      console.log(selectedOptions)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
