<template>
    <div class="video-box">
        <div v-for="(item, index) in videoList" @click="handlePlay(index)" :key="index">
          <video
              :key="index"
              class="video-all"
              :src="item"
              loop
              :controls="false"
              :onerror="defaultImg"
              :poster="videoCoverList[index]"
              alt=""
          />
          <img class="switch-icon" src="@/assets/image/details/video-icon.png" alt="">
        </div>

        <van-popup v-model="videoShow" :style="{ width: '80%', height: '60%' }">
          <video id="video1" class="popup-video" :src="videoSrc" controls="controls">
            您的浏览器不支持 video 标签。
          </video>
        </van-popup>
    </div>
</template>
<script>
    import {cloneDeep} from 'lodash'
    import Signature from "@/utils/signature";

    export default {
        name: 'VideoItem',
        props: {
            // 默认图片
            initVideoList: {
                type: [String, Array],
                default: () => []
            },
            // 限制图片张数
            limit: {
                type: Number,
                default: 6
            }
        },
        data() {
            return {
                videoShow: false,
                videoSrc: '',
                defaultImg: 'this.src="' + require('@/assets/image/err.png') + '"',
                videoList: [],
                videoListSuffix: [],
                isFirst: false,
                isPlay: false, // 是否在播放
                videoCoverList: [] // 视频封面图
            }
        },
        watch: {
            initVideoList: {
                handler(value) {
                    if (value) {
                        if (!this.isFirst) { // 初始化的时候执行 只执行一次
                            this.isFirst = true
                            this.videoListSuffix = []
                            this.videoList = []
                            value = value instanceof Array ? value : [value]
                            if (value.length !== 0) {
                                value.forEach((item) => {
                                    this.renderVideo(item)
                                })
                                this.videoListSuffix = cloneDeep(value)
                            }
                        }
                    }
                },
                immediate: true
            }
        },
        mounted() {
        },
        methods: {
            async renderVideo(path) {
                if (path.length !== 0) {
                    const signature = Signature.getInstance()
                    const client = await signature.get()
                    const url = client.signatureUrl(path);
                    const imgUrl =  client.signatureUrl(path, {expires: 300000, process: `video/snapshot,t_0,f_jpg,w_750,h_306,m_fast,ar_auto`});
                    this.videoList.push(url)
                    this.videoCoverList.push(imgUrl)
                }
            },
            /**
             * 播放视频
             */
            handlePlay(index) {
              console.log(index)
              console.log(this.videoList)
                this.videoSrc = this.videoList[index]
              console.log('this.videoSrc', this.videoSrc)
                this.videoShow = true
            },
            /**
             * 关闭视频播放
             */
            handleClosePlay() {
                const video = document.querySelector('.video-all')
                this.isPlay = false
                video.pause()
            }

        }
    }
</script>
<style lang="scss" scoped>
    .video-box {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .video-all {
        object-fit: fill;
        width: 100%;
        height: 100%;
    }
    .switch-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 22px;
        height: 22px;
    }
    .popup-video {
      width: 100%;
      height: 100%;
    }
</style>
