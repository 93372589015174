import request from '@/utils/request'
import { NET_WORK_ENUM } from '@/api/index'

// 回收站
export function add_recycle(data = {}) {
    return request({
        url: '/uc/identity/recycle/add_recycle',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
