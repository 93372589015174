<template>
  <van-search
      background="transparent"
      v-model="value"
      show-action
      @input="valueInput"
      :placeholder="placeholderSearch"
      @search="onSearch"
  >
    <template slot="action">
      <div @click="onSearch" style="font-weight: bold">搜索</div>
    </template>
  </van-search>
</template>

<script>
var isclick = true;
export default {
  name: "search",
  data(){
    return{
    }
  },
  props:{
    placeholderSearch:{
      type:String,
      default:''
    },
    value:{
      type:String,
      default:''
    }
  },
  methods:{
    valueInput(val){
      this.value = val
    },
    onSearch(val){
      console.log(val)
      if(isclick){
        isclick = false;
        this.$emit('searchCallBack',this.value )
        setTimeout(function(){
          isclick = true;
        },1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.van-search__content{
  background: #FFFFFF!important;
  border-radius: 20px !important;
}
</style>
