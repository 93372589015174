import axios from 'axios'
// import store from '@/store'
import router from '../router'
import store from '@/store'
import Queue from './axios/queue'
import {Toast} from "vant";
import VueCookies from "vue-cookies";
import { loading } from '@/components/LoadingBlock/loading'
import AppBridge from '@/commonjs/appBridge'

const timeout = 150000
// create an axios instance
const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,
	timeout: 150000, // request timeout
	validateStatus: function (status) {
		return status >= 200 && status < 304 // default
	}
})
// 请求拦截器
const queue = Queue.create()
const CancelToken = axios.CancelToken
service.interceptors.request.use(
	config => {
		config.headers['loginFrom'] = VueCookies.get('source') || config.loginFrom || 'yms_ba'
		config.headers['token'] = store.getters.getToken
		// config.headers['token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyTmFtZSI6Imx5aDAwMSIsImV4cCI6MTY0NTIzODczMX0.slBaog8LYdUCjwODZ3GYM40nifzcgsh-0SQttjOk2Pg'
		const mock = config.mock || ''
		if (config.data) {
			config.data = config.data.jsonData ? config.data : {jsonData: config.data}
		}
		if (config.GATE) {
			if (mock) {
				config.url = mock + config.GATE + config.url
			} else {
				config.url = config.GATE + config.url
			}
		} else if (mock) {
			config.url = mock + config.url
		} else {
			config.url = process.env.VUE_APP_GATE_MGT + config.url
		}
		if (config.method === 'post') {
			config.data = config.data || {}
		}
		/** ------------处理重复提交-------------- */
		const source = CancelToken.source()
		config.cancelToken = source.token
		const URL = config.url + '--' + JSON.stringify(config.data)
		if (queue.has(URL)) {
			console.log('URL', URL)
			source.cancel('等待响应中，请勿重复提交')
			queue.remove()
		} else {
			queue.add({
				url: URL,
				source: source
			})
		}
		/** ------------处理重复提交-------------- */
		return config
	},
	error => {
		return Promise.reject(error)
	}
)


// 响应拦截器
service.interceptors.response.use(
	response => {

		/** ------------处理重复提交-------------- */
		/** ------------处理重复提交-------------- */
		/** ------------处理重复提交-------------- */
		const URL = response.config.url + '--' + (response.config.isFormData ? JSON.stringify(response.config.data) : response.config.data)
		queue.del(URL)
		/** ------------处理重复提交-------------- */
		/** ------------处理重复提交-------------- */
		/** ------------处理重复提交-------------- */
		const res = response.data
		// if the custom code is not 20000, it is judged as an error.
    if (parseInt(res.code) !== 0) {
      if (['10010', '09990', '401'].includes(res.code)) {

        loading.hide()
        Toast('登录失效')
        store.dispatch('logout')
        return
      }
      // 判断输入内容是否已存在
      if (res.code === '1047' || res.code === '10041') {
        return res
      }

			if (!response.config.hideErrorToast) {
				Toast(res.desc ? res.desc : '请求异常')
			}
      loading.hide()

      const err = {
        message: res.desc,
        code: res.code
      }
      return Promise.reject(err)
    } else {
		// if (parseInt(res.code) !== 0) {
		// 	if (parseInt(res.code) === 401) {
		// 		// Toast(res.desc ? res.desc : '请求异常');
		// 		AppBridge.jumpPage(0, '')
		// 	}
		// 	if (res.desc !== 'null') {
		// 		if (!response.config.isShowToast) {
		// 			Toast(res.desc ? res.desc : '请求异常');
		// 		}
		// 	}
		// 	const err = {
		// 		message: res.desc,
		// 		code: res.code
		// 	}
		// 	return Promise.reject(err)
		// } else {
			// if (res.content || res.content === 0) {
			// 	return res.content
			// } else {
			// 	return res
			// }
      return res.content
		}
	},
	error => {
    loading.hide()
		if (error.message === '等待响应中，请勿重复提交') {
			queue.remove()
			return Promise.reject(error)
		} else {
			if (error.response && (error.response.status === 403 || error.response.status === 401)) {
				Toast('登录失效');
        store.dispatch('logout')
			} else {
				if (error == `Error: timeout of ${timeout}ms exceeded`) {
					Toast('请求超时');
				} else {
					Toast('请求失败');
					store.dispatch('logout')
				}
			}
		}
		queue.remove()
		return Promise.reject(error)
	}
)

export default service

// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function myAxios({method = 'post', url, data = {}, GATE, params = {},loginFrom=''}) {
	return service({
		method,
		url,
		data,
		params,
		GATE,
    loginFrom,
	})
}

export const yunExportToExcel = () => {

}
