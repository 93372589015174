<template>
  <div>
    <van-dialog v-model="isShow" title="选择回收原因"  :before-close="onBeforeClose" show-cancel-button @confirm="handleConfirm"
                :confirm-button-color="'rgb(68, 132, 255)'" @cancel="onBeforeClose" >
      <div class="optionWrap-all" style="margin-top: 10px;">
        <div class="optionWrap">

          <div class="option option-center">
            <div :class="{'active': recycleList.includes(item.detailCode)}"
                 :key="index3"
                 v-for="(item,index3) in recycleArr"
                 @click="tabClick(item,index3)">{{item.detailName}}</div>
          </div>
        </div>
      </div>
      <div>
        <van-cell-group inset v-if="dialogShow">
          <van-field
              v-model="message"
              label=""
              :rows="4"
              type="textarea"
              style="min-height: 100px"
              placeholder="建议填写回收原因哦~"
              show-word-limit
          />
        </van-cell-group>
      </div>
      <div style="color: #999999;font-size: 8px;margin:4px 20px 20px 20px;">移入回收站之后可以从电脑端认证列表恢复</div>
    </van-dialog>
  </div>
</template>

<script>
import variables from '@/assets/css/variables.scss'
import CheckBoxAndSelectShow from '@/components/checkBoxAndSelectShow';
import {dicType} from "@/api/common";
import {add_recycle} from '@/api/recycle'
export default {
  name: "recycleDialog",
  components:{
    CheckBoxAndSelectShow
  },
  props: {
    isShow: {
      type: Boolean
    },
    recycleItemArr:{
      type: Array,
      default:()=>[]
    }
  },
  watch: {
    recycleItemArr:{
      handler(val){
        this.arr = val||[]
      }
    },
    isShow: {
      handler(val) {
        console.log('--------------',val)
        this.message = ''
        this.recycleList=[]
        this.show = val
      }
    }
  },
  data() {
    return {
      recycleArr:[],
      dialogShow:false,
      theme: variables.theme,
      show: false,
      info: null,
      message:'',
      recycleList:[],
      arr:[]
    }
  },
  mounted() {
    this.initMounted()
    this.recycleList=[]
    this.message = ''
    this.dialogShow = false

  },
  methods:{
    tabClick(item){
      const selectList = this.recycleList
      if (item.detailCode=='自定义'){
        this.recycleList = ['自定义']
      }else {
        if(selectList.includes(item.detailCode)) {
          const index = selectList.findIndex(list => list === item.detailCode)
          selectList.splice(index, 1)
        } else {
          selectList.push(item.detailCode)
          this.recycleList = selectList
        }
        this.recycleList.map((val,i)=>{
          if (val === '自定义'){
            this.recycleList .splice(i,1)
          }
        })
      }
      if (this.recycleList.includes('自定义')){
        this.dialogShow = true
      }else {
        this.dialogShow = false
      }
    },
    initMounted(){
      let data = [
        {code:'REASON_FOR_RECOVERY',dicStatus: 1},
      ]
      this.initDictCode(data);
    },
    initDictCode(data) {
      dicType({jsonData: data}).then(res => {
        console.log(res.REASON_FOR_RECOVERY)
        this.recycleArr = res.REASON_FOR_RECOVERY || []
        this.recycleArr.forEach(item=>{
          item.detailCode = item.detailName
        })
      })
    },
    //阻止关闭弹框
    onBeforeClose(action, done) {
      if (action === "confirm") {//点击确定按钮
        return done(false);//阻止关闭
      } else {
        this.dialogShow = false
        this.handleClose()
        return done()//允许关闭
      }
    },
    handleConfirm() {
      let arr = []
      if (this.recycleList.length==0){
        this.$toast('请选择至少一项回收原因！')
        return;
      }
      if (this.recycleList.includes('自定义')){
        if(this.message){
          arr = this.recycleList.concat([this.message])
        }else {
          this.$toast('请填写自定义回收原因！')
        }
      }else {
        arr = this.recycleList
      }
      arr.map((val,i)=>{
        if (val === '自定义'){
          arr.splice(i,1)
        }
      })
      console.log('最终数据===============',arr)
      if (arr.length!==0){
        let list = []
        this.arr.forEach(item => {
          list.push({id: item.id, strategyId: item.strategyId||item.id,identity:item.identity,reason:arr.join(',')})
        })
        add_recycle(list).then(res=>{
          console.log(res)
          this.$emit('confirmCallBack',false)
        })
      }
    },
    handleClose() {
      this.$emit('closeCallBack',false)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/filter';
::v-deep .van-field {
  background: #F5F5F5;
}
::v-deep .van-field__control::placeholder {
  color: #c8c9cc;
  padding:0px 10px 0px 10px;
}
::v-deep .van-field__control{
  padding:0px 10px 0px 10px;
}
</style>
