<template>
  <van-popup  v-model="dataShow"  position="bottom" @click-overlay="onCancel">
    <van-datetime-picker
        v-model="data"
        type="date"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="onCancel"
        title="选择年月日"
    />
  </van-popup>
</template>

<script>
import moment from 'moment'

import { areaList } from '@vant/area-data';

export default {
  name: "index",
  props:{
    title:{
      type:String,
      default:() =>""
    },
    data:{
      type:String,
      default:() => new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    },
    dataShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxDate: new Date(2100, 10, 1),
      areaList
    };
  },
  methods:{
    onConfirm(data){
      let time = moment(data).format('YYYY-MM-DD')
      console.log('tag', time)
      this.$emit("onConfirm",time)
    },
    onChange(){

    },
    onCancel(){
      this.$emit('onCancel',false)
      console.log('点击了取消')
    },
  }
}
</script>

<style lang="scss" scoped >

</style>
