<template>
  <van-dialog class="contacts--dialog" v-model="show" confirm-button-text="复制" :confirm-button-color="theme" @confirm="handleConfirm">
    <div v-if="contactsInfo" class="contacts__header">
      <div class="contacts__header--info">
        <div class="contacts__header__name">{{ contactsInfo.userName || '--' }}</div>
        <div v-if="contactsInfo.positionName">{{ contactsInfo.positionName || '--' }}</div>
        <div v-if="contactsInfo.companyName" class="contacts__header__company">{{ contactsInfo.companyName || '--' }}</div>
      </div>
      <div class="contacts__avatar">
        <image-item
            :key="contactsInfo.headUrl"
            :limit="1"
            :disabled="true"
            :init-image-list="contactsInfo.headUrl"
        />
      </div>
    </div>
    <div v-if="contactsInfo" class="contacts__content">
      <div class="contacts__content__item">
        <div class="contacts__content__item--left">手机：{{ contactsInfo.phone || '--' }}</div>
        <a v-if="contactsInfo.phone" class="contacts__content__operate" :href="`tel:${contactsInfo.phone}`">
          <img class="contacts__content__operate__icon--1" src="@/assets/image/details/phone-icon.png"/>打给他
        </a>
      </div>
      <div class="contacts__content__item">
        <div class="contacts__content__item--left">微信：{{ contactsInfo.weChat || '--' }}</div>
        <div v-if="contactsInfo.weChat" class="contacts__content__operate" @click="handleCopy(contactsInfo.weChat)">
          <img class="contacts__content__operate__icon--2" src="@/assets/image/details/wechat-icon.png"/>复制
        </div>
      </div>
      <div class="contacts__content__item">
        <div class="contacts__content__item--left">邮箱：{{ contactsInfo.email || '--' }}</div>
        <div v-if="contactsInfo.email" class="contacts__content__operate" @click="handleCopy(contactsInfo.email)">
          <img class="contacts__content__operate__icon--3" src="@/assets/image/details/email-icon.png"/>复制
        </div>
      </div>
    </div>
    <div class="contacts--dialog__close--wrap" @click="handleClose">
      <img class="contacts--dialog__close" src="@/assets/image/dialog-close.png"/>
    </div>

  </van-dialog>
</template>

<script>
import variables from '@/assets/css/variables.scss'
import { copyText } from "@/utils/util"
import { getContactsInfo,get_by_identity } from '@/api/details'
export default {
  name: "ContactsDialog",
  props: {
    isShow: {
      type: Boolean
    },
    connectItem: {
      type: Object
    },
    brandInfo:{
      type: Object
    }
  },
  watch: {
    isShow: {
      handler(val) {
        this.show = val
      }
    },
    brandInfo: {
      handler(val) {
        console.log(val)
      }
    },
    connectItem: {
      handler(val) {
        if(val && val.userId) {
          this.contactsInfo = null
          this.getContactsInfo()
        }
      }
    }
  },
  data() {
    return {
      theme: variables.theme,
      show: false,
      contactsInfo: null
    }
  },
  methods: {
    handleCopy(data) {
      copyText(data)
    },
    async getContactsInfo() {
      const res = await getContactsInfo({
        dataId:  this.connectItem.userId,
        jsonData: this.connectItem.userType
      })
      // const res = await get_by_identity({
      //   userType:this.connectItem.userType == 4 ? 2 : 1,
      //   identity:this.connectItem.identity || this.connectItem.identityType,
      //   userId:Number(this.connectItem.userType) === 4 ? this.connectItem.id : this.connectItem.userId,
      // })
      this.contactsInfo = res
      if(this.brandInfo){
        const {dockingPeopleName,dockingPeoplePositionC,companyName,dockingPeoplePhone,dockingPeopleWechat,dockingPeopleEmail} = this.brandInfo;
        this.contactsInfo.userName = dockingPeopleName;
        this.contactsInfo.positionName = dockingPeoplePositionC;
        this.contactsInfo.companyName = companyName;
        this.contactsInfo.phone = dockingPeoplePhone;
        this.contactsInfo.weChat = dockingPeopleWechat;
        this.contactsInfo.email = dockingPeopleEmail;
      }
    },
    handleConfirm() {
      const { userName, phone, weChat } = this.contactsInfo
      const text = `${userName}   手机：${phone}   微信：${weChat}`
      copyText(text)
      this.$toast.success('复制成功')
      this.$emit('contactsConfirm')
    },
    handleClose() {
      this.$emit('contactsConfirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts--dialog {
  width: 600px;
  box-sizing: border-box;
  padding: 60px 46px 0 46px;
  &__close {
    width: 28px;
    height: 28px;
    &--wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 92px;
      height: 92px;
    }
  }
}
.contacts {

  &__header {
    display: flex;
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 34px;
    color: #333;
    &--info {
      flex: 1;
    }
    &__name {
      font-size: 30px;
      line-height: 42px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    &__company {
      margin-top: 6px;
    }
  }
  &__avatar {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    overflow: hidden;
  }
  &__content {
    color: #333;
    &__item {
      display: flex;
      font-size: 28px;
      margin-bottom: 22px;
      &--left {
        flex: 1;
      }
    }
    &__operate {
      display: flex;
      align-items: center;
      color: $theme;
      font-size: 28px;
      &__icon--1 {
        width: 28px;
        height: 26px;
        margin-right: 6px;
      }
      &__icon--2 {
        width: 28px;
        height: 28px;
        margin-right: 6px;
      }
      &__icon--3 {
        width: 28px;
        height: 28px;
        margin-right: 6px;
      }
    }
  }
}
</style>
