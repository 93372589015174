<template>
  <div v-if="buttonAllowed(`chart:customer:total`)" >
    <van-tabs v-model="active" color="#4484FF" title-active-color="#4484FF" title-inactive-color="#999999" @disabled="onClickDisabled">
      <van-tab title="客户总量分析"></van-tab>
      <van-tab title="白金客户总量分析" :disabled="true"></van-tab>
    </van-tabs>
    <div class="wrap">
      <div class="chartBox">
        <div class="inner">
          <div class="topArea">
            客户总量分析
            <span @click="filterShow = true">查询条件</span>
          </div>
          <div class="chart" ref="chart"></div>
        </div>
      </div>
      <div class="table">
        <table class="details--table">
          <tbody>
            <tr>
              <th v-for="item in [{yearMonth:'日期'},...tableList]" :key="item.yearMonth"><div>{{ item.yearMonth }}</div></th>
            </tr>
            <tr v-for="item,index in ['客户总数','本月kpi','BD成功数','完成率']" :key="item.id">
              <td> <div>{{item}}</div></td>
              <td v-for="j in tableList" :key="j.yearMonth">
                <div @click="jump(index,j)">{{j[props[index]]}}{{(index === 3?'%':'')}}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <filterPop
      :filterShow.sync="filterShow"
      :optionTitle="'所在平台'"
      :queryItems="queryItems"
      @filterSure="filter"
      @filterClose="filterShow = false">
    </filterPop>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from '@/utils/echarts'
import filterPop from './components/filterPop'
import {chartList,tableList} from '@/api/chart.js'
import {buttonAllowed} from "@/utils/util";
export default {
  data(){
    return {
      active:0,
      filterShow:false,
      queryItems:[
        { prop: 'time', label: '时间选择', value: '',
          options:[
          {detailName:'今日',detailCode:'1'},
          {detailName:'本周',detailCode:'2'},
          {detailName:'本月',detailCode:'3'},
          {detailName:'昨日',detailCode:'4'},
          {detailName:'上周',detailCode:'5'},
          {detailName:'上月',detailCode:'6'},
          {detailName:'选择年份',detailCode:'7'},
          {detailName:'选择月份',detailCode:'8'}
        ],close:false },
        {prop: 'architecture', label: '组织架构选择', value: '',options:[
          {detailName:'我的',detailCode:'4'},
          {detailName:'全部',detailCode:'5'},
          {detailName:'选择人员/部门',detailCode:'6'},
        ],close:true },
      ],
      tableList:[],
      props:['assignCustomerNum','kpiTarget','bdSuccessNum','completeRate']
    }
  },
  components:{
    filterPop
  },
  mounted(){
    this.init()
    this.getTableList()
  },
  methods:{
    buttonAllowed,
    onClickDisabled(){
      this.$toast('功能开发中，敬请期待')
    },
    async getTableList(){
      const res = await tableList()
      this.tableList = res
      console.log(res)
    },
    jump(index,val){
      console.log(val)
        // return
        let assignStartTime = moment(val.yearMonth).startOf('month').format('YYYY-MM-DD HH:mm:ss')
        let assignEndTime = moment(val.yearMonth).endOf('month').format('YYYY-MM-DD HH:mm:ss')
      if(index == 0){
        this.$router.push(`/bd_person?&active=1&assignStartTime=${assignStartTime}&assignEndTime=${assignEndTime}&expandDeptAuthority=${true}`)
      }else if(index == 2){
        this.$router.push(`/bd_person?&active=0`)
      }
    },
    filter(val){
      function filterTime(type,time){
        if(type === 'start') {
          return moment().startOf(time).format('YYYY-MM-DD HH:mm:ss')
        }else if(type === 'end'){
          return moment().endOf(time).format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let assignStartTime = ''
      let assignEndTime = ''
      let ownerUserIdList = ''
      switch(val.time) {
        case '1':
          assignStartTime = filterTime('start','day')
          assignEndTime = filterTime('end','day')
          break
        case '2':
          assignStartTime = filterTime('start','isoWeek')
          assignEndTime = filterTime('end','isoWeek')
          break
        case '3':
          assignStartTime = filterTime('start','month')
          assignEndTime = filterTime('end','month')
          break
        case '4':
           assignStartTime = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
           assignEndTime = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')
          break
        case '5':{
           assignStartTime = moment().subtract(1, 'isoWeek').startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')
           assignEndTime = moment().subtract(1, 'isoWeek').endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')
          break
        }
        case '6':{
           assignStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
           assignEndTime = moment().subtract( 1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
          break
        }
        case '7':{
          assignStartTime = moment(val['time-7']).startOf('year').format('YYYY-MM-DD HH:mm:ss')
          assignEndTime = moment(val['time-7']).endOf('year').format('YYYY-MM-DD HH:mm:ss')
          break
        }
        case '8':{
          assignStartTime = moment(val['time-8']).startOf('month').format('YYYY-MM-DD HH:mm:ss')
          assignEndTime = moment(val['time-8']).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          break
        }
      }
      switch(val.architecture) {
          case '4':{
            ownerUserIdList = [JSON.parse(localStorage.getItem('USERINFO')).userId]
            break
          }
          case '5':{
            ownerUserIdList = []
            break
          }
          case '6':{
            ownerUserIdList = [val.userId]
            break
          }
      }
      console.log(val,'vallll')
      console.log(assignStartTime)
      console.log(assignEndTime)
      console.log(ownerUserIdList)

      this.$router.push(`/totalCustomers?assignStartTime=${assignStartTime}&assignEndTime=${assignEndTime}&ownerUserIdList=${JSON.stringify(ownerUserIdList)}`)
    },
    async init(){

      const res =await chartList()
      console.log(res,'rs')
      const chart = this.$refs.chart
      const myChart = echarts.init(chart)
      const option = {
        grid: {
          top:'10%',
          left: '3%',
          right: '4%',
          bottom: '15%',
          containLabel: true
        },
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
              offset: 0, color: '#63D4FF'    // 0% 处的颜色
          }, {
              offset: 1, color: '#337BF2'   // 100% 处的颜色
          }],
          globalCoord: false // 缺省为 false
        },
        // dataZoom:[
        //  {
        //     type: 'inside',
        //     start: 0,
        //     end: 100,
        //   },
        //   {
        //     start: 0,
        //     end: 10,
        //     bottom:10,
        //     height:15,
        //   }
        // ],
        xAxis: {
          type: 'category',
          boundaryGap:false,
          data: res.map(item => item.month + '月')
        },
        yAxis: {
          type: 'value',
          splitLine :{
          lineStyle:{
            type:'dashed'
          },
          show:true
          },
        },
        series: [
          {
            itemStyle:{
              normal:{
                lineStyle:{
                  width:4
                }
              }
            },
            data: res.map(item => item.num ),
            type: 'line',
            smooth: true
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap{
  height: calc(100vh - 240px);
  padding:30px 30px 140px;
  overflow: auto;
  .chartBox{
    padding-top: 30px;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
    .inner{
      margin: 30px;
      overflow: auto;
      padding-top: 44px;
    }
    .topArea{
      width: calc(100% - 66px) ;
      position: absolute;
      top: 44px;
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      display: flex;
      justify-content: space-between;
      span{
        font-size: 28px;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
      }
    }
    .chart{
      width: 200%;
      height: 460px;
    }
  }
  .table{
    box-sizing: border-box;
    margin-top: 42px;
    padding: 30px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    overflow-x: scroll;
     .details--table {
      font-size: 24px;
      color: #333;
      text-align: left;
      border-collapse: collapse;
      table-layout: auto;
      word-break: break-all;
      width: 100%;
      tr{
        &:nth-child(2),&:nth-child(4) {
          td:not(:first-child) {
            color: #4484FF;
          }
        }
        &:nth-child(odd) {
          background: #ECF2FF;
        }
        td:first-child,th:first-child{
          div{
            width: 200px;
          }
        }
      }
      td, th {
        height: 80px;
        width: 100px;
        white-space: nowrap;
        padding: 0 10px;
        font-weight: normal;
        div{
          min-width: 100px;
        }
      }
    }
  }
}
</style>
