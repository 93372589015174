/*
 * @Descripttion: 分销项目
 * @version: v1.05
 * @Author: 李杰锋
 * @Date: 2021-02-02 16:54:52
 * @LastEditors: lijiefeng
 * @LastEditTime: 2021-07-05 13:35:39
 */
export default class Queue {
  constructor() {
    this.list = []
    this.instance = null
  }
  static create() {
    if (this.instance) this.instance
    return new Queue()
  }
  has(url) {
    return this.list.some(v => {
      return v.url === url
    })
  }
  get(url) {
    return this.list.find(v => {
      return v.url === url
    })
  }
  add(item) {
    this.list.push({
      url: item.url,
      source: item.source
    })
  }
  del(url) {
    const i = this.list.findIndex(v => {
      return v.url === url
    })
    if (i > -1) {
      this.list.splice(i, 1)
    }
  }
  all() {
    return this.list
  }
  remove() {
    this.list = []
  }
}

