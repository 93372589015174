import navigator from "@/commonjs/navigator.js";
/**
 * 暂时保存安卓扩展回调方法。 key 为 sourceFrom + Date.now()
 */
const AndroidFunc = {};
/**
 *
 * @param {string} name 必填 app扩展方法名
 * @param {object} data 扩展进参 可空 备注安卓 sourceFrom 不用填写，
 * @param {string} androidCallbackName 安卓扩展回调方法名 可空 默认为空
 * @param {Boolean} isIosCallback IOS扩展是否有回调 默认false
 * @param {Boolean} isSourceFrom 安卓回调是否有 sourceFrom
 * @param {string} androidFailCallbackName 安卓扩展失败回调方法名 可空 默认为空
 * @returns
 */
async function appCall(
  name,
  data,
  androidCallbackName = "",
  isIosCallback = false,
  isSourceFrom = false,
  androidFailCallbackName = ""
) {
  const isApp = navigator.openInWebview();
  const isEquipment = navigator.isEquipment();
  // const isIos = isApp && isEquipment.isIOS;
  const isIos = isEquipment.isIOS;
  // const isAndroid = isApp && isEquipment.isAndroid;
  const isAndroid = isEquipment.isAndroid;
  return new Promise((resolve, reject) => {
    if (isIos) {
      let params = { action: name };
      if (data) {
        params.data = data;
      }
      if (isIosCallback) {
        params.callback = {
          success: (response) => {
            let item = response;
            if (typeof response === "string") {
              item = JSON.parse(response);
            }
            resolve(item);
          },
          fail: (response) => {
            let item = response;
            if (typeof response === "string") {
              item = JSON.parse(response);
            }
            reject(item);
          },
        };
      }
      window.iosBridge.call(params);
    } else if (isAndroid) {
      if (data) {
        if (androidCallbackName) {
          const sourceFrom = androidCallbackName + Date.now();
          data.sourceFrom = sourceFrom;
          AndroidFunc[sourceFrom] = (response) => {
            resolve(response);
            delete AndroidFunc[sourceFrom];
          };

          window[androidCallbackName] = (response) => {
            let res = response;
            if (typeof response === "string") {
              res = JSON.parse(response);
            }
            if (
              isSourceFrom &&
              res &&
              res.sourceFrom &&
              AndroidFunc[res.sourceFrom] &&
              typeof AndroidFunc[res.sourceFrom] === "function"
            ) {
              AndroidFunc[res.sourceFrom](res);
            } else if (!isSourceFrom) {
              resolve(res || true);
              delete window[androidCallbackName];
            } else {
              reject({
                message: "未找到对应回调。sourceFrom：" + res.sourceFrom,
              });
            }
          };
        }
        if (androidFailCallbackName) {
          window[androidFailCallbackName] = (response) => {
            reject(response || false);
            delete window[androidFailCallbackName];
          };
        }
        window.androidBridge[name](JSON.stringify(data));
      } else {
        const res = window.androidBridge[name]();
        resolve(res)
      }
    } else {
      reject({ message: "不在APP环境。", code: "notApp" });
    }
  });
}
/**
 *  原生api 封装类
 */
class AppBridge {
  constructor() {}
  static get isApp() {
    return navigator.openInWebview();
  }
  static get isIOS() {
    return this.isApp && navigator.isEquipment().isIOS;
  }
  static get isAndroid() {
    return this.isApp && navigator.isEquipment().isAndroid;
  }
  static get UPLOAD_TYPE() {
    return {
      image: "image",
      video: "video",
    };
  }
  /**
   *
   * @param {object} param0  {object} params {name:'',//上传文件放在哪个目录下。 视频类型无此参数
   * isCrop:false,//是否裁剪 default：false
   * @param {*} type type:this.UPLOAD_TYPE.image //上传类型 图片|视频 default：this.UPLOAD_TYPE.image}
   * @returns  {object} Promise<{url:'',...}>上传成功回调url。 注意：视频类型为oss上传。
   */
  static async upload(
    {
      dirName = "",
      isCrop = false,
      mediaType = this.UPLOAD_TYPE.image,
      imageScaleWidth = null,
      imageScaleHeight = null,
      sourceFrom = '',
    }
  ) {
    let res = null;
    let data = {
      dirName,
      isCrop,
      mediaType,
      sourceFrom
    };
    if (imageScaleWidth && imageScaleHeight) {
      data.imageScaleWidth = imageScaleWidth;
      data.imageScaleHeight = imageScaleHeight;
    }
    res = await appCall("native_chooseAndUploadMedia", data, "returnUrl", true);
    // res.url = res.image_path;
    return res;
  }
  /**
   * 刷新用户
   */
  static refreshUserInfo(type) {
    const data = {
      type
    }
    appCall("native_refresh", data);
  }
  /**
   * 跳转页面
   */
  static jumpPage(pageType, params) {
    let data = {};
    if (pageType===10){
      if (params.userID){
        data ={
          pageType,
          params
        }
      }else {
        data = {
          pageType
        }
      }
    }else {
      data ={
        pageType,
        params
      }
    }
    appCall('native_jumpPage', data)
  }
  /**
   * 显示原生弹框
   */
  static showNativeAlert(params) {
    appCall('native_alert', params)
  }
  /**
   * 分享图片
   */
  // eslint-disable-next-line no-dupe-class-members
  static shareImagePage(directShare, showShareBtn,shareContent) {
    const data = {
      directShare,
      showShareBtn,
      shareContent
    }
    appCall('native_shareImage', data)
  }
  /**
   * 分享链接
   */
  // eslint-disable-next-line no-dupe-class-members
  static shareLinkPage(directShare, showShareBtn,shareContent) {
    const data = {
      directShare,
      showShareBtn,
      shareContent
    }
    appCall('native_shareLink', data)
  }
  /**
   * 获取用户信息
   */
  // eslint-disable-next-line no-dupe-class-members
  static async getCurrentUserInfo() {
    try {
      const isEquipment = navigator.isEquipment();
      const isIos = isEquipment.isIOS;
      if(isIos) {
        const res = await appCall("native_getCurrentUserInfo", '', '', true);
        return res
      }
      const res = await appCall('native_getCurrentUserInfo')
      return res
    } catch (error) {
      throw false;
    }

  }
  /**
   * 关闭当前web
   */
  static closeWeb(pageType, params) {
    appCall('native_closeWeb')
  }
  static async pay(params) {
    try {
      await appCall(
        "native_pay",
        params,
        "returnSuccessRegistration",
        true,
        false,
        "returnPayErrorSuccessRegistration"
      );
      return true;
    } catch (error) {
      throw false;
    }
  }
}

export default AppBridge;
