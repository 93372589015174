<template>
  <div id="app">
    <keep-alive>
      <router-view  :key="$route.fullPath" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive"></router-view>
    <bottom-navigation v-if="navigationShow"/>
  </div>
</template>

<script>
import BottomNavigation from '@/components/BottomNavigation'
export default {
  name: 'App',
  components: {
    BottomNavigation
  },
  computed: {
    navigationShow () {
      return Boolean(this.$route.meta?.bottomnavigation)
    }
  },
  created() {
    console.log('created')

    sessionStorage.removeItem('proxyEnv')
    const { VUE_APP_ENV, NODE_ENV } = process.env
    if (NODE_ENV === 'dev') sessionStorage.setItem('proxyEnv', VUE_APP_ENV || 'dev')

    if (
      location.href.includes('guest_confirm') ||
      location.href.includes('guest_success')
    ) {
      return 
    }
    // 除了上面的几个界面下 都需要 重新获取到权限
    this.$store.dispatch('getCode')
   
  },
  methods:{
  }
}
</script>

<style lang="scss">
body {
  background-color: #FBFAFA;
}
.toastClassName{
  z-index: 1000000001 !important;
}
/* .operate-item {
        display: flex;
        align-items: center;
        height: 98px;
        color: #333333;
        padding: 0 12px 0 16px;
        font-size: 28px;
        border-bottom: 1px solid #E5E5E5;
        &:last-child {
          border-bottom: none;
        }
        &__icon {
          width: 26px;
          height: 26px;
          margin-right: 6px;
        }
      } */
</style>
