<template>
  <div v-bind:key="key" v-if="options.length!==0">
    <div class="name">
      {{ title }}
      <van-popover
          v-if="isShowPopover"
          v-model="showPopover"
          placement="top-start"
          theme="dark"
          trigger="click"
      >
        <div class="popover-text">{{ popoverText }}</div>
        <template #reference>
          <van-icon name="info-o" />
        </template>
      </van-popover>
      <span v-if="options && options.length > 3" @click="showMoreClick" class="name-string-div">
        <div class="name-string">{{ returnNameString() }}</div>
        <span style="color: #333333" v-if="selectArr.length==0">{{ option.length > 3 ? '收起' : '展开' }}</span>
        <img class="option-img" v-if="option.length>3" src="@/assets/image/up.png">
        <img class="option-img" v-else src="@/assets/image/down.png">
      </span>
    </div>
    <div class="option option-center">
      <div :class="{'active': selectArr.includes(item.detailCode)}"
           :key="index3"
           v-for="(item,index3) in option"
           @click="tabClick(item,index3,type)">{{ item.detailName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      showPopover: false,
      popoverText: '根据公司名称检测，同公司是否已购买白金会员;若手动修改公司名称，则会在次日0点更新数据',
      option: [],
      selectArr: [],
    }
  },
  props: {
    isShowPopover: {
      type: Boolean,
      default: false
    },
    isArray: {},
    options: {},
    name: {},
    title: {},
    type: {},
    selectList: {
      type: Array,
      default: () => []
    },
    key: {}
  },
  watch: {
    options() {
      this.option = JSON.parse(JSON.stringify(this.options))
      this.selectArr = JSON.parse(JSON.stringify(this.selectList))
      this.showMoreClick()
    },
  },
  created() {
  },
  mounted() {
    this.option = JSON.parse(JSON.stringify(this.options))
    this.selectArr = JSON.parse(JSON.stringify(this.selectList))
    this.showMoreClick()
  },
  methods: {
    returnNameString() {
      let arr = [];
      if (this.options.length !== 0) {
        this.options.forEach(item => {
          this.selectArr.forEach(value => {
            if (item.detailCode === value) {
              arr.push(item.detailName)
            }
          })
        })
        if (arr.length !== 0) {
          return arr.join(',')
        } else {
          return ''
        }
      }
    },
    showMoreClick() {
      if (this.option.length > 3) {
        this.option.length = 3
      } else {
        this.option = JSON.parse(JSON.stringify(this.options))
      }
      this.$forceUpdate()
    },
    tabClick(item, index, field) {
      if (field === 'cb') { // 多选
        const selectList = this.selectArr
        if (selectList.includes(item.detailCode)) {
          const index = selectList.findIndex(list => list === item.detailCode)
          selectList.splice(index, 1)
        } else {
          selectList.push(item.detailCode)
        }
        this.selectArr = selectList
      } else { // 单选
        if (this.selectArr.includes(item.detailCode)) {
          this.selectArr = []
        } else {
          this.selectArr = [item.detailCode]
        }
      }
      let arr = this.selectArr;
      let data = {
        name: this.name,
        value: this.isArray ? arr : arr.join(','),
        list: this.selectArr
      }
      this.$emit('dataCallBack', data)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/filter';
.popover-text {
  width: 400px;
  padding: 10px 20px;
  font-size: 24px;
}
.name-string-div {
  float: right;
  font-weight: normal;
  color: #4484FF;
  text-align: right;
  display: flex;
  flex-direction: row;
}

.name-string {
  width: 400px;
  color: #4484FF;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
