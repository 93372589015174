<template>
    <van-popup
      v-model="show"
      position="bottom"
      @click-overlay="onCancel"
      round
    >
      <van-picker
        title="选择顾问"
        show-toolbar
        :value-key="'userName'"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
</template>

<script>
import {getDepartmentUser} from "@/api/customerSea";
export default {
  props:{
    showDistribution:{
      type:Boolean,
      default:false,
    },
    departmentName:{
      type:String,
      default:'',
    },
    special:{
      type:Boolean,
      default:false
    }
  },
   computed:{
     show: {
      get() {
        return this.showDistribution;
      },
      set(val) {
        this.$emit("update:showDistribution", val);
      }
    },
  },
  data() {
    return {
      columns:[]
    };
  },
  mounted(){
    this.getUserHousekeeperList()
  },
  methods:{
    async getUserHousekeeperList(){
      const res =await getDepartmentUser({[this.special?'allName':'name']:this.departmentName})
      this.columns = res && res.length ? res[0].childs : []
    },
    onConfirm(item){
      this.$emit('onConfirm',item)
    },
    onCancel(){
      this.$emit('onCancel')
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
