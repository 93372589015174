<!--
    @FilePath: src\views\customer\components\Pop.vue
    @Description: 确认弹窗
    @Author: lyh
-->
<template>
  <van-popup
      :close-on-click-overlay="false"
      @close="clickClose"
      :overlay-style="{ backgroundColor: 'rgba(0,0,0,.5)' }"
      v-model="isShow">
    <div class="content-box">
      <div class="text-box">
        <div class="phone-text">确认绑定 <span class="phone">{{ phone }}</span></div>
        <div>为客户注册手机号，绑定后不可修改，请谨慎操作</div>
      </div>
      <div class="btn-box">
        <div @click="clickClose" class="btn">取消</div>
        <div class="btn">确认</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "Pop",
  props: {
    isShow: {
      default: false
    },
    phone: {
      default: ''
    }
  },
  methods: {
    /**
     * 点击关闭弹窗
     */
    clickClose() {
      this.$emit('clickClose')
    }
  }
}
</script>

<style scoped lang="scss">
.van-popup--center {
  border-radius: 20px;
  overflow: hidden;
}

.content-box {
  background-color: #fff;
  width: 470px;
  font-size: 24px;
  text-align: center;
  color: #333333;
  line-height: 34px;

  .phone {
    font-size: 30px;
    font-weight: 400;
  }

  .phone-text {
    padding-bottom: 16px;
  }

  .text-box {
    padding: 56px 80px 38px;
  }

  .btn-box {
    line-height: 86px;
    display: flex;
    font-size: 28px;
    border-top: 1px solid #E5E5E5;

    .btn {
      flex: 1;
      color: #3D7FFF;

      &:nth-child(1) {
        border-right: 1px solid #E5E5E5;
        color: #888888;
      }
    }
  }
}
</style>
