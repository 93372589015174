// 图表
import Chart from '@/views/chart/index'
import TotalCustomers from '@/views/chart/totalCustomers'
const chartRouter = [
    {
        path: '/chart',
        name: 'Chart',
        component: Chart,
        meta: {
            keepAlive: false,
            title: "图表",
            bottomnavigation:true
        }
    },
    {
        path: '/totalCustomers',
        name: 'TotalCustomers',
        component: TotalCustomers,
        meta: {
            keepAlive: false,
            title: "客户总数",
        }
    }
]

export default chartRouter
