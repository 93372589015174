<template>
  <div>
    <van-swipe-cell
        class="swipe"
        @open="openClick(item)"
        @close="closeClick"
    >
      <div class="item" :class="{'isMultiple':isMultiple}">
        <div class="checked" v-if="isMultiple">
          <van-checkbox :name="item"></van-checkbox>
        </div>
        <div class="received-all"   @click="detailClick(item)">
          <div>
            <div class="received-name">
              <IdentityTypeTem class="" :identity="item.identity" />
              <span style="max-width: 80%"  v-if="item.identity=='USER_LABEL_IDENT_RETAILER'||item.identity=='USER_LABEL_IDENT_RED'">{{returnName(item) }}</span>
              <span style="max-width: 80%" v-else>{{ item.companyName }}</span>
              <span style="color: #eb5f00; font-size: 12px; font-weight: normal; padding-left: 4px">{{ item.suspectedWhiteGold ? '疑似白金' : ''}}</span>
              <div class="received-num">
                <img class="received-img" src="@/assets/image/received/user.png" />
                <div>{{item.concatNumber||'0'}}</div>
              </div>
            </div>
<!--            TODO 店铺-->
            <div class="received-item"  v-if="item.identity=='USER_LABEL_IDENT_RETAILER'">
              <span class="received-job " v-if="item.provinceName">地域：{{ item.provinceName||'--' }}</span>
            </div>
<!--            TODO 品牌-->
            <div class="received-item" v-if="item.identity=='USER_LABEL_IDENT_BRAND'">
              <span class="received-job " v-if="item.name">品牌：{{returnName(item) }}</span>
            </div>
 <!--            TODO 红人-->
            <div class="received-item" v-if="item.identity=='USER_LABEL_IDENT_RED'">
              <span class="received-job " v-if="item.followers">粉丝数：{{ item.followers||'0' }}</span>
            </div>
<!--            TODO 机构-->
            <div class="received-item" v-if="item.identity=='USER_LABEL_IDENT_RED_ORG'">
              <span class="received-job " v-if="item.netRedNum">签约红人：{{ item.netRedNum||'0' }}</span>
            </div>
<!--            TODO 工厂-->
            <div class="received-item" v-if="item.identity=='USER_LABEL_IDENT_FACTORY'">
              <span class="received-job " v-if="item.factoryTypeName">工厂类型：{{ item.factoryTypeName||'--' }}</span>
            </div>
<!--            TODO 服务商-->
            <div class="received-item" v-if="item.identity=='USER_LABEL_IDENT_SERVICE_PROVIDER'">
              <span class="received-job " v-if="item.serviceTypeName">服务商类型：{{ item.serviceTypeName||'--' }}</span>
            </div>
            <div class="received-item">
              <span class="received-job ">{{ item.userTypeName||'--' }}</span>
            </div>
            <div class="received-item">
              <span class="received-job ">BD跟进天数：{{ item.days||'0' }} 丨</span><span class="received-job">跟进次数：{{ item.visitCount||'0' }}</span>
            </div>
            <div class="received-item">
              <span class="received-job ">BD：{{ item.followUpPerson||'--' }}丨</span><span class="received-job">顾问：{{ item.ownerUserName||'--' }}</span>
            </div>
          </div>
        </div>
      </div>
      <template #right>
        <div class="operate">
          <div class="recycle operate-w"  @click="recycleClick(item)" >
            <div class="operate-item">
                        <span >
                        移入<br/>回收站
                        </span>
            </div>
          </div>
          <div class="draw operate-w"  @click="followClick(item)">
            <div class="operate-item">
                        <span >
                        填写<br/>跟进
                        </span>
            </div>
          </div>
          <div class="distribution operate-w" @click="connectClick(item)">
            <div class="operate-item">
                        <span >
                          联系<br/>TA
                        </span>
            </div>
          </div>
        </div>
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
import IdentityTypeTem from "@/views/components/identityTypeTem";
export default {
  name: "receivedItem",
  components:{
    IdentityTypeTem
  },
  props: {
    item: {},
    isMultiple: {},
    identityType:{}
  },
  data(){
    return{
      openList:[]
    }
  },
  methods:{
    returnName(item){
      let name = '--'
      if (item.name&&item.brandNameEn){
        if (item.name==item.brandNameEn){
          name = item.name
        }else {
          name = item.name +'/'+item.brandNameEn
        }
      }else {
        name = item.name||item.brandNameEn
      }
      name = name||'--'
      return name
    },
    recycleClick(item){
      console.log(item)
      this.$emit('recycleCallBack',item)
    },
    detailClick(item){
      this.$emit('detailsItemCallBack',item)
    },
    followClick(item){
      console.log(item)
      this.$emit('followCallBack',item)
    },
    connectClick(item){
      console.log(item)
      this.$emit('connectCallBack',item)
    },
    openClick(item){
      this.openList.push(item)
    },
    closeClick(item){
      let index =  this.openList.indexOf(item)
      this.openList.splice(index,1)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/haveItem';
.received-all{
  background: #FFFFFF;
  //width: 630px;
  background: #FFFFFF;
  border-radius: 20px;
  padding:30px 30px 30px 0px;

  .received-name{
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
  }
  .received-job{
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
    margin-left: 12px;
  }
  .received-img{
    width: 32px;
    height: 32px;
    vertical-align: text-bottom;
  }
  .received-item{
    margin-top: 14px;
    vertical-align: text-top;
  }
  .received-login{
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #127EFE;
    background: rgba(0,126,255,0.09);
    margin-left: 4px;
    padding: 4px;
  }
  .received-num{
    position: absolute;
    top: 34px;
    right: 20px;
    font-size: 32px;
    display: flex;
    align-items: center;
  }
}
</style>
