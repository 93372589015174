import request from '@/utils/request'
import {NET_WORK_ENUM} from '@/api/index'

/**
 * 未合作 新 统一接口
 */

let NO_All_LIST = '/crm/v1/customer/my_customers';


/**
 * 放入公海
 * @type {string}
 */
// 未合作放入公海
let NOT_RELEASE = '/international/waters/v1/common/list_page';

/**
 * 未合作客户移交
 * @type {string}
 */
let NOT_TURN_OVER = '/international/waters/v1/common/all_receive'



/**
 * 回收站
 * @type {string}
 */
let ADD_RECYCLE = '/uc/identity/recycle/add_recycle';





export function not_release(data = {}) {
    return request({
        url: NOT_RELEASE,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
export function no_all_list(data = {}) {
    return request({
        url: NO_All_LIST,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function add_recycle(data = {}) {
    return request({
        url: ADD_RECYCLE,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}


export function not_turn_over(data = {}) {
    return request({
        url: NOT_TURN_OVER,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
