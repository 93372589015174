<template>
  <div v-if="!empty" class="image-wrap">
    <img
        v-for="(item, index) in imgList"
        :key="index"
        class="img-all"
        :src="item"
        :onerror="defaultImg"
        alt=""
        @click="imagePreview(index)"
        :style="{width: width ? `${width}px` : '100%', height: width ? `${width}px`:'100%', borderRadius: borderRadius ? `${borderRadius}px` : ''}"
    />
  </div>
  <!--  处理传入为空的图片-->
  <div v-else class="image-wrap">
    <img
        class="img-all"
        :src="emptyImg"
        alt=""
        :style="{width: width ? `${width}px` : '100%', height: width ? `${width}px`:'100%', borderRadius: borderRadius ? `${borderRadius}px` : ''}"
    />
  </div>
</template>
<script>
import emptyImg from '@/assets/image/err.png'
import {ImagePreview} from 'vant';
import {cloneDeep} from 'lodash'
import Signature from "@/utils/signature";

export default {
  props: {
    isSimple: { // 是否单张
      type: Boolean,
      default: true
    },
    showIndex:{
      type: Boolean,
      default: true
    },
    width:{
      type: Number,
      default: 0
    },
    // 默认图片
    initImageList: {
      type: [String, Array],
      default: () => []
    },
    borderRadius: {
      type: Number,
      default: 0
    },
    // 是否可以预览图片
    isPreview: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      emptyImg: emptyImg,
      defaultImg: 'this.src="' + require('@/assets/image/err.png') + '"',
      imgList: [],
      imgListSuffix: [],
      isFirst: false,
      empty: true
    }
  },
  watch: {
    initImageList: {
      handler(value) {
        if (value) {
          this.empty = false
          if (!this.isFirst) { // 初始化的时候执行 只执行一次
            this.isFirst = true
            this.imgListSuffix = []
            this.imgList = []
            /**
             * 跟服务端定义好
             * 单张传字符串
             * 多张传数组
             * */
            if(!this.isSimple && typeof value === 'string') { // 后端图片数组可能以字符串形式返回
              value = JSON.parse(value)
            }
            value = value instanceof Array ? value : [value]
            if (value.length !== 0){
              value.forEach((item) => {
                this.renderImage(item)
              })
              this.imgListSuffix = cloneDeep(value)
            }
          }
        } else {
          this.empty = true
        }
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    // 图片预览
    imagePreview(index) {
      if(!this.isPreview) return
      ImagePreview({
        showIndex:this.showIndex?false:true,
        images: this.imgList,
        startPosition: index
      })
    },
    async renderImage(path) {
      if (path.length !== 0 ) {
        const Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
        const objExp = new RegExp(Expression)
        // 是否以http开头
        if (objExp.test(path)) {
          this.imgList.push(path)
          this.imgListSuffix.push(path)
          return
        }
        const signature = Signature.getInstance()
        const client = await signature.get()
        const url = client.signatureUrl(path);
        this.imgList.push(url)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.image-wrap {
  display: flex;
  flex-wrap: wrap;
}
.img-all {
  //object-fit: scale-down;
}
</style>
