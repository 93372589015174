<template>
  <div class="wrap">
      <div class="time" >{{query.assignStartTime.split(' ')[0]}}<span v-if="query.assignStartTime && query.assignStartTime">至</span>{{query.assignEndTime.split(' ')[0]}}客户总数</div>
      <div class="middleBar"> <span class="text">{{totalNum}}条数据</span></div>
      <div class="infoWrap" ref="list" @scroll="alive">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-checkbox-group ref="checkboxGroup">
            <div
              class="swipe"
              v-for="item in list" :key="item.id"
              >
              <div class="item" @click="jump(item)">
                <div class="identity"><span :class="item.identity">{{item.identityName}}</span>{{item.name || '- -'}}</div>
                <div class="special" v-if="item[showOptions[item.identity || 'nothing'].specialValue]"><img :src="showOptions[item.identity|| 'nothing'].specialSrc" /> {{showOptions[item.identity || 'nothing'].specialField}}:{{item[showOptions[item.identity || 'nothing'].specialValue]  || '--'}}</div>
                <div class="identification">
                  <userTypeCom :item="item" />
                </div>
                <div><img src="../../assets/image/icon/time.png" /> 跟进天数:{{item.days}}</div>
                <div><img src="../../assets/image/icon/time.png" /> 跟进次数:{{item.visitCount}} <span class="housekeeper">{{item.ownerUserName}}</span></div>
                <div class="person">
                  <img src="../../assets/image/icon/person.png" />{{item.contactNumber||0}}
                </div> 
              </div>
            </div>
          </van-checkbox-group>
        </van-list>
      </div>
  </div>
</template>

<script>
import {dicType} from "@/api/common";
import {get_expand_dept_page} from '@/api/chart'
import userTypeCom from "@/views/components/userType";
export default {
  components:{
    userTypeCom
  },
  data() {
    return {
      list:[],
      showOptions:{
        'USER_LABEL_IDENT_RETAILER':{
          specialField:'地域',
          specialValue:'provinceName',
          specialSrc: require('@/assets/image/icon/position.png'),
        },
        'USER_LABEL_IDENT_BRAND':{
          specialField:'品牌',
          specialValue:'name',
          specialSrc: require('@/assets/image/icon/brand.png'),
        },
        'USER_LABEL_IDENT_RED_ORG':{
          specialField:'签约红人',
          specialValue:'netRedNum',
          specialSrc: require('@/assets/image/icon/red.png'),
        },
        'USER_LABEL_IDENT_RED':{
          specialField:'粉丝数',
          specialValue:'followers',
          specialSrc: require('@/assets/image/icon/mcn.png'),
        },
        'USER_LABEL_IDENT_SERVICE_PROVIDER':{
          specialField:'服务商类型',
          specialValue:'serviceTypeName',
          specialSrc: require('@/assets/image/icon/service.png'),
        },
        'USER_LABEL_IDENT_FACTORY':{
          specialField:'工厂类型',
          specialValue:'factoryTypeName',
          specialSrc: require('@/assets/image/icon/service.png'),
        },
        'nothing':{
          specialField:'地域',
          specialValue:'provinceName',
          specialSrc: require('@/assets/image/icon/position.png'),
        },
      },
      scrollPages: 0, // 缓存当前滚动条的位置
      value: '',
      loading: false,
      finished: false,
      params:{
        pageNum:1,
        pageSize:10,
      },
      totalNum:0,
      options:[],
      queryItems:[
        // {prop: 'platformCode', label: '所在平台', value: '',options:[],close:true },
      ],
      assignStartTime:'',
      assignEndTime:'',
      ownerUserIdList:[],
      query:{}
    };
  },
  created(){
    const {ownerUserIdList} = this.$route.query
    this.query = {...this.$route.query,ownerUserIdList:ownerUserIdList && JSON.parse(ownerUserIdList) || []}
  },
  activated() {
    // 缓存组件激活时调用
    this.$refs.list.scrollTop = this.scrollPages; // 获取 dom 的scrollTop = 缓存的滚动条位置
  },
  methods:{
    alive(e) {
     // 获取页面滚动条的位置
      this.scrollPages = e.target.scrollTop; 
    },
    jump(item){
      let queryData ='?id=' + item.userId+'&identity='+item.identity + (item.identity == 'USER_LABEL_IDENT_BRAND' ? ('&brandId=' + item.strategyId) :'')//品牌身份多传个品牌id
      if (item.userType == 4) {
        this.$router.push({
          path: '/manual_details'+queryData
        })
      } else {
        switch (item.identity) {
          case 'USER_LABEL_IDENT_RETAILER':
            this.$router.push({
              path: '/shop_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_BRAND':

            this.$router.push({
              path: '/brand_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_RED':
            this.$router.push({
              path: '/favourite_details' +queryData
            })
            break;
          case 'USER_LABEL_IDENT_RED_ORG':
            this.$router.push({
              path: '/mcn_details'+queryData
            })
            break;
          case 'USER_LABEL_IDENT_FACTORY':
            this.$router.push({
              path: '/factory_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_SERVICE_PROVIDER':
            this.$router.push({
              path: '/server_details' + queryData
            })
            break;
        }
      }
    },
    onLoad() {
      console.log('到底了');
      this.getList()
    },
    async getList(){
      this.params = {...this.params,...this.query}
      // this.params.assignEndTime = this.assignEndTime
      // this.params.ownerUserIdList = this.ownerUserIdList
       const res = await get_expand_dept_page(this.params)
       this.list.push(...res.records)
       this.params.pageNum++
       this.loading = false
       this.totalNum = res.totalNum
        if (res && this.list.length >= res.totalNum) { 
          this.finished = true
        }
        console.log(res)
    },
   init(){
      this.params.pageNum = 1
      this.list = []
      this.checkedList = []
      this.finished = false;
      this.loading = true;
    },
    onSearch(){
      this.init()
      this.onLoad();
    },
    // 筛选
  }
}
</script>

<style lang="scss" scoped>
@import "@/views/customerSea/css/list";
.wrap{
  .time{
    width: 690px;
    height: 92px;
    background: #FFFFFF;
    margin: 30px 30px 20px;
    text-align: center;
    border-radius: 20px;
    font-size: 32px;
    font-weight: 400;
    color: #333333;
    line-height: 92px;
  }
  .middleBar{
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    line-height: 1;
    font-size: 28px;
    .text{
      color: #999999;
    }
  }
}

.swipe{
  .item{
    padding: 20px 30px;
    div{
      display: flex;
      align-items: center;
      font-size: 28px;
      color: #888888;
      &.identity{
        margin-bottom: 22px;
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        span {
          padding: 0 12px;
          height: 36px;
          line-height: 36px;
          margin-right: 10px;
          font-size: 24px;
          border-radius: 10px;
          text-align: center;
        }
        .USER_LABEL_IDENT_RETAILER{
          color: #FD953E;
          background: #FFF1DE;
        }
        .USER_LABEL_IDENT_BRAND{
          color: #FF8F19;
          background: #FFF1DE;
        }
        .USER_LABEL_IDENT_RED_ORG{
          color: #4484FF;
          background: #ECF2FF;
        }
        .USER_LABEL_IDENT_RED{
          color: #FD3E3E;
          background: #FDEEEC;
        }
        .USER_LABEL_IDENT_SERVICE_PROVIDER{
          color: #00BE21;
          background: #E9F8F3;
        }
        .USER_LABEL_IDENT_FACTORY{
          color: #2683FF;
          background: #EFF2FE;
        }
      }
      &.special{
        margin-bottom: 10px;
      }
      &.identification{
        margin-bottom: 10px;
        span{
          font-size: 28px;
        }
      }
      .housekeeper{
        margin-left: 22px;
        color: #333333;
      }
      &.person{
        position: absolute;
        right: 30px;
        top: 26px;
        font-size: 32px;
        font-weight: bold;
        color: #333333;
      }
      img{
        width: 30px;
        height: 30px;
        margin-right: 16px;
      }
    }
  }
}
</style>
