import request from '@/utils/request'
import { NET_WORK_ENUM } from '@/api/index'
// 联系人列表
export function contact_list(data = {}) {
    return request({
        url: '/crm/docking/people/v1/list_by_page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
// 联系人查重
export function contact_check(data = {}) {
    return request({
        url: '/crm/docking/people/v1/cnki_contact',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
// 联系人查询名称
export function contact_name(data = {}) {
    return request({
        url: '/international/waters/v1/common/list_page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
// 绑定联系人
export function contact_bind(data = {}) {
    return request({
        url: '/crm/docking/people/v1/bind_contact_user',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
// 解决绑定联系人
export function contact_un_bind(data = {}) {
    return request({
        url: '/crm/docking/people/v1/un_bind',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
// 删除联系人
export function contact_delete(data = {}) {
    return request({
        url: '/crm/docking/people/v1/del_contact',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
