//活动数据
const signInfomation = () => import('@/views/activityData/signInfomation')//负责人
const myVerification = () => import('@/views/activityData/myVerification')//核销人
const historyInfomation = () => import('@/views/activityData/historyInfomation')//历史数据
const detailList = () => import('@/views/activityData/detailList')//明细列表
const phoneSearch = () => import('@/views/activityData/phoneSearch')//手机号查找
const signResult = () => import('@/views/activityData/signResult')//签到结果

const activityDataRouter = [
  {
      path: '/sign_infomation',
      name: 'SignInfomation',
      component: signInfomation,
      meta: {
          keepAlive: false,
          title: "活动数据-负责人",
      },
    },
    {
      path: '/my_verification',
      name: 'MyVerification',
      component: myVerification,
      meta: {
          keepAlive: false,
          title: "我的核销清单",
      },
    },
    {
      path: '/history_infomation',
      name: 'HistoryInfomation',
      component: historyInfomation,
      meta: {
          keepAlive: false,
          title: "历史大会数据对比",
      },
    },
    {
      path: '/detail_list',
      name: 'DetailList',
      component: detailList,
      meta: {
          keepAlive: false,
          title: "明细列表",
      },
    },
    {
      
      path: '/phone_search',
      name: 'PhoneSearch',
      component: phoneSearch,
      meta: {
          keepAlive: false,
          title: "手机号查找",
      },
    },
    {
      path: '/sign_result',
      name: 'SignResult',
      component: signResult,
      meta: {
          keepAlive: false,
          title: "签到结果",
      },
    },
]
export default activityDataRouter