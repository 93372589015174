import request from '@/utils/request'
import { NET_WORK_ENUM } from '@/api/index'

// 品牌
export function brandPage(data = {}) {
    return request({
        url: '/international/waters/v1/brand/page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function brandReceive(data = {}) {
    return request({
        url: '/international/waters/v1/brand/receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 工厂
export function factoryPage(data = {}) {
    return request({
        url: '/international/waters/v1/factory/page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function factoryReceive(data = {}) {
    return request({
        url: '/international/waters/v1/factory/receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 服务商
export function servicePage(data = {}) {
    return request({
        url: '/international/waters/v1/service/page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function serviceReceive(data = {}) {
    return request({
        url: '/international/waters/v1/service/receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 红人
export function redPage(data = {}) {
    return request({
        url: '/international/waters/v1/red/page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function redReceive(data = {}) {
    return request({
        url: '/international/waters/v1/red/receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 机构
export function redOrgPage(data = {}) {
    return request({
        url: '/international/waters/v1/red_org/page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function redOrgReceive(data = {}) {
    return request({
        url: '/international/waters/v1/red_org/receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 零售商
export function retailersPage(data = {}) {
    return request({
        url: '/international/waters/v1/retailers/page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

export function retailersReceive(data = {}) {
    return request({
        url: '/international/waters/v1/retailers/receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 跟进列表查询
 * @param data
 * @returns {*}
 */
export function getVisitRecordList(data = {}) {
    return request({
        url: '/crm/v1/sea/visit/record/list_by_page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 填写跟进记录
 * @param data
 * @returns {*}
 */
export function saveVisitRecordInfo(data = {}) {
    return request({
        url: '/crm/v1/sea/visit/record/save_info',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 获取部门用户
 * @param data
 * @returns {*}
 */
export function getDepartmentUser(data = {}) {
    return request({
        url: '/admin/user/get_depart_user_list',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 获取跟进记录详情
 * @param data
 * @returns {*}
 */
export function getVisitRecordDetail(data = {}) {
    return request({
        url: '/crm/v1/sea/visit/record/detail_by_id',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 回收站-放入回收站
 * @param data
 * @returns {*}
 */
export function add_recycle(data = {}) {
    return request({
        url: '/uc/identity/recycle/add_recycle',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 【客保/BA】 公海 统一接口(分页)
export function list_page(data = {}) {
    return request({
        url: '/international/waters/v1/common/list_page',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 客户公海领取/分配
export function receive(data = {}) {
    return request({
        url: '/international/waters/v1/common/receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

// 大客户公海领取/分配
export function all_receive(data = {}) {
    return request({
        url: '/international/waters/v1/common/all_receive',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
