const brand = () => import('@/views/recordCustomer/brand')

const recordCustomerRouter = [
	{
		path: '/brand_record_customer',
		name: 'BrandRecordCustomer',
		component: brand,
		meta: {
			keepAlive: true,
			title: "品牌",
		}
	}
]
export default recordCustomerRouter
