import request from '@/utils/request'

// 上传
export function getSignature(data) {
  return request({
    url: '/file/v2/get/app/policy',
    method: 'post',
    data,
    GATE: '/api/yms/oss'
  })
}
