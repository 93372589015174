import Vue from 'vue'
import VueRouter from 'vue-router'
import detailsRouter from './details/index'
import shareRouter from './share'

import customerRouter from "./customerRouter";
import customerSeaRouter from "./customerSeaRouter";
import gadgetsRouter from "./gadgets";

import haveAndNotCooperatingRouter from "@/router/haveAndNotCooperating";
import guestRouter from "@/router/guest";
import activityDataRouter from "@/router/activityData";
import bdPersonRouter from "@/router/bdPerson";
import contactRouter from '@/router/contact'
import chartRouter from '@/router/chart'
import recordCustomer from '@/router/recordCustomer'

Vue.use(VueRouter)
let routerConfig = [
	{
		path: '/',
		name: 'Index',
		component: () => import('@/views/index'),
		meta: {
			title: "工作台",
			keepAlive: true,
			bottomnavigation: true
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login'),
		meta: {
			title: "欢迎登录仪美尚CRM系统",
		},
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import('@/views/profile'),
		meta: {
			title: "我的",
			bottomnavigation: true
		},
	},
	{
		path: '/test_page',
		name: 'testPage',
		component: () => import('@/views/testPage'),
		meta: {
			title: "测试页面"
		},
	},
	...bdPersonRouter, // bd人员专用
	...detailsRouter, // 详情相关路由
	...customerRouter, // 客户路由
	...shareRouter, // 分享路由
	...customerSeaRouter, //公海路由
	...haveAndNotCooperatingRouter,// 已合作和未合作客户
	...customerSeaRouter, //公海路由
	...gadgetsRouter, // 常用工具路由
	...guestRouter,//嘉宾路由
	...activityDataRouter,//活动路由
	...contactRouter,// 联系人
	...recordCustomer, // 录入客户
	...chartRouter//图表
]
const router = new VueRouter({
	routes: routerConfig,
	scrollBehavior() {
		return {x: 0, y: 0}
	}
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	if (!from.name) {
		sessionStorage.removeItem('backRouter')
	}
	if (to.name === 'Login' && from.name === 'GuestList' && !localStorage.getItem('TOKEN')) {
		sessionStorage.setItem('backRouter', from.fullPath)
	}
	next()
})

router.onError((error) => {
	const pattern = /Loading chunk (\d)+ failed/g
	const isChunkLoadFailed = error.message.match(pattern)
	const targetPath = router.history.pending.fullPath
	if (isChunkLoadFailed) {
		router.replace(targetPath)
	}
	window.location.reload()
})


export default router
