<!--
 * @FilePath: /web-yms-customer-h5/src/views/customer/components/datetimePickerPop.vue
    @Description: 时间弹窗
    @Author: lyh
-->
<template>
  <div v-if="isShow">
    <van-overlay :show="isShow" @click="clickClose" />
    <van-datetime-picker
        class="date-picker"
        v-model="time"
        type="date"
        title="走访时间"
        :max-date="maxDate"
        @confirm="clickConfirm"
        @cancel="clickClose"
    />
  </div>
</template>

<script>
import timeFormat from "@/utils/timeFormat";

export default {
  name: "datetimePickerPop",
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
    isShow: {
      default: false
    },
    value: {
      default: String
    }
  },
  data() {
    return {
      time: new Date(),
      timeLock: false
    }
  },
  
  watch: {
    value: {
      immediate: true,
      handler(newData) {
        if (newData) {
          if (!this.timeLock) {
            this.time = new Date(newData.replaceAll('-', '/')) // ios手机不支持有'yyyy-MM-dd hh:mm:ss'格式的时间, 需要转换为'yyyy/MM/dd hh:mm:ss'
            this.clickConfirm()
            this.timeLock = true
          }
        }
      }
    }
  },
  computed: {
    maxDate() {
      let date = ''
      if (this.isShow) {
        date = new Date()
      }
      return date
    }
  },
  created(){
    if(this.isNew){
      this.time = new Date()
      this.clickConfirm()
    }
  },
  methods: {
    /**
     * 点击关闭弹窗
     */
    clickClose() {
      this.$emit('clickClose')
    },
    /**
     * 点击确认
     */
    clickConfirm() {
      console.log(this.time, '阿拉拉')
      const value = timeFormat(this.time, 'yyyy-MM-dd hh:mm:ss')
      const label = timeFormat(this.time, 'yyyy-MM-dd')
      this.$emit('clickConfirm', {value, label})
    }
  }
}
</script>

<style scoped lang="scss">
.date-picker {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
</style>
