import request from '@/utils/request'
import {NET_WORK_ENUM} from '@/api/index'

// 数据字典
let DIC_TYPE = '/dict/v2/detail';
// 省市区
let QUERY_ADDRESS = '/area/v1/query';
// 类目列表
let CATEGORY = '/sys/category/v1/get_list';
// 查询部门员工
let GET_DEPARTMENT_USER_BY_NAME = '/department/v1/get_department_user_by_name';

// 查询多个BD部门员工
let GET_DEPART_USER_LIST = '/admin/user/get_depart_user_list';
let PROVINCE_LIST = '/area/v1/query';
let SELECT_ALL_CATEGORY = '/ps_category/v1/select_all_category';


// 公海-五大身份 领取/分配BD/移交
 let ALL_RECEIVE='/international/waters/v1/common/all_receive'

export function all_receive(data = {}) {
    return request({
        url: ALL_RECEIVE,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
export function get_depart_user_list(data = {}) {
    return request({
        url: GET_DEPART_USER_LIST,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
export function select_all_category(data = {}) {
    return request({
        url: SELECT_ALL_CATEGORY,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE_GOODS,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 查询部门员工
 * */
export function get_department_user_by_name(data = {}) {
    return request({
        url: GET_DEPARTMENT_USER_BY_NAME,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 获取全部省份的接口
 * */
export function province_list(data = {}) {
    return request({
        url: PROVINCE_LIST,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 类别
 * */
export function category(data = {}) {
    return request({
        url: CATEGORY,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 数据字典
 * */
export function dicType(data = {}) {
    return request({
        url: DIC_TYPE,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}

/**
 * 获取域名
 */
export function req_project_domain(data) {
    return request({
        url: '/web/public/network/v1/visit',
        method: 'post',
        data
    })
}

/**
 * 获取微信签名
 * */
// export function getWechatConfig(data = {}) {
//     return request({
//         url: '/wechat/enterprise/user/jssdk/config',
//         method: 'post',
//         data,
//         GATE: NET_WORK_ENUM.GATE,
//         mock: NET_WORK_ENUM.MOCK
//     })
// }

/**
 * 获取微信签名
 * */
export function getWechatConfigWx(data = {}) {
    return request({
        url: '/wechat/user/jssdk/config',
        method: 'post',
        data: { jsonData: encodeURIComponent(window.location.href.split("#")[0]) },
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 *
 * */
export function queryAddress(data = {}) {
    return request({
        url: QUERY_ADDRESS,
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
/**
 * 获取类目
 * */
export function getCategoryList(data = {}) {
    return request({
        url: '/sys/category/v1/get_list',
        method: 'post',
        data,
        GATE: NET_WORK_ENUM.GATE,
        mock: NET_WORK_ENUM.MOCK
    })
}
