<template>
<span>
  <span class="login-type yellow" v-if="identity=='USER_LABEL_IDENT_RETAILER'">零售商</span>
  <span class="login-type yellow" v-if="identity=='USER_LABEL_IDENT_BRAND'">品牌</span>
  <span class="login-type red" v-if="identity=='USER_LABEL_IDENT_RED'">红人</span>
  <span class="login-type blue" v-if="identity=='USER_LABEL_IDENT_RED_ORG'">机构</span>
  <span class="login-type blue" v-if="identity=='USER_LABEL_IDENT_FACTORY'">工厂</span>
  <span class="login-type green" v-if="identity=='USER_LABEL_IDENT_SERVICE_PROVIDER'">服务商</span>
</span>
</template>

<script>
export default {
  name: "identityTypeTem",
  props:{
    identity:{
      type:String,
      default:''
    }
  }
}
</script>

<style scoped lang="scss">
.login-type{
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  padding: 4px;
  margin-right: 10px;
  margin-left: 10px;
}
.blue{
  color: #2683FF;
  background: #EFF2FE;;
}
.yellow{
  color: #FF8F19;
  background: #FFF1DE;
}
.green{
  color: #00BE21;
  background: #E9F8F3;
}
.red{
  color: #FD3E3E;
  background: #FDEEEC;
}
</style>
