<!--
    @FilePath: src\views\customer\relatedCustomer.vue
    @Description: 关联客户
    @Author: lyh
-->

<template>
  <div class="container">
    <input class="phone-input"
           type="text"
           v-model="phone"
           placeholder="请输入系统注册手机号"
           onkeyup="value = value.replace(/[^\d]/g, '')"
           maxlength=11>
    <div class="tip">注：仅可用于绑定已注册用户，未注册请使用分享激活功能。</div>
    <van-button class="relate-btn" type="info" @click="clickRelated">关联</van-button>
    <Pop :is-show="isShow" :phone="phone" @clickClose="clickClose"/>
  </div>
</template>

<script>
import Pop from "@/views/customer/components/Pop";
import {Toast} from 'vant'

export default {
  name: "relatedCustomer",
  components: {
    Pop
  },
  data() {
    return {
      phone: '15750756633', // 注册手机号
      isShow: false
    }
  },
  methods: {
    /**
     * 点击关联
     */
    clickRelated() {
      if (this.phone) {
        let reg_phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (reg_phone.test(this.phone)) {
          this.isShow = true
        } else {
          Toast('请输入正确的手机号')
        }
      } else {
        Toast('请输入关联手机号')
      }
    },
    /**
     * 关闭弹窗
     */
    clickClose() {
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 30px;
  width: 100%;
  min-height: 100vh;
  background: #F1F4F5;
  box-sizing: border-box;
  font-size: 24px;
}

.phone-input {
  display: inline-block;
  width: 100%;
  line-height: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  padding: 0 30px;
}

.tip {
  color: #999999;
  line-height: 34px;
  text-align: center;
  padding: 30px 0 54px;
}

.relate-btn {
  width: 100%;
  border-radius: 20px;
  height: 80px;
  background-color: #4484FF;
}
</style>
