<template>
  <div class="wrap">
    <div class="middleBar">
      <span class="text">{{total}}条数据</span>
      <div class="operate">
        <span @click="filterShowClick">筛选<img src="@/assets/image/screen-icon.png"></span>
        <span class="right-40" v-if="multiple" @click="multipleClick">{{textName}}</span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props:{
    total:{
      types: String | Number,
      default:'0'
    },
    multiple:{
      types:String | Number,
      default: true
    },
    text:{
      types: String,
      default:'多选'
    }
  },
  components:{
  },
  watch:{
    text:{
      handler(newData){
        this.textName = JSON.parse(JSON.stringify(newData||this.text))
      }
    }
  },
  data() {
    return {
      textName:'多选'
    };
  },
  methods:{
    filterShowClick(){
      this.$emit('filterShowCallBack',true)
    },
    multipleClick(){
      if(this.textName === '完成') {
        this.textName = '多选'
      }else{
        this.textName = '完成'
      }
      this.$emit('multiple')
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrap{
    //height: 100%;
    //background: #F1F4F5;
  }
  .middleBar{
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    line-height: 1;
    font-size: 28px;
    .text{
      color: #999999;
    }
    .operate{
      color: #333333;
      span{
        display: inline-flex;
        &:first-child{
          //
          img{
            width: 26.71px;
            height: 25.6px;
            margin-left: 14px;
          }
        }

      }
    }
  }
  .right-40{
    margin-left: 40px;
  }
</style>
