<template>
  <div v-if="isShow">
    <van-overlay :show="isShow" @click="clickClose"/>
    <van-picker
        :show-toolbar="true"
        class="picker-option"
        title="跟进方式"
        :columns="columns"
        value-key="label"
        :default-index="defaultSelect"
        @confirm="clickConfirm"
        @cancel="clickClose"
    />
  </div>
</template>

<script>
import {dicType} from "@/api/common";

export default {
  name: "modeOptionPop",
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false
    },
    value: {
      default: String,
    },
    identity: {
      default: String,
    },
    
  },
  async created() {
    if(this.identity === 'USER_LABEL_IDENT_BRAND'){
       await this.getDicType({jsonData: [{code: 'BRAND_FOLLOW_UP'}]})
    }else{
      await this.getDicType({jsonData: [{code: 'FOLLOW_UP_MODE'}]})
    }

    if(this.isNew){
      const select = this.columns[this.defaultSelect]
      if (select) {
        this.clickConfirm(select)
        this.timeLock = true
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newData) {
        if (newData) {
          if (!this.timeLock) {
            this.defaultSelect = this.columns.findIndex((item, index) => {
              if (item.value === newData) {
                return item
              }
            })
            this.defaultSelect = this.defaultSelect === -1 ? 0 : this.defaultSelect
            const select = this.columns[this.defaultSelect]
            if (select) {
              this.clickConfirm(select)
              this.timeLock = true
            }
          }
        } 
      }
    }
  },
  data() {
    return {
      columns: [],
      timeLock: false,
      defaultSelect: 0 // 默认选中
    }
  },
  methods: {
    /**
     * 点击关闭弹窗
     */
    clickClose() {
      this.$emit('clickClose')
    },
    /**
     * 点击确认
     */
    clickConfirm(value) {
      this.$emit('clickConfirm', value)
    },
    /**
     * 获取字典
     * @param param
     * @returns {Promise<void>}
     */
    async getDicType(param) {
      const res = await dicType(param)
      this.columns = []
      if (res) {
        this.columns =(this.identity === 'USER_LABEL_IDENT_BRAND' ? res['BRAND_FOLLOW_UP']  : res['FOLLOW_UP_MODE']).map(item => {
          return {
            label: item.detailName,
            value: item.detailCode
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.picker-option {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}
</style>
