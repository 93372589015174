<template>
  <div class="yf-uploader">
    <div class="yf-uploader__list">
      <div v-for="(item, index) in imgList" :key="index" class="yf-uploader__item">
        <img :src="item" :onerror="defaultImg" alt="" class="yf-uploader__img" @click="imagePreview(index)" />
        <img :src="cancelIcon" class="yf-uploader__delete" @click="imgDel(index)">
        <img :src="checkIcon" class="yf-uploader__check" @click="imagePreview(index)">
      </div>
      <div v-if="imgList.length < limit && !disabled" class="yf-uploader__input-wrapper">
        <img class="yf-uploader__file" src="@/assets/image/img-upload.png">
        <div v-if="isApp" class="yf-uploader__input" @click="uploadImageOss"></div>
        <input
          v-else
          ref="fileInput"
          id="fileId"
          accept="image/png,image/jpg,image/jpeg"
          class="yf-uploader__input"
          type="file"
          :multiple="true"
          @change="change($event)"
        >
      </div>
    </div>
  </div>
</template>
<script>
import Cropper from 'cropperjs'
import { ImagePreview } from 'vant';
import moment from 'moment';
import {cloneDeep} from 'lodash'
import AppBridge from "@/commonjs/appBridge";
import Signature from '@/utils/signature'
import VueCookies from "vue-cookies";
import cancelIcon from '@/assets/image/cancel-icon.png'
import checkIcon from '@/assets/image/check-icon.png'
export default {
  props: {
    disabled:{
      type: Boolean,
      default: () => false,
    },
    // 默认图片
    initImageList: {
      type: [String,Array],
      default: () => []
    },
    // 限制图片张数
    limit: {
      type: Number,
      default: 6
    },
    // 定义图片比例
    aspectRatio: {
      type: Array,
      default: () => []
    },
    // 文件夹
    folder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      defaultImg: 'this.src="' + require('@/assets/image/err.png') + '"',
      isApp: false,
      filename: '',
      imgList: [],
      imgListSuffix: [],
      dialogImageUrl: '',
      dialogVisible: false,
      form: {},
      pathDir: null,
      uploadImgUrl: null,
      showBtnImg: true,
      noneBtnImg: false,
      isFirst: false,
      cancelIcon,
      checkIcon
    }
  },
  watch: {
    initImageList: {
      handler(value) {
        // 第一次进入为空数组 不执行
        if(value && !value.length) {
          return
        }
        if (value){
          if(!this.isFirst) { // 初始化的时候执行 只执行一次
            this.isFirst = true
            this.imgListSuffix = []
            this.imgList = []
            /**
             * 跟服务端定义好
             * 单张传字符串
             * 多张传数组
             * */
            if(this.limit === 1) {
              value = [value]
            }
            value.forEach((item) => {
              this.renderImage(item)
            })
            this.imgListSuffix = cloneDeep(value)
            // this.handleCallbackImgList()
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    let userAgent = window.navigator.userAgent;
    if(userAgent.indexOf('_yifeiApp_') > -1|| userAgent.search('_yifeiApp_')> -1||userAgent.indexOf('yifei') > -1|| userAgent.search('yifei')> -1){
      this.isApp =true
      // console.log('当前状态不在微信 是当前APP打开的页面')
    }
  },
  methods: {
    // 删除
    imgDel(index) {
      this.imgList.splice(index, 1)
      this.imgListSuffix.splice(index, 1)
      this.handleCallbackImgList()
    },
    // 图片预览
    imagePreview(index) {
      ImagePreview({
        images: this.imgList,
        startPosition: index
      })
    },
    // 在app里面 调用app方法
    async uploadImageOss() {
      try {
        const res = await AppBridge.upload({
          dirName: this.folder,
          mediaType: AppBridge.UPLOAD_TYPE.image,
          isCrop: !!this.aspectRatio.length,
          imageScaleWidth: this.aspectRatio.length > 1 ? this.aspectRatio[0] : '',
          imageScaleHeight: this.aspectRatio.length > 1 ? this.aspectRatio[1] : '',
        })
        this.imgList.push(res.url)
        this.imgListSuffix.push(res.objectKey)
        setTimeout(() => {
          this.handleCallbackImgList()
        })
      } catch (error) {
        console.log(error)
      }
    },
    change(event) {
      if(this.aspectRatio.length) { // 裁剪 只能上传一张
        const file = this.$refs.fileInput.files[0] // 获取文件流
        const isImgType = file.type.includes('image')
        const isLt20M = file.size / 1024 / 1024 < 20
        this.filename = file.name
        if (!isImgType) {
          this.$toast('只能上传图片类型')
          return false
        }
        if (!isLt20M) {
          this.$toast('上传图片大小不能超过 20MB!')
          return false
        }
        // 显示裁剪框
        this.clip(event, {})
      } else {
        const files = this.$refs.fileInput.files
        Object.values(files).forEach((item) => {
          const isImgType = item.type.includes('image')
          const isLt20M = item.size / 1024 / 1024 < 20
          this.filename = item.name
          if (!isImgType) {
            this.$toast('只能上传图片类型')
            throw Error();
          }
          if (!isLt20M) {
            this.$toast('上传图片大小不能超过 20MB!')
            throw Error();
          }
          this.postImg(item)
        })
      }
    },
    // 初始化方法
    initialize(opt) {
      const self = this
      this.options = opt
      // 创建dom
      this.createElement()
      // 初始化裁剪对象
      /**
       * aspectRatio为数组
       * 有设置aspectRatio
       *
       * */
      let aspectRatio = NaN
      let cropBoxResizable = true
      if (this.aspectRatio.length > 1) {
        const width = this.aspectRatio[0]
        const height = this.aspectRatio[1]
        if (width === 1 && height === 1) { // 为1:1比例
          cropBoxResizable = true
        } else {
          // 固定宽高 不允许拖动来调整剪裁框的大小
          cropBoxResizable = false
        }
        aspectRatio = width / height
      }
      const options = {
        // aspectRatio: opt.aspectWithRatio / opt.aspectHeightRatio, // 裁剪框比例  默认NaN   例如：: 1 / 1,//裁剪框比例 1：1
        aspectRatio: aspectRatio, // 裁剪框比例  默认NaN   例如：: 1 / 1,//裁剪框比例 1：1
        // aspectRatio: 1/1,
        autoCropArea: opt.autoCropArea || 1,
        viewMode: 2, // 裁剪方式
        guides: true, // 是否在剪裁框上显示虚线
        cropBoxResizable: cropBoxResizable, // 是否通过拖动来调整剪裁框的大小
        cropBoxMovable: true, // 是否通过拖拽来移动剪裁框。
        dragCrop: true,
        dragMode: 'move', // ‘crop’: 可以产生一个新的裁剪框3 ‘move’: 只可以移动3 ‘none’: 什么也不处理
        center: false, // 是否显示裁剪框中间的+
        zoomable: true, // 是否允许放大图像。
        zoomOnTouch: true, // 是否可以通过拖动触摸来放大图像。
        scalable: true, // 是否允许缩放图片
        // minCropBoxWidth: 750,
        // minCropBoxHeight: 306,
        background: false, // 容器是否显示网格背景
        checkOrientation: true,
        checkCrossOrigin: true,
        zoomOnWheel: false, // 是否允许鼠标滚轴缩放图片
        toggleDragModeOnDblclick: false,
        ready: function() {
          if (opt.aspectRatio === 'Free') {
            const cropBox = self.cropper.cropBox
            cropBox.querySelector('span.cropper-view-box').style.outline =
              'none'
            self.cropper.disable()
          }
        },
      }
      this.cropper = new Cropper(this.preview, options)
    },
    // 创建一些必要的DOM，用于图片裁剪
    createElement() {
      // 初始化图片为空对象
      this.preview = null
      // <img src="../../assets/app/loading.gif">
      let str =
        '<div><img id="clip_image" src="originUrl"></div><button type="button" id="cancel_clip">取消</button><button type="button" id="clip_button">确定</button>'
      str +=
        '<div class="crop_loading"><div class="crop_content"><div class="crop_text">修剪中...</div></div></div>'
      str +=
        '<div class="crop_success"><div class="crop_success_text">上传成功</div></div></div>'

      const body = document.getElementsByTagName('body')[0]
      this.reagion = document.createElement('div')
      this.reagion.id = 'clip_container'
      this.reagion.className = 'container'
      this.reagion.innerHTML = str
      // 添加创建好的DOM元素
      body.appendChild(this.reagion)
      this.preview = document.getElementById('clip_image')

      // 绑定一些方法
      this.initFunction()
    },
    // 初始化一些函数绑定
    initFunction() {
      const self = this
      this.clickBtn = document.getElementById('clip_button')
      this.cancelBtn = document.getElementById('cancel_clip')
      // 确定事件
      this.addEvent(this.clickBtn, 'click', function() {
        self.crop()
      })
      // 取消事件
      this.addEvent(this.cancelBtn, 'click', function() {
        self.destroyed()
      })
      // 清空input的值
      this.addEvent(this.fileObj, 'click', function() {
        this.value = ''
      })
    },
    // 外部接口，用于input['file']对象change时的调用
    clip(e, opt) {
      this.fileObj = e.srcElement
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return false // 不是图片直接返回
      // 调用初始化方法
      this.initialize(opt)

      // 获取图片文件资源
      this.picValue = files[0]

      // 调用方法转成url格式
      this.originUrl = this.getObjectURL(this.picValue)

      // 每次替换图片要重新得到新的url
      if (this.cropper) {
        this.cropper.replace(this.originUrl)
      }
    },
    // 图片转码方法
    getObjectURL(file) {
      let url = null
      if (window.createObjectURL !== undefined) {
        // basic
        url = window.createObjectURL(file)
      } else if (window.URL !== undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file)
      } else if (window.webkitURL !== undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
      }
      return url
    },
    // 点击确定进行裁剪
    crop() {
      const self = this
      const image = new Image()
      let croppedCanvas
      let roundedCanvas

      // Crop
      document.querySelector('.crop_loading').style.display = 'block'

      setTimeout(() => {
        /**
          width: 750, // 输出画布的目标宽度
          height: 360, // 输出画布的目标高度。
          minWidth: 236, // 输出画布的最小目标宽度，默认值为0。
          minHeight: 146, // 输出画布的最小目标高度，默认值为0。
          maxWidth: 236, // 输出画布的最大目标宽度，默认值为Infinity(无穷大)。
          maxHeight: 146// 输出画布的最大目标高度，默认值为Infinity(无穷大)。
         */
        const options = {}
        if (this.aspectRatio.length > 1 && this.aspectRatio[0] !== 1) {
          options.width = this.aspectRatio[0]
          options.height = this.aspectRatio[1]
        }
        if (this.aspectRatio.length > 1) {
          croppedCanvas = this.cropper.getCroppedCanvas(options)
        } else {
          croppedCanvas = this.cropper.getCroppedCanvas()
        }

        // Round
        roundedCanvas = this.getRoundedCanvas(croppedCanvas)
        const imgData = roundedCanvas.toDataURL()
        image.src = imgData
        // 判断图片是否大于100k,不大于直接上传，反之压缩
        if (imgData.length < 100 * 1024) {
          const file = this.base64toFile(imgData, this.filename)
          // 图片上传
          this.postImg(file)
        } else {
          image.onload = () => {
            // 压缩处理
            const file = this.base64toFile(imgData, this.filename)
            // 图片上传
            this.postImg(file)
          }
        }
        // 调用后台接口，传参
        const params = {
          file: document.getElementById('fileId').files[0] // 获取文件流
        }
        self.$emit('after-upload', params) // 调用后台接口
      }, 20)
    },
    // base64转为普通文件格式
    base64toFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const suffix = mime.split('/')[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    // 获取裁剪图片资源
    getRoundedCanvas(sourceCanvas) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const width = sourceCanvas.width
      const height = sourceCanvas.height

      canvas.width = width
      canvas.height = height

      context.imageSmoothingEnabled = true
      context.drawImage(sourceCanvas, 0, 0, width, height)
      context.globalCompositeOperation = 'destination-in'
      context.beginPath()
      context.rect(0, 0, width, height)
      context.fill()

      return canvas
    },
    // 销毁原来的对象
    destroyed() {
      // 移除事件
      this.clickBtn && this.removeEvent(this.clickBtn, 'click', null)
      this.cancelBtn && this.removeEvent(this.cancelBtn, 'click', null)
      this.fileObj && this.removeEvent(this.fileObj, 'click', null)
      // 移除裁剪框
      this.reagion && this.reagion.parentNode.removeChild(this.reagion)
      // 销毁裁剪对象
      this.cropper && this.cropper.destroy()
      this.cropper = null
    },
    // 图片上传
    postImg(file) {
      this.$loading.show()
      this.getSignature(file)
      this.destroyed()

      // window.setTimeout( function () {
      //   document.querySelector('.crop_loading').style.display = 'none';
      //   document.querySelector('.crop_success').style.display = 'block';
      //   //裁剪完后摧毁对象
      //     self.destroyed();
      // },3000)
    },
    async getSignature(file) {
      const signature = Signature.getInstance()
      const client = await signature.get()
      const year = moment().format('YYYY')
      const month = moment().format('MM')
      const day = moment().format('DD')
      const timestamp = moment().valueOf()
      const userId = VueCookies.get('userId') || 'userId'
      const path = `${this.folder}/${year}/${month}/${day}/${userId}_${timestamp}_${this.filename}`
      try {
        const result = await client.put(path, file);
        const url = client.signatureUrl(path);
        this.imgList.push(url)
        this.imgListSuffix.push(path)
        this.handleCallbackImgList()
        this.$loading.hide()
      } catch (e) {
        this.$loading.hide()
        console.log(e);
      }
    },
    handleCallbackImgList() {
      this.imgListSuffix = this.imgListSuffix || []
      /**
       * 回调
       * 单张传字符串
       * 多张传数组
       * */
      const imgList = this.limit === 1 ? this.imgListSuffix[0] : this.imgListSuffix
      this.$emit('handleGetImgList', imgList)
    },
    async renderImage(path) {
      const signature = Signature.getInstance()
      const client = await signature.get()
      const url = client.signatureUrl(path);
      this.imgList.push(url)
    },
    // 图片旋转
    rotateImg(img, direction, canvas) {
      // 最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0
      const max_step = 3
      if (img == null) return
      // img的高度和宽度不能在img元素隐藏后获取，否则会出错
      const height = img.height
      const width = img.width
      let step = 2
      if (step == null) {
        step = min_step
      }
      if (direction == 'right') {
        step++
        // 旋转到原位置，即超过最大值
        step > max_step && (step = min_step)
      } else {
        step--
        step < min_step && (step = max_step)
      }
      // 旋转角度以弧度值为参数
      const degree = (step * 90 * Math.PI) / 180
      const ctx = canvas.getContext('2d')
      switch (step) {
        case 0:
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0)
          break
        case 1:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, 0, -height)
          break
        case 2:
          canvas.width = width
          canvas.height = height
          ctx.rotate(degree)
          ctx.drawImage(img, -width, -height)
          break
        case 3:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, -width, 0)
          break
      }
    },
    // 图片压缩
    compress(img, Orientation) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 瓦片canvas
      const tCanvas = document.createElement('canvas')
      const tctx = tCanvas.getContext('2d')
      const initSize = img.src.length
      let width = img.width
      let height = img.height

      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio
      if ((ratio = (width * height) / 4000000) > 1) {
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
      } else {
        ratio = 1
      }
      canvas.width = width
      canvas.height = height
      //        铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // 如果图片像素大于100万则使用瓦片绘制
      let count
      if ((count = (width * height) / 1000000) > 1) {
        count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
        //            计算每块瓦片的宽和高
        const nw = ~~(width / count)
        const nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(
              img,
              i * nw * ratio,
              j * nh * ratio,
              nw * ratio,
              nh * ratio,
              0,
              0,
              nw,
              nh
            )
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height)
      }
      // 修复ios上传图片的时候 被旋转的问题
      if (Orientation !== '' && Orientation !== 1) {
        switch (Orientation) {
          case 6: // 需要顺时针（向左）90度旋转
            this.rotateImg(img, 'left', canvas)
            break
          case 8: // 需要逆时针（向右）90度旋转
            this.rotateImg(img, 'right', canvas)
            break
          case 3: // 需要180度旋转
            this.rotateImg(img, 'right', canvas) // 转两次
            this.rotateImg(img, 'right', canvas)
            break
        }
      }
      // 进行最小压缩
      // let ndata = canvas.toDataURL( 'image/jpeg' , 0.1);
      const ndata = canvas.toDataURL('image/png', 0.1)
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0

      return ndata
    },
    // 添加事件
    addEvent(obj, type, fn) {
      if (obj.addEventListener) {
        obj.addEventListener(type, fn, false)
      } else {
        obj.attachEvent('on' + type, fn)
      }
    },
    // 移除事件
    removeEvent(obj, type, fn) {
      if (obj.removeEventListener) {
        obj.removeEventListener(type, fn, false)
      } else {
        obj.detachEvent('on' + type, fn)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.yf-uploader {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    position: relative;
    margin-right: 20px;
  }
  &__file {
    width: 100px;
    height: 100px;
  }
  &__img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: scale-down;
    margin-bottom: 10px;
    border: 1px solid #e5e5e5;
  }
  &__delete {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 28px;
    height: 28px;
  }
  &__check {
    position: absolute;
    right: 0px;
    top: 0px;
    left: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    margin: auto;
  }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    z-index: 99;
  }
  &__input-wrapper {
    position: relative;
  }
}
.zyg-uploader__upload {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  margin: 0 8px 8px 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}

.zyg-uploader__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  z-index: 99;
}

.zyg-image__img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

#zyg-upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#zyg-delete-icon {
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  color: #969799;
  font-size: 18px;
  background-color: #fff;
  border-radius: 100%;
  z-index: 100;
}

.van-uploader__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(50, 50, 51, 0.88);
  z-index: 1000;
}
</style>
<style>
input[type="file"] {
  outline: none;
  /*margin-top: 20px;*/
}

#clip_button {
  position: absolute;
  right: 10%;
  bottom: 20px;
  width: 80px;
  height: 40px;
  border: none;
  border-radius: 2px;
  background: #1AAD19;
  color: #fff;
  font-size: 14px;
}

#cancel_clip {
  position: absolute;
  left: 10%;
  bottom: 20px;
  width: 80px;
  height: 40px;
  border: none;
  border-radius: 2px;
  color: #fff;
  background: #E64340;
  font-size: 14px;
}

#clip_container.container {
  z-index: 99999;
  position: fixed;
  padding-top: 60px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
}

#clip_container.container > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#clip_image {
  max-width: 100%;
}

.cropper-container {
  font-size: 0;
  line-height: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}

.crop_loading,
.crop_success {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.crop_loading .crop_content {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  max-width: 70%;
  min-height: 88px;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.crop_loading .crop_content img {
  margin-top: 15px;
  margin-bottom: 10px;
}

.crop_success .crop_success_text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  background: #000;
  opacity: 0.9;
  width: 120px;
  height: 30px;
  color: #fff;
  line-height: 30px;
  font-size: 16px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cropper-container img {
  /* Avoid margin top issue (Occur only when margin-top <= -height) */
  display: block;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100%;
  height: 100%;
  image-orientation: 0deg;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cropper-wrap-box {
  overflow: hidden;
}

.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}

.cropper-modal {
  opacity: 0.5;
  background-color: #000;
}

.cropper-view-box {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
}

.cropper-dashed {
  position: absolute;
  display: block;
  opacity: 0.5;
  border: 0 dashed #eee;
}

.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}

.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
}

.cropper-center:before,
.cropper-center:after {
  position: absolute;
  display: block;
  content: " ";
  background-color: #eee;
}

.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}

.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}

.cropper-line {
  background-color: #39f;
}

.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}

.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}

.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}

.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}

.cropper-point {
  width: 5px;
  height: 5px;
  opacity: 0.75;
  background-color: #39f;
}

.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}

.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}

.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}

.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}

.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}

.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}

.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}

.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
  }
}

.cropper-point.point-se:before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: " ";
  opacity: 0;
  background-color: #39f;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMzTjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}

.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
</style>
