<template>
  <div>
    <Search :value="searchValue" :placeholderSearch="placeholderSearch" @searchCallBack="searchCallBack"/>
    <Content :text="text" @multiple="multipleClick" @filterShowCallBack="filterShowCallBack" :total="total">
      <div class="infoWrap" ref="list" @scroll="alive">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadCallBack"
        >
          <div class="">
            <van-checkbox-group v-model="checkedList" ref="checkboxGroup" @change="checkedChange">
              <div v-for="(item,index) in list" :key="index">
                <receivedItem @recycleCallBack="recycleCallBack" :identityType="identityType"
                          @detailsItemCallBack="detailsItemCallBack"
                          @followCallBack="followCallBack" @connectCallBack="connectCallBack"
                          :item="item" :isMultiple="isMultiple"></receivedItem>
              </div>
              <div style="height: 40px"></div>
            </van-checkbox-group>
          </div>
        </van-list>

      </div>
      <div class="isMultipleOp" v-if="isMultiple">
        <van-checkbox v-model="checked" @click="checkAll">全选</van-checkbox>
        <span class="num">已选({{ checkedList.length }})</span>
        <van-popover
            v-model="showPopover"
            trigger="click"
            :actions="actions"
            placement="top-end"
            @select="onSelect"
        >
          <template #reference>
            <van-button type="" style="border:1px solid rgb(68, 132, 255);
            color: rgb(68, 132, 255);background: #FFFFFF;border-radius: 25px;height: 25px">
              更多
            </van-button>
          </template>
        </van-popover>

        <div class="draw" @click="restoreClick">退回公海</div>
        <div class="distribution" @click="transferClick">移交</div>
      </div>
    </Content>
    <filterPop :searchValue="searchValue"  :tabState="tabState" @sureCallBack="sureCallBack" :totalAll="total"  :filterShow="filterShow"
                @close="onCancel" :formItem="form">
    </filterPop>
    <ContactsDialog :connectItem="connectItem" :is-show="isShowContacts" @contactsConfirm="onCancel"/>
    <SelectItem v-if="selectShow" :selectShow="selectShow" :name="name" :title="'选择顾问'" :arr="selectArr"
                @onCancel="onCancel" @onConfirm="onSelectConfirm"/>
    <RecycleDialog :is-show="recycleShow" :recycleItemArr="recycleItemArr" @closeCallBack="closeCallBack" @confirmCallBack="toastCallBack"
                 />
<!--    <DepartUserItem v-if="selectShow" :options="selectArr" :itemArr="turnOverDTOS" @finishCallBack="onSelectConfirm" />-->
  </div>
</template>

<script>
import DepartUserItem from '@/components/departUserItem/index'
import RecycleDialog from "@/views/components/recycleDialog";
import ContactsDialog from '@/components/ContactsDialog'
import filterPop from "@/views/bdPerson/components/filterPop";
import Content from '@/views/components/content.vue'
import receivedItem from "./item/receivedItem";
import Search from '@/views/components/search';
import {my_customers,release_sea,all_receive} from "@/api/cooperating/bd";
import {returnExpireData} from "@/utils/check/changeData";
import {add_recycle, not_release, not_turn_over} from "@/api/cooperating/not";
import {get_depart_user_list} from "@/api/common";
export default {
  components:{
    DepartUserItem,
    Content,
    Search,
    receivedItem,
    filterPop,
    ContactsDialog,
    RecycleDialog
  },
  props:{
    identityType:{
      type:String,
      default:"",
    },
    placeholderSearch:{
      type:String,
      default:"",
    },
    searchType:{
      type:String,
      default:"",
    }
  },
  data() {
    return {
      turnOverDTOS:[],
      recycleItemArr:[],
      name: '',
      selectShow:false,
      selectArr:[],
      showPopover: false,
      // 通过 actions 属性来定义菜单选项 ,{text: '分配顾问',id:2}
      actions: [{text: '移入回收站',id:1}],

      text: '多选',
      scrollPages: 0, // 缓存当前滚动条的位置
      recycleItem:null,
      recycleShow:false,
      connectItem:{},
      searchValue:'',
      total:0,
      isShowContacts:false,
      filterShow:false,
      loading: false,
      finished: false,
      list:[],
      checkedList:[],//选中数组
      isMultiple:false,
      checked:false,
      tabState:1,
      form:{
        identityList:[],
        tabState:1,// 0 BD成功 1已领取
        pageNum:1,
        pageSize:20
      }
    };
  },
  created() {
    console.log('-----------created')
    this.$EventBus.$on('GO_BACK_RELOAD', () => {
      this.form = {
        identityList:[],
        tabState:1,// 0 BD成功 1已领取
        pageNum:1,
        pageSize:20
      }
      this.loading = false
      this.finished = false
      this.list = []
      this.init()
    })
  },
  activated() {
    let expire= this.$route.query.expire
    if (expire){
      this.form.expireEndTime = returnExpireData(expire,1)
      this.form.expireStartTime = returnExpireData(expire,2)
    }
    let {identity='',expandDeptAuthority=null,assignStartTime='',assignEndTime=''} = this.$route.query
    console.log('我们进入================created',identity)
    this.form.identityList = this.form.identityList.length!==0?this.form.identityList:(identity?identity.split(','):[])
    this.form.expandDeptAuthority = Boolean(expandDeptAuthority)||null
    this.form.assignStartTime = assignStartTime
    this.form.assignEndTime = assignEndTime
    this.initActivated();
    // 缓存组件激活时调用
    this.$refs.list.scrollTop = this.scrollPages; // 获取 dom 的scrollTop = 缓存的滚动条位置
  },
  methods:{
    onSelectConfirm(item) {
      console.log(item)
      let data = {
        receiveList: this.turnOverDTOS,
        ownerUserId:item.userNo
      }
      all_receive(data).then(res => {
        this.toastCallBack()
      })
    },
    initActivated() {
      this.getUserByName()
    },
    getUserByName() {
      console.log('------------------------------')
      let data = {};
      data={
        allDepartName: [
          "仪美尚,会员部,拓展组"
        ]
      }
      get_depart_user_list(data).then(res => {
        res = res[0].childs || []
        if (res.length !== 0) {
          res.forEach(item => {
            item.detailCode = item.userNo;
            item.detailName = item.userName;
          })
          this.selectArr = res
        }
        console.log('res=', res)
      })
    },
    restoreClick() {
      let data = {
        index: 1,
        msg: '是否将选择的数据退回公海？'
      }
      this.dialogCallback(data)
      console.log(this.checkedList)
    },
    transferClick() {
      console.log(this.checkedList)
      let data = {
        index: 2,
        msg: '是否将选择的数据移交？'
      }
      this.dialogCallback(data)
    },
    dialogCallback(data) {
      console.log(this.checkedList)
      if (this.checkedList.length == 0) {
        this.$toast('请至少选择一个！')
        return;
      }
      let arr = [];
      if (data.index == 1){
        this.checkedList.forEach(item => {
          arr.push({id: item.id, identity: item.identity,userId:item.userId})
        })
      }else {
        this.checkedList.forEach(item => {
          arr.push({id: item.userId, identity: item.identity})
        })
      }

      this.turnOverDTOS = arr || []
      let that = this;
      if (data.index === 1) {
        this.notReleaseNetWork();
      } else if (data.index === 2) {
        that.selectShow = true
        console.log('this.selectArr')
        console.log(this.selectArr)

      }
      // this.$dialog.confirm({
      //   title: '提示',
      //   message: data.msg,
      //   confirmButtonColor: '#3D7FFF',
      // }).then(() => {
      //   if (data.index === 1) {
      //     this.notReleaseNetWork();
      //   } else if (data.index === 2) {
      //     that.selectShow = true
      //
      //   }
      // }).catch(() => {
      //   console.log('')
      // });
    },
    /**
     * TODO 退回公海接口
     */
    notReleaseNetWork() {
      let that = this;
      let data = {
        receiveList: that.turnOverDTOS
      }
      release_sea(data).then(res => {
        that.toastCallBack()
      })
    },
    toastCallBack() {
      this.recycleShow = false
      this.selectShow = false
      this.$toast('操作成功！')
      this.list = [];
      this.form.pageNum = 1;
      this.init()
      this.onCancel()
    },
    onSelect(action) {
      if (this.checkedList.length == 0) {
        this.$toast('请至少选择一条数据！')
      }else {
        if (action.id==1){
          this.recycleShow = true
          this.recycleItemArr=this.checkedList
          console.log('更多=',this.recycleItemArr)
        }else {
          this.selectShow = true
          this.recycleItem = '';
        }
      }

    },
    checkedChange() {
      console.log('我点击了')
      if (this.checkedList.length === this.list.length) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    alive(e) {
      // 获取页面滚动条的位置
      this.scrollPages = e.target.scrollTop;
    },
    recycleCallBack(item){
      this.recycleShow = true
      console.log('recycleShow',this.recycleShow)
      this.recycleItem = item
      this.recycleItemArr=[item]
    },
    sureCallBack(item){
      this.form = item;
      this.list = []
      this.init();
    },
    init(){
      let that = this;
      that.initUserTypeList();
    },
    async initUserTypeList(){
      this.form.crmSearch = this.searchValue;
      for (let i in this.form){
        if (!this.form[i]){
          delete this.form[i]
        }
      }
      try {
        this.total = 0
        this.form.tabState = this.tabState
        const res = await my_customers(this.form)
        this.initThenCallBack(res);
      }catch (e) {
        this.loading = false
        this.finished = true
      }
    },
    initThenCallBack(res){
      res.records = res.records||[]
      this.list.push(...res.records)
      this.form.pageNum++
      this.loading = false
      this.total = Number(res.totalNum)
      if (res.records.length!=0 && this.list.length <= this.total) {
        this.finished = false
      }else {
        this.loading = false
        this.finished = true
      }
      console.log(res)
    },
    detailsItemCallBack(item){
      this.localStorageCallBack('1')
      if (item.userType == 4) {
        let queryData ='?id=' + item.userId+'&identity='+item.identity + (item.identity == 'USER_LABEL_IDENT_BRAND' ? ('&brandId=' + '') :'')//品牌身份多传个品牌id
        this.$router.push({
          path: '/manual_details'+queryData
        })
      } else {
        let queryData ='?id=' + item.userId+'&identity='+item.identity + (item.identity == 'USER_LABEL_IDENT_BRAND' ? ('&brandId=' + (item.userType == 1 ? '' : item.strategyId)) :'')//品牌身份多传个品牌id
        switch (item.identity) {
          case 'USER_LABEL_IDENT_RETAILER':
            this.$router.push({
              path: '/shop_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_BRAND':

            this.$router.push({
              path: '/brand_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_RED':
            this.$router.push({
              path: '/favourite_details' +queryData
            })
            break;
          case 'USER_LABEL_IDENT_RED_ORG':
            this.$router.push({
              path: '/mcn_details'+queryData
            })
            break;
          case 'USER_LABEL_IDENT_FACTORY':
            this.$router.push({
              path: '/factory_details' + queryData
            })
            break;
          case 'USER_LABEL_IDENT_SERVICE_PROVIDER':
            this.$router.push({
              path: '/server_details' + queryData
            })
            break;
        }
      }
    },
    followCallBack(item){
      this.localStorageCallBack('1')
      this.$router.push({
        path: '/fill_in_follow_up?identity='+item.identity+'&userId='+item.userId
      })
    },
    localStorageCallBack(index){
      localStorage.setItem('active',index)
    },
    connectCallBack(item){
      let data = {
        id:item.id,
        userType:item.userType,
        userId:item.userId,
        identityType: this.identityType
      }
      this.connectItem = data
      console.log('connectCallBack=',item)
      this.isShowContacts  = true
    },
    filterShowCallBack(){
      this.filterShow = true
    },
    multipleClick(){
      this.isMultiple = !this.isMultiple
      this.$emit('isMultipleCallback',this.isMultiple)
    },
    onConfirm(item){
      console.log(item)
      this.showDistribution = false
    },
    closeCallBack(){
      this.recycleShow = false
    },
    onCancel(){
      this.isShowContacts = false
      this.showDistribution = false
      this.filterShow = false
      this.selectShow = false
      this.isMultiple = false
      this.recycleShow = false
      this.text = '多选'
      this.checked = false
      this.checkedList = []
    },
    isMultipleCallback(isMultiple){
      console.log(isMultiple)
      this.isMultiple = isMultiple
    },
    checkAll(item){
      if(this.checked){
        this.$refs.checkboxGroup.toggleAll(true);
      }else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    onLoadCallBack() {
      this.init()
    },
    searchCallBack(val){
      this.searchValue = val||'';
      this.list = [];
      this.form.pageNum = 1;
      this.init()
    },
  }
}
</script>

<style lang="scss" scoped>
.infoWrap{
  margin: 20px 30px 0 0;
  /* padding-bottom: 120px; */
  box-sizing: border-box;
  height: calc(100% - 180px) ;
  overflow: auto;
  .swipe{
    margin-bottom: 20px;
  }
  .item{
    box-sizing: border-box;
    margin-left: 30px;
    height: 194px;
    border-radius: 10px;
    background: #FFFFFF;
    position: relative;
    z-index: 1;
    color:#333333;
    padding-left: 30px;
    &.isMultiple{
      padding-left: 100px;
    }
    .checked{
      position: absolute;
      left: 30px;
      top: 60px;
    }
    .name{
      font-size: 36px;
      margin-bottom: 8px;
    }
    .style{
      font-size: 24px;
    }
  }
  .operate{
    display: flex;
    /* margin-left: -18px; */
    div{
      text-align: center;
      height: 152px;
      line-height: 152px;
      font-size: 28px;
      color: #FFFFFF;
    }
    .draw{
      width: 134px;
      background: #EB5D00;
      &.active{
        margin-left: -18px;
      }
    }
    .distribution{
      width: 116px;
      background: #4484FF;
      border-radius: 0px 20px 20px 0px;
    }
  }
}

.isMultipleOp{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  height: 120px;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 30px;
  font-size: 28px;
  .num{
    display: inline-block;
    line-height: 1;
    margin-left: 20px;
    color:#999999
  }
  .draw,.distribution{
    font-size: 24px;
    width: 152px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    position:absolute;
    border-radius: 25px;
  }
  .draw{
    right: 190px;
    color:#4484FF;
    border: 1px solid #4484FF;
  }
  .distribution{
    background: #4484FF;
    right: 30px;
    color: #FFFFFF;
  }
}
</style>
