<template>
<ul class="bottom-navigation">
  <li @click="router('Chart')" :class="{ on: chartOn }">
    <img src="@/assets/image/index/dashboard.png"/>
    <img class="on" src="@/assets/image/index/dashboard-on.png"/>
    <p>图表</p>
  </li>
  <li @click="router('Index')" :class="{ on: indexOn }">
    <img src="@/assets/image/index/dashboard.png"/>
    <img class="on" src="@/assets/image/index/dashboard-on.png"/>
    <p>工作台</p>
  </li>
  <li @click="router('Profile')" :class="{ on: profileOn }">
    <img src="@/assets/image/index/my.png"/>
    <img class="on" src="@/assets/image/index/my-on.png"/>
    <p>我的</p>
  </li>
</ul>
</template>

<script>
export default {
  name: 'BottomNavigation',
  computed: {
    chartOn () {
      return this.$route.name === 'Chart'
    },
    indexOn () {
      return this.$route.name === 'Index'
    },
    profileOn () {
      return this.$route.name === 'Profile'
    }
  },
  methods: {
    router (name) {
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.bottom-navigation {
  width: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  border-radius: 20px 20px 0px 0px;
  background: #FFFFFF;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  overflow: hidden;
  li {
    flex: 0;
    width: 50%;
    float: left;
    text-align: center;
    padding: 10px 30px;
    margin: 10px;
    position: relative;
    img {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 56px;
      transition: opacity .3s;
    }
    p {
      margin-top: 50px;
      margin-bottom: 0;
      color: #333333;
      font-size: 24px;
      white-space: nowrap;
      transition: color .3s;
    }
    &:not(.on) {
      img{
        opacity: 1;
        &.on {
          opacity: 0;
        }
      }
    }
    &.on {
      img{
        opacity: 0;
        &.on {
          opacity: 1;
        }
      }
      p {
        color: #4484FF;
      }
    }
  }
}
</style>